import { Button } from "reactstrap";
import { makeFullS3ObjectUrl } from "utils/makeFullS3ObjectUrl";
import "../../styles/template/template.scss";
const Zip = makeFullS3ObjectUrl("zip.png");

const UploadPopup = () => {
  return (
    <>
      <div className="erc-template-wrapper">
        <div className="title">
          <h5 className="c-tx-primary f-700 mb-0">Upload New Contract</h5>
        </div>
        <div className="upload-wrapper mt-30 mb-30">
          <div className="box">
            <div className="text-center">
              <img src={Zip} className="img-fluid" alt="upload" />
              <p className="c-tx-ninth mt-10">Choose a zip file or drag it here.</p>
            </div>
          </div>
        </div>
        <div className="btn-wrapper text-end ">
          <Button className="custom-primary br-5 new-btn">Upload</Button>
        </div>
      </div>
    </>
  );
};

export default UploadPopup;

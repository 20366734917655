import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const onGetProfileDetails = (): Promise<IUserProfile> => {
  return new Promise((resolve, reject) => {
    HTTPService.get(apiEndPoints.getProfileAPI)
      .then((response: any) => resolve(response))
      .catch((error) => reject(error));
  });
};

export interface IUser {
  cropProfilePicture: string;
  email: string;
  firstName: string;
  id: number;
  networkStatus: number;
  kycApproved: number;
  lastName: string;
  ownerOrg: boolean;
  profilePic: string;
  unRead: number;
  universeMaintenance: boolean;
  userId: string;
  username: string;
}
interface IUserProfile {
  data: IUser;
  message: string;
  status: 200;
}

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const ParticipantsChannelTableAPI = (data: IParticipantsChannelTableAPIParams): Promise<IParticipantsChannelTableAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.channelManagement.shared.list, data);
};

interface IParticipantsChannelTableAPIParams {
  limit?: number;
  page?: number;
  search?: string;
  pagination?: boolean;
}

export interface IParticipantsChannelTableAPIResData {
  channelName: string;
  totalParticipate: number;
}

interface IParticipantsChannelTableAPIRes {
  status: number;
  message: string;
  data: {
    count: number;
    data: IParticipantsChannelTableAPIResData[];
  };
}

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const invitationAcceptedRejectedAPI = (params: IinvitationAcceptedRejectedAPIParams): Promise<invitationAcceptedRejectedAPIRes> => {
  return HTTPService.patch(apiEndPoints.universeAPI.channelManagement.invitationRejectedAccepted + params.id, params.data);
};

interface IinvitationAcceptedRejectedAPIParams {
  id: any;
  data: any;
}

interface invitationAcceptedRejectedAPIRes {
  message: string;
  status: number;
}

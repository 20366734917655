import { IUser } from "utils/common/helper/profileActions";
import { NOTIFICATION_ON, USERDATA, USERINFOSTATUS } from "./type";

export const onUserDetailsUpdate = (payload: IUser) => {
  return { type: USERDATA, payload };
};

export const onNotifcationOn = () => {
  return { type: NOTIFICATION_ON };
};

export const setUserInfo = (payload: boolean) => {
  return { type: USERINFOSTATUS, payload };
};

import { faBell } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Notification = <FontAwesomeIcon icon={faBell} />;

const RegularBell = () => {
  return Notification;
};

export default RegularBell;

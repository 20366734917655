import {
  FIRSTTIMEVISITORMODAL,
  FIRSTTIMEVISITORTOURVISIBLE,
  PRIVATE_BLOCKCHAIN_FIRST
} from './type'

export const setFirstTimeVisitorTourVisibility = (
  payload: boolean
) => {
  return { type: FIRSTTIMEVISITORTOURVISIBLE, payload }
}
export const setFirstTimeVisitorModalVisibility = (
  payload: boolean
) => {
  return { type: FIRSTTIMEVISITORMODAL, payload }
}
export const setFirstTimePrivateBlockchain = (payload: boolean) => {
  return { type: PRIVATE_BLOCKCHAIN_FIRST, payload }
}

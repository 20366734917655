import { networkInfoAPI } from "actions/networkInfoAPI";
import axios, { AxiosResponse } from "axios";
import store from "store";
import { onUserLoggedIn, onUserLogOut } from "store/auth/action";
import { setLoading } from "store/loader/action";
import { setNetwork } from "store/networkReducer/action";
import { setNotificationCount } from "store/notification/action";
import { onUserDetailsUpdate, setUserInfo } from "store/user/action";
import { onGetProfileDetails } from "utils/common/helper/profileActions";
import {
  getEncryptedCookie,
  setEncryptedLocalStorage,
  toastError,
} from "./commonFunctions";
import { cookieKeys, localStorageKeys } from "./constants";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_APIBASEURL,
});

const errorInterceptor = (errorResponse: any) => {
  store.dispatch(setLoading(false));
  if (errorResponse) {
    const { message, status } = errorResponse.data;
    if (status === 401) {
      // @ts-ignore
      store.dispatch(onUserLogOut());
    } else if (status === 406) {
      onGetProfileDetails()
        .then((response) => {
          setEncryptedLocalStorage(localStorageKeys.isLoggedIN, response.data);
          // @ts-ignore
          store.dispatch(onUserLoggedIn());
          store.dispatch(setNotificationCount(response.data.unRead));
          store.dispatch(onUserDetailsUpdate(response.data));
          store.dispatch(setUserInfo(false));
          if (response?.data?.networkStatus === 1) {
            networkInfoAPI().then((res) => {
              if (res?.status === 200) {
                setEncryptedLocalStorage(
                  localStorageKeys.networkInfo,
                  res?.data
                );
                store.dispatch(setNetwork(res?.data));
                store.dispatch(setLoading(false));
                window.location.reload();
                if (res.data.tour) {
                  // @ts-ignore
                  store.dispatch(setUserTour(true));
                }
              }
            });
          } else {
            window.location.reload();
            store.dispatch(setLoading(false));
          }
        })
        .catch((err) => {
          store.dispatch(setLoading(false));
        });
    } else if (message && status !== 423) {
      if (message !== "KYC does not exist") {
        if (status === 406 || message === "Not acceptable") {
        } else if (status === 409 || message === "Channel name already exist") {
        } else {
          toastError(message);
        }
      }
      else if (status === 423) {
      window.location.href = "/unauthorized";
      }
    }
  }
};

axiosInstance.interceptors.request.use(
  (req) => {
    const cookie = getEncryptedCookie(cookieKeys.cookieUser);
    if (cookie && cookie.token) {
      // @ts-ignore
      req.headers.Authorization = `Bearer ${cookie.token}`;
    }
    return req;
  },
  (err) => {
    // catches client side error like no internet etc
    return Promise.reject(err);
  }
);

axiosInstance.interceptors.response.use(
  (req) => {
    return req;
  },
  (err) => {
    errorInterceptor(err.response);
    return Promise.reject(err);
  }
);

export default class HTTPService {
  static get<T = never, R = AxiosResponse<T>>(
    url: string,
    params?: any
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .get(url, { params })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static put<T = never, R = AxiosResponse<T>>(
    url: string,
    body: any
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .put(url, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static post<T = never, R = AxiosResponse<T>>(
    url: any,
    body: any,
    params?: any
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .post(url, body, params && { params })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }

  static delete<T = never, R = AxiosResponse<T>>(
    url: string,
    body: any
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .delete(url, { data: body })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }
  static patch<T = never, R = AxiosResponse<T>>(
    url: string,
    body: any
  ): Promise<R> {
    return new Promise((resolve, reject) => {
      axiosInstance
        .patch(url, body)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response || error));
    });
  }
}

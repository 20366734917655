import { FC, memo } from "react";
import { useSelector } from "react-redux";
import { IRootReducer } from "store/root-reducer";
import CharacterPrinter from "./characterPrinter";

interface ISend {
  message: string;
  forwordRef?: any;
  error?: boolean;
}

const Send: FC<ISend> = ({ message, forwordRef, error }) => {
  const userDetails = useSelector((state: IRootReducer) => state?.userDetails);
  return (
    <div ref={forwordRef} className="chat-list-send-api">
      <div>
        <div className="chat-list-user-send text-uppercase">
          <img src={userDetails?.user?.cropProfilePicture} alt="ml" />
        </div>
      </div>
      <div className={`${error ? "response-error" : ""} `}>
        {message.length > 200 ? (
          <pre className="text-break" style={{ whiteSpace: "pre-wrap" }}>
            {message}
          </pre>
        ) : (
          <CharacterPrinter text={message} delay={message.length > 30 ? 60 : 30} />
        )}
      </div>
    </div>
  );
};

export default memo(Send);

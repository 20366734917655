import { FC } from "react";
import { Button, Modal } from "reactstrap";

interface IConfirmationModal {
  handleAction: () => void;
  open: {
    open: boolean;
    id: string;
  };
  setOpen: any;
}

const ConfirmationFolderModal: FC<IConfirmationModal> = ({ open, setOpen, handleAction }) => {
  const handleClose = () => {
    setOpen({
      id: "",
      open: false
    });
  };

  return (
    <Modal isOpen={open.open} className="custom-popup-channel" size="sm" centered>
      {" "}
      <div className="erc-template-wrapper">
        <div className="title">
          <h5 className="c-tx-primary text-center f-700 mb-20">Confirmation</h5>
        </div>
        <p className="c-tx-ninth text-center mb-20">Are you sure want to delete Folder?</p>
        <div className="btn-wrapper text-center">
          <Button className="custom-primary-outline me-3 mb-3 mb-sm-0 new-btn" onClick={handleClose}>
            no
          </Button>
          <Button
            className="custom-primary new-btn"
            onClick={() => {
              handleAction();
            }}>
            Yes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationFolderModal;

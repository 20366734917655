import { getDecryptedLocalStorage } from "utils/commonFunctions";
import { localStorageKeys } from "utils/constants";
import { NOTIFICATION_ON, USERDATA, USERINFOSTATUS } from "./type";

interface IinitialState {
  user: any;
  userInfo: boolean;
}
const initialState: IinitialState = {
  user: getDecryptedLocalStorage(localStorageKeys.isLoggedIN),
  userInfo: false
};

const userDetailsReducer = (
  state = initialState,
  action: {
    type: String;
    payload: any;
  }
) => {
  const newState = {
    ...state
  };
  switch (action.type) {
    case USERDATA:
      newState.user = action.payload;
      break;
    case NOTIFICATION_ON:
      newState.user.notification = action.payload;
      break;
    case USERINFOSTATUS:
      newState.userInfo = action.payload;
      break;
  }
  return newState;
};

export default userDetailsReducer;

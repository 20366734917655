import { getOverviewListAPI } from "actions/getOverviewListAPI";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Col } from "reactstrap";
import { setLoading } from "store/loader/action";
import { setPeerDetails } from "store/networkReducer/action";
import { getDecryptedLocalStorage, setEncryptedLocalStorage, toastSuccess } from "utils/commonFunctions";
import { localStorageKeys } from "utils/constants";

const OverviewListComponent = () => {
  const dispatch = useDispatch();
  const history: any = useLocation();
  const id: any = history?.state?.ids;
  const logData = getDecryptedLocalStorage(localStorageKeys.universeLogs);

  const [overViewList, setOverviewList] = useState<any>();

  const handleCopyTransaction = (text?: string) => {
    if (text) {
      window.navigator.clipboard.writeText(text).then(() => {
        toastSuccess("Copied to Clipboard");
      });
    }
  };

  const getOverviewListAction = () => {
    if (id !== "") {
      dispatch(setLoading(true));
      getOverviewListAPI(id || logData?.id || "")
        .then((res: any) => {
          dispatch(setLoading(false));
          if (res?.status === 200) {
            dispatch(setPeerDetails(res.data || []));
            setEncryptedLocalStorage(localStorageKeys.universeLogs, res.data);
            setOverviewList(res);
          }
        })
        .catch((err: any) => {});
    }
  };
  useEffect(() => {
    getOverviewListAction();
    // eslint-disable-next-line
  }, []);

  return (
    <Col md="12" lg="12" xl="8" xxl="8">
      <div className="backdrop-block blockchain-wrapper h-100">
        <div className="title">
          <h5 className="c-tx-primary  f-600 mb-10">Node Information</h5>
          <p className="c-tx-ninth">Below you can view all the metadata for this node including node endpoints and other important information you may wish to copy.</p>
        </div>

        <ul className="peer-node-list">
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Name</p>
            </div>
            <div className="second-peer-title">
              <p className="c-tx-ninth">{overViewList?.data?.name || "Name"}</p>
            </div>
            <div className="third-peer-title"></div>
          </li>
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Peer ID</p>
            </div>
            <div className="second-peer-title">
              <p className="c-tx-ninth">{overViewList?.data?.id || "Peer ID"}</p>
            </div>
            <button className="c-tx-forth f-700" onClick={() => handleCopyTransaction(overViewList?.data?.id)}>
              Copy
            </button>
          </li>
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Region</p>
            </div>
            <div className="second-peer-title">
              <p className="c-tx-ninth">{overViewList?.data?.peerRegionId?.name || "Region"}</p>
            </div>
          </li>
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Node size</p>
            </div>
            <div className="second-peer-title">
              <p className="c-tx-ninth">{overViewList?.data?.size || "Node size"}</p>
            </div>
            <div className="third-peer-title"></div>
          </li>
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Owner</p>
            </div>
            <div className="second-peer-title">
              <p className="c-tx-ninth">{overViewList?.data?.peerUserId.name || "Owner"}</p>
            </div>
            <div className="third-peer-title"></div>
          </li>
          <li>
            <div className="first-peer-title">
              <p className="c-tx-primary f-700">Email</p>
            </div>
            <div className="second-peer-title">
              <p className="c-tx-ninth">{overViewList?.data?.peerUserId.email || "Email"}</p>
            </div>
            <div className="third-peer-title"></div>
          </li>

          <div className="third-peer-title"></div>
        </ul>
      </div>
    </Col>
  );
};

export default OverviewListComponent;

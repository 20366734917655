import { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import "../../../styles/standalone/standalone.css";

const ContractWizard = () => {
  const [scriptIsLoaded, setScriptIsLoaded] = useState(false);
  function isScriptLoaded(src: string) {
    // eslint-disable-next-line quotes
    return Boolean(document.querySelector('script[src="' + src + '"]'));
  }
  // embed openzaplin script
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://wizard.openzeppelin.com/build/embed.js";
    script.async = true;

    document.body.appendChild(script);

    const timeout = setTimeout(() => {
      if (isScriptLoaded("https://wizard.openzeppelin.com/build/embed.js")) {
        setScriptIsLoaded(true);
      }
    }, 1000);
    return () => {
      document.body.removeChild(script);
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div>
      <div className="hero">
        <h1>Token Engine 2.0</h1>
        <p>
          Use the interactive generator below to bootstrap your smart contract. Build on top of{" "}
          <a href="https://openzeppelin.com/contracts" className="underline-text">
            OpenZeppelin Contracts
          </a>
          .
        </p>
      </div>

      <div className="wizard-container">
        {!scriptIsLoaded && (
          <div className="d-flex justify-content-center align-items-center position-relative">
            <Spinner size={"sm"} />
          </div>
        )}
        {/* @ts-ignore */}
        <oz-wizard class="wizard" data-sync-url="fragment"></oz-wizard>
      </div>

      <div className="faq">
        <ul>
          <li>
            <h3>What is this?</h3>
            <p>Contracts Wizard is an interface to interactively build a smart contract out of components from OpenZeppelin Contracts.</p>
          </li>
          <li>
            <h3>How can I use this?</h3>
            <p>
              Select the kind of contract that you want (current support for ERC20 and ERC721), set your parameters and desired features (token name, symbol, premint amount, access control, etc.), and
              Contracts Wizard will generate all of the code necessary. The resulting code is ready to be compiled and deployed, or it can serve as a starting point and customized further with
              application specific logic.
            </p>
          </li>
          <li>
            <h3>What can I do with the code?</h3>
            <p>
              There’s several options for how to proceed with it that will be useful in different circumstances. “Copy to Clipboard” as well as the standard “Download” will work well with local
              development workflows (such as using Hardhat or Truffle), whereas “Open in Remix” will be ideal for anyone working on Remix IDE.
            </p>
          </li>
        </ul>
      </div>

      {/* <div className="defender">
        <h2>What is Lorem Ipsum?</h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
          of type and scrambled it to make a type specimen book.
        </p>
      </div> */}
    </div>
  );
};

export default ContractWizard;

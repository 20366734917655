import { uploadContractContext } from "hooks/UseUploadNewContract";
import { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import { Button, Modal, Spinner } from "reactstrap";
import { makeFullS3ObjectUrl } from "utils/makeFullS3ObjectUrl";

const Zip = makeFullS3ObjectUrl("zip.png");

const UploadNewContractModal = () => {
  const contractContextValues = useContext(uploadContractContext);
  const [limiteValidation, setLimiteValidation] = useState(false);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles[0].size > 1000000) {
        setLimiteValidation(true);
      } else {
        setLimiteValidation(false);
        contractContextValues.contractFormik.setFieldValue("file", acceptedFiles[0]);
      }
    },
    [contractContextValues.contractFormik]
  );
  const { getRootProps, getInputProps, isDragActive, fileRejections, acceptedFiles } = useDropzone({ onDrop, accept: { "application/zip": [".zip"] } });

  const handleClose = () => {
    setLimiteValidation(false);
    contractContextValues.toggleUploadModal();
  };

  return (
    <Modal
      isOpen={contractContextValues.uploadPopup}
      className="custom-popup-channel"
      toggle={handleClose}
      size="lg"
      centered
      external={
        <Link to="#" className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30" onClick={handleClose}>
          CLOSE
        </Link>
      }>
      <div className="erc-template-wrapper">
        <div className="title">
          <h5 className="c-tx-primary f-700 mb-0">Upload Contract File</h5>
        </div>
        <div className="upload-wrapper mt-30 mb-30">
          <div className={`box ${contractContextValues.loading ? "disable " : ""}`} {...getRootProps()}>
            <div className="text-center">
              <img src={Zip} className="img-fluid" alt="upload" />
              <input {...getInputProps()} disabled={!contractContextValues.loading} />
              {limiteValidation ? <p className="text-danger mt-10">Please upload only zip file upto 1 MB</p> : null}
              {acceptedFiles.length > 0 ? (
                <p className="c-tx-ninth mt-10">{acceptedFiles[0].name}</p>
              ) : isDragActive ? (
                <p className="c-tx-ninth mt-10">Drop the files here ...</p>
              ) : fileRejections.length > 0 ? (
                <p className="text-danger mt-10">Please upload only zip file upto 1 MB</p>
              ) : (
                <p className="c-tx-ninth mt-10">Choose a zip file or drag it here.</p>
              )}
            </div>
          </div>
        </div>
        <div className="btn-wrapper text-end ">
          <Button
            className="custom-primary br-5 new-btn"
            onClick={() => {
              if (!limiteValidation) contractContextValues.contractFormik.handleSubmit();
            }}>
            {contractContextValues.loading ? <Spinner size="sm" /> : "Upload"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UploadNewContractModal;

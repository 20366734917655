import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const deleteFolderAPI = (id: string): Promise<IdeleteFolderAPI> => {
  return HTTPService.delete(apiEndPoints.universeAPI.folderTemplate.delete + id, "");
};

interface IdeleteFolderAPI {
  status: number;
  message: string;
}

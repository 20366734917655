import { faApple, faChrome, faGooglePlay } from "@fortawesome/free-brands-svg-icons";
import { faStar as faStarEmpty } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faDesktop, faGlobe, faStar, faStarHalfAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import APIAndApplicationFeaturesModel from "components/models/APIFeaturesModel";
import { useState } from "react";
import { Button } from "reactstrap";
import { numberFormatter, toastSuccess } from "utils/commonFunctions";
import { applicationRating, defualtAppLogo } from "utils/constants";
import "../../styles/api/api.scss";
import StartIconComponent from "./StartIconComponent";

const Filledstar = <FontAwesomeIcon icon={faStar} />;
const faStarEmptyIcon = <FontAwesomeIcon icon={faStarEmpty} />;
const halfStar = <FontAwesomeIcon icon={faStarHalfAlt} />;
const Check = <FontAwesomeIcon icon={faCheck} />;
const Globe = <FontAwesomeIcon icon={faGlobe} />;
const Desktop = <FontAwesomeIcon icon={faDesktop} />;
const Home = <FontAwesomeIcon icon={faChrome} />;
const Play = <FontAwesomeIcon icon={faGooglePlay} />;
const Ios = <FontAwesomeIcon icon={faApple} />;

const Application = ({ allAPIData, reviewAndRating }: any) => {
  const [imgError, setImgError] = useState(false);
  // if no api data found then return from here
  if (!allAPIData) return <div></div>;

  const { description, features, id, isAvailable, logo, logoIcon, name, appId } = allAPIData;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [featureModelVisibility, setFeatureModelVisibility] = useState(false);
  const toggleFeatureModel = () => setFeatureModelVisibility(!featureModelVisibility);
  const openUrl = (url: string) => {
    if (!url) {
      toastSuccess("Coming Soon");
      return null;
    }
    window.open(url);
  };
  const handleImg = () => setImgError(true);
  return (
    <>
      <div className={`api-wrapper mb-4 ${isAvailable && appId !== "ooyxk2Hxqro13DmsThRrdn23O" ? "" : "pe-none opacity-75"}`}>
        <div className={`custum-badge ${isAvailable && appId !== "ooyxk2Hxqro13DmsThRrdn23O" ? "" : "bg-secondary"}`}>
          {isAvailable && appId !== "ooyxk2Hxqro13DmsThRrdn23O" ? "Available" : "Inaccessible"}
        </div>
        {/**
         * @logo
         * @rating
         * @description
         */}
        <div className="title-wrapper align-items-center">
          <div className="block-1">
            <img src={imgError ? defualtAppLogo : logoIcon ? logoIcon : logo} alt="ima" className="img-fluid" onError={handleImg} />
          </div>
          <div className="block-2">
            <h3 className="c-tx-third f-600 mb-2">{name}</h3>
            {/* ratings */}
            <div className="d-sm-flex my-1">
              {isAvailable && appId !== "ooyxk2Hxqro13DmsThRrdn23O" ? (
                <ul className="d-inline-sm-flex d-flex star-list">
                  <StartIconComponent icon={Filledstar} />
                  <StartIconComponent icon={Filledstar} />
                  <StartIconComponent icon={Filledstar} />
                  <StartIconComponent icon={Filledstar} />
                  <StartIconComponent icon={Filledstar} />
                  {applicationRating.find((item) => item.id === id)?.rating === 5 ? (
                    <StartIconComponent icon={Filledstar} className="icon-d" />
                  ) : (
                    <StartIconComponent icon={halfStar} className="icon-d" />
                  )}
                </ul>
              ) : (
                <ul className="d-inline-sm-flex d-flex star-list">
                  <StartIconComponent icon={faStarEmptyIcon} />
                  <StartIconComponent icon={faStarEmptyIcon} />
                  <StartIconComponent icon={faStarEmptyIcon} />
                  <StartIconComponent icon={faStarEmptyIcon} />
                  <StartIconComponent icon={faStarEmptyIcon} className="icon-d" />
                </ul>
              )}
              <div className="inline-flex ms-sm-2 mt-2 mt-sm-0">
                <p className="small c-tx-third mb-0">
                  ({isAvailable && appId !== "ooyxk2Hxqro13DmsThRrdn23O" ? applicationRating.find((item) => item.id === id)?.reviewCount : 0}) | Subscribed User :{" "}
                  {isAvailable && appId !== "ooyxk2Hxqro13DmsThRrdn23O" ? numberFormatter(applicationRating.find((item) => item.id === id)?.review || 2200, 1) : 0}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="content paragrah-content">
          <p className="c-tx-ninth mb-0">
            {description?.slice(0, 130)}
            {description?.length > 130 ? "..." : ""}
          </p>
        </div>

        {/**
         * @APP
         * @features
         */}
        {/* This section will show all the api features list */}
        <div className="points-wrapper">
          <p className="c-tx-third f-600 mb-2">Features</p>
          <ul className="point-list">
            {Array.isArray(features) &&
              features?.slice(0, 2)?.map((feature: string, index: number) => {
                return (
                  <li key={index}>
                    <div className="icon">
                      <p className="c-tx-tenth mb-0">{Check}</p>
                    </div>
                    <p className="c-tx-tenth mb-0">
                      {feature?.slice(0, 30)}
                      {feature?.length > 30 ? "..." : ""}
                    </p>
                  </li>
                );
              })}
            {Array.isArray(features) && features.length > 0 && (
              <li>
                <a
                  className="mb-0 readmore"
                  href="/"
                  onClick={(event) => {
                    event.preventDefault();
                    setFeatureModelVisibility(true);
                  }}>
                  Read More Features{" "}
                </a>
              </li>
            )}
          </ul>
        </div>

        {/**
         * @platforms
         */}
        {/* It will show the list of all the available platform */}
        <div className="points-wrapper available-on content mb-0">
          <p className="c-tx-third f-600 mb-2">Available On</p>
          <ul className="available-list">
            {allAPIData?.redirect_url && (
              <li className="available-bedge" onClick={() => openUrl(allAPIData?.redirect_url)}>
                <div className="icon">
                  <p className="c-tx-tenth mb-0">{Globe}</p>
                </div>
                <p className="c-tx-tenth mb-0">Web App </p>
              </li>
            )}

            {allAPIData?.desktop_url && (
              <li className="available-bedge" onClick={() => openUrl(allAPIData?.desktop_url)}>
                <div className="icon">
                  <p className="c-tx-tenth mb-0">{Desktop}</p>
                </div>
                <p className="c-tx-tenth mb-0">Desktop App</p>
              </li>
            )}

            {allAPIData?.extension_url && (
              <li className="available-bedge" onClick={() => openUrl(allAPIData?.extension_url)}>
                <div className="icon">
                  <p className="c-tx-tenth mb-0">{Home}</p>
                </div>
                <p className="c-tx-tenth mb-0">Chrome Extension</p>
              </li>
            )}

            {allAPIData?.app_url && (
              <li className="available-bedge" onClick={() => openUrl(allAPIData?.app_url)}>
                <div className="icon">
                  <p className="c-tx-tenth mb-0">{Play}</p>
                </div>
                <p className="c-tx-tenth mb-0">Android App</p>
              </li>
            )}
            {allAPIData?.ios_url && (
              <li className="available-bedge" onClick={() => openUrl(allAPIData?.ios_url)}>
                <div className="icon">
                  <p className="c-tx-tenth mb-0">{Ios}</p>
                </div>
                <p className="c-tx-tenth mb-0">Ios App</p>
              </li>
            )}
          </ul>
        </div>

        {/**
         * @CTA
         * @buttons
         */}
        <div className={"ct-btn-group btn-block"}>
          <Button
            className="custom-primary-outline custom-primary w-100"
            onClick={(event) => {
              event.preventDefault();
              setFeatureModelVisibility(true);
            }}>
            App Details{" "}
          </Button>
          {/* <Button className="custom-primary" >subscribe</Button> */}
        </div>
      </div>
      {/* this model is being used to show api informations and features in detail */}
      <APIAndApplicationFeaturesModel
        name={name}
        description={description}
        id={id}
        isAvailable={isAvailable}
        logo={logo}
        features={features}
        featureModelVisibility={featureModelVisibility}
        toggleFeatureModel={toggleFeatureModel}
      />
    </>
  );
};
export default Application;

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const addNewMember = (params: IAddNewMember): Promise<IAddNewMemberResult> => {
  return HTTPService.post(apiEndPoints.members, params);
};

export interface IAddNewMember {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  place: string;
  zipcode: string;
  address: string;
  status: number;
  gender: string;
}

export interface IAddNewMemberResult {
  message: string;
  status: number;
  userInfo: { id: number; userId: string };
  id: number;
  userId: string;
}

import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createPeerAPI } from "actions/createPeerAPI";
import { useFormik } from "formik";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, FormGroup, Input, Label, Modal } from "reactstrap";

import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";

import { getNetworkRegions, INetworkRegion } from "utils/common/helper/get-network-regions";
import { handleTrim, toastError, toastSuccess } from "utils/commonFunctions";
import * as Yup from "yup";
import "../../../styles/channelmanagement/channelmanagement.scss";
const Check = <FontAwesomeIcon icon={faCheck} />;

interface ICreateChannelModalComponent {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  getPeerList: (status: boolean) => void;
}

const CreatePeerNodeModal: FC<ICreateChannelModalComponent> = ({ setModal, modal, getPeerList }) => {
  const dispatch = useDispatch();
  const FormikState = useFormik({
    initialValues: {
      name: "",
      region: ""
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Peer name is required").min(3, "Peer name must be at least 3 characters").max(50, "Peer name must be at most 50 characters")
    }),
    onSubmit: (values) => {
      let data = { ...values, name: values.name.trim() };
      dispatch(setLoading(true));
      createPeerAPI(data).then((res) => {
        if (res.status === 200) {
          setModal(false);
          getPeerList(true);
          toastSuccess(res.message);
          resetForm();
        }
        dispatch(setLoading(false));
      });
    }
  });

  const { handleSubmit, values, errors, touched, handleBlur, setFieldValue, resetForm } = FormikState;

  const handleClose = useCallback(() => {
    setModal(false);
    resetForm();
    // eslint-disable-next-line
  }, [])

  const handleValidation = () => {
    handleSubmit();
  };

  const [regionsList, setRegionsList] = useState<INetworkRegion[]>([]);
  const networkId = useSelector((state: IRootReducer) => state.NetworkReducer.networkInfoNetworkId?.networkDomainType?.id);
  const loading = useSelector((state: IRootReducer) => state.loading);

  useEffect(() => {
    if (modal) {
      dispatch(setLoading(true));
      getNetworkRegions(networkId)
        .then((regionsResp) => {
          setRegionsList(regionsResp.data.data);
          setFieldValue("region", regionsResp.data.data.find((item) => item.default)?.id || "");
          dispatch(setLoading(false));
        })
        .catch((err) => {
          toastError(err.message);
        });
    }
    return () => {}; // eslint-disable-next-line
  }, [modal, networkId, setFieldValue])

  return (
    <>
      <Modal
        isOpen={modal}
        className="custom-popup-channel create-channel-pop"
        toggle={handleClose}
        // style={{ maxWidth: "700px" }}
        size="xl"
        centered
        external={
          <Link to="#" className="text-white f-700 close modal-close close small text-uppercase thankYouCloseButtonHide font-30" onClick={handleClose}>
            Close
          </Link>
        }>
        <div className="d-lg-flex custom-card-wrapper h-auto justify-content-between">
          <div className="w-100" style={{ marginRight: "20px" }}>
            <div className="title">
              <h5 className="c-tx-primary f-700 mb-20">Enter Environment Details</h5>
            </div>
            <FormGroup className="position-relative">
              <Label for="name">
                Peer Name <span className="text-danger">*</span>
              </Label>
              <Input
                name="name"
                id="name"
                invalid={Boolean(touched?.name ? errors?.name : false)}
                onBlur={handleBlur}
                onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                placeholder="Enter Peer name"
                type="text"
                value={values.name}
              />
              {touched.name ? errors?.name && <span className="text-danger">{errors?.name}</span> : null}
            </FormGroup>
            <div className="flex-wrap-bl order-2 order-lg-1">
              <div className="detail-block mt-0">
                <Label>
                  Set Home Region <span className="text-danger">*</span>
                </Label>
                <p className="c-tx-ninth"></p>
                <div className="out-wrap">
                  <div className="select-wrap overflow-auto" style={{ maxHeight: 250 }}>
                    {loading?.isLoading ? "Loading..." : null}
                    {regionsList.map((region) => {
                      return (
                        <div className="pg" key={region.id}>
                          <label>
                            <input
                              disabled={region?.disable}
                              type="radio"
                              name="homeRegion"
                              checked={values.region === region.id || false}
                              className="card-input-element"
                              onChange={(event: any) => {
                                if (event.target.checked) {
                                  setFieldValue("region", region.id);
                                }
                              }}
                            />
                            <div className="panel panel-default card-input">
                              <div className=" d-flex align-items-center justify-content-between">
                                <div className="wrapper-bl-block-first d-inline-flex w-80 flex-wrap">
                                  <div className="bl-b-1 bl-b">
                                    <img src={region.file} style={{ maxWidth: 30 }} alt="flag-1" className="img-fluid" />
                                    <p className="mb-0 c-tx-primary f-700">{region.name}</p>
                                  </div>
                                  <div className="bl-b-2 bl-b">
                                    <p className="mb-0 c-tx-ninth">{region.domain}</p>
                                  </div>
                                  <div className="bl-b-3 bl-b">
                                    <p className="mb-0 c-tx-ninth">{region.region}</p>
                                  </div>
                                </div>
                                <div className="wrapper-bl-block-second d-inline-flex">
                                  <div className="custom-radio-btn"></div>
                                </div>
                              </div>
                            </div>
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                handleValidation();
              }}
              className="custom-primary w-100 mt-2">
              send Request
            </Button>
          </div>
          <div className="right-block w-lg-50 ml-1 right-block-resn">
            <div className="flex-bl">
              <h5 className="tab-heading c-tx-primary f-700 ">What is a Home Region ?</h5>
              <p className="c-tx-ninth mb-2 small lh-18">
                The home region is the cloud provider and underlying region where information about the network (such as membership details) is stored. The available cloud providers are :
              </p>
              <div className="points-wrapper h-auto">
                <ul className="point-list">
                  <li>
                    <div className="icon">
                      <p className="c-tx-tenth mb-0 small lh-18">{Check}</p>
                    </div>
                    <p className="c-tx-tenth mb-0 small lh-18">AWS</p>
                  </li>
                  <li>
                    <div className="icon">
                      <p className="c-tx-tenth mb-0 small lh-18">{Check}</p>
                    </div>
                    <p className="c-tx-tenth mb-0 small lh-18">Microsoft Azure</p>
                  </li>
                </ul>
              </div>
              <p className="c-tx-ninth mb-0 small lh-18">Additional regions may be whitelisted during the next and final step of creating a network.</p>
              <div className="ct-link">
                <a href="https://edexa.network" target={"_blank"} rel="noreferrer" className="mb-0 readmore small f-700">
                  Learn more{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default memo(CreatePeerNodeModal);

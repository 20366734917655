import { useEffect, useState } from "react";

function CharacterPrinter({ text, delay }: any) {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (index < text.length) {
        setIndex((prevIndex) => prevIndex + 1);
      } else {
        clearInterval(intervalId);
      }
    }, delay);

    return () => {
      clearInterval(intervalId);
    };
  }, [index, text.length, delay]);

  const printedText = text.slice(0, index);

  return (
    <pre
      style={{ whiteSpace: "pre-wrap" }}
      dangerouslySetInnerHTML={{
        __html: printedText
      }}
      className="pt-2 font-16"></pre>
  );
}
export default CharacterPrinter;

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getMetricsMonitoringListAPI = (params: getMetricsMonitoringListAPIParams): Promise<getMetricsMonitoringListAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.peerNode.metricsMonitoring.list, params);
};

interface getMetricsMonitoringListAPIParams {
  limit: number;
  page: number;
}

interface getMetricsMonitoringListAPIRes {
  message: string;
  status: number;
  data: {
    count: number;
    data: any[];
  };
}

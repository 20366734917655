import { FormikContextType, useFormik } from "formik";
import React, { useState } from "react";
import { ContractUploadValidationSchema } from "utils/common/helper";
import { uploadSmartContractAPI } from "utils/common/helper/upload-smart-contract";
import { toastSuccess } from "utils/commonFunctions";

interface IContractFormikInitialValues {
  name: "";
  description: "";
  language: "";
  protocol: "";
  file: null;
}
interface IContractUploadContext {
  contractFormik: FormikContextType<IContractFormikInitialValues>;
  uploadPopup: boolean;
  toggleUploadModal: () => void;
  stepperModalOpen: boolean;
  toggleStepperModal: () => void;
  currentStep: number;
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  loading: boolean;
}
export const uploadContractContext = React.createContext<IContractUploadContext>(
  // setting default empty object, using ts ignore because I know what I am doing
  // @ts-ignore
  {}
);

const useUploadNewContract = (stepperModalOpen: boolean, toggleStepperModal: () => void) => {
  const [uploadPopup, setuploadPopup] = useState(false);
  const toggleUploadModal = () => setuploadPopup(!uploadPopup);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const contractUploadFormik = useFormik({
    initialValues: {
      name: "",
      description: "",
      language: "node",
      protocol: "",
      file: null
    },
    validationSchema: ContractUploadValidationSchema,
    onSubmit: (values) => {
      setLoading(true);
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("description", values.description);
      formData.append("protocol", values.protocol);
      formData.append("language", values.language);
      formData.append("file", values.file || "");
      uploadSmartContractAPI(formData)
        .then((res) => {
          toastSuccess(res.message);
          // reset form states
          setLoading(false);
          toggleUploadModal();
          toggleStepperModal();
          contractUploadFormik.resetForm();
          setCurrentStep(0);
        })
        .catch((Err) => {
          setLoading(false);
        });
    }
  });

  const handleReset = () => {
    contractUploadFormik.resetForm();
    toggleStepperModal();
  };

  // if needed to reset form when modal is close run this useeffect
  // const resetFormStates = () => {
  //   contractUploadFormik.resetForm();
  //   setCurrentStep(0);
  // };
  // useEffect(() => {
  //   return () => {
  //     resetFormStates();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const contractContextValues: IContractUploadContext = {
    contractFormik: contractUploadFormik as FormikContextType<IContractFormikInitialValues>,
    uploadPopup: uploadPopup,
    toggleUploadModal: toggleUploadModal,
    stepperModalOpen: stepperModalOpen,
    toggleStepperModal: handleReset,
    currentStep: currentStep,
    setCurrentStep: setCurrentStep,
    loading: loading
  };

  return { contractContextValues };
};

export default useUploadNewContract;

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const networkInfoAPI = (): Promise<InetworkInfoAPI> => {
  return HTTPService.get(apiEndPoints.universeAPI.network.info);
};

// Generated by https://quicktype.io

export interface InetworkInfoAPIResData {
  id: string;
  peerUrl: string;
  exploreUrl: string;
  certAuthUrl: string;
  restApiUrl: string;
  networkInfoNetworkId: NetworkInfoNetworkID;
  tour: boolean;
  network: any;
  peerData: any;
  userName: string;
  password: string;
}

export interface NetworkInfoNetworkID {
  id: string;
  networkRegionType: NetworkRegionType;
  networkDomainType: NetworkDomainType;
}

export interface NetworkDomainType {
  id: string;
  name: string;
}

export interface NetworkRegionType {
  file: string;
  id: string;
  name: string;
  default: boolean;
  visible: boolean;
  disable: boolean;
}

interface InetworkInfoAPI {
  message: string;
  status: number;
  data: InetworkInfoAPIResData;
}

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getMemberKycList = (params: IKycPagination): Promise<IKycList> => {
  return HTTPService.get(apiEndPoints.kycList, params);
};

export interface IKycPagination {
  search?: string;
  page?: number;
  limit: number;
  filter?: string;
}

interface IRows {
  createdAt: string;
  email: string;
  firstName: string;
  id: number;
  isApproved: number;
  lastName: string;
  statusReason: string;
  userId: string;
  submittedBy: string;
}
export interface IKycList {
  data: {
    count: number;
    rows: [IRows];
  };
  message: string;
  status: number;
}

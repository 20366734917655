import {
  faAngleDown,
  faAngleUp,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDebouncedEffect } from "hooks/UseDebouncedEffect";
import { FC, useState } from "react";
import { Button, Collapse, Input, Label } from "reactstrap";
import CheckboxInput from "./CheckboxInput";

interface IEvent {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  eventData: {
    action: string;
    event: string;
    status: number;
  }[];
  setEventData: React.Dispatch<
    React.SetStateAction<
      {
        action: string;
        event: string;
        status: number;
      }[]
    >
  >;
  mainEventData: any;
}

const Event: FC<IEvent> = ({
  setStep,
  eventData,
  setEventData,
  mainEventData,
}) => {
  const [eventTemp, setEventTemp] = useState<any[]>([]);
  const [tempData, setTempData] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState(eventData?.length > 0 ? true : false);

  const handleClick = (status: boolean, data: any) => {
    if (status) {
      const tempValue = mainEventData.find((item: any) => item.event === data);
      tempData.push(tempValue);
      setTempData(tempData);
    } else {
      setTempData(tempData.filter((item) => item.event !== data));
    }
  };

  const handleAddData = () => {
    setEventData(tempData);
    setStep(1);
  };

  useDebouncedEffect(
    () => {
      let data = eventData.filter((item) => {
        return String(item.event)
          .toLocaleLowerCase()
          .includes(String(search).toLocaleLowerCase())
          ? item
          : null;
      });
      setEventTemp(data);
    },
    [search],
    500
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    handleClick(e.target.checked, e.target.value);
  };

  const result: any[] = [];
  for (const item of mainEventData) {
    const [eventType] = item.event.split(".");
    let eventTypeData: any = result.find((entry) => entry.type === eventType);
    if (!eventTypeData) {
      eventTypeData = { type: eventType, data: [] };
      result.push(eventTypeData);
    }
    eventTypeData.data.push({
      event: item.event,
      action: item.action,
    });
  }

  return (
    <>
      <span
        className="cursor-pointer c-tx-secondary fw-bold"
        onClick={() => setStep(1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="mr-10" />
        Back
      </span>
      <h3 className="c-tx-third f-600 mb-2 mb-0 mt-1">Select events to send</h3>
      <Input
        className="mt-20"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search events..."
      />
      <Label className="mt-10 mb-10 c-tx-tenth" check>
        <h3 className="mt-3">bStamp Events</h3>
      </Label>
      <div className="webhooks-event">
        <div
          className="cursor-pointer p-2 prevent-select"
          style={{ borderBottom: "1px solid #dadce0" }}
          onClick={() => setStatus(!status)}
        >
          <div className="d-flex justify-content-between mb-2">
            {result.map((item: any) => (
              <p
                style={{ fontSize: "18px" }}
                className="fw-bold c-tx-third d-flex justify-content-center align-item-center"
              >
                <Input
                  type="checkbox"
                  checked={eventData?.length === mainEventData?.length}
                  className="mr-10"
                  onChange={(e) => {
                    if (e.target.checked) {
                      let temp: any[] = [];
                      result.map((item: any) => {
                        temp = item.data;
                      });
                      setTempData(temp);
                      setEventData(temp);
                    } else {
                      setEventData([]);
                    }
                  }}
                />
                {item.type[0].toUpperCase() + item.type.slice(1)}
              </p>
            ))}
            <div className="d-flex cursor-pointer c-tx-tenth justify-content-center align-items-center">
              <FontAwesomeIcon
                icon={!status ? faAngleDown : faAngleUp}
                className="ml-10"
              />
            </div>
          </div>
          {
            <Collapse isOpen={status} className="ml-20">
              <div
                onClick={(e) => e.stopPropagation()}
                className="prevent-select"
              >
                {status &&
                  result.map((item: any) =>
                    item.data.map((val: any, index: number) => (
                      <CheckboxInput
                        eventData={eventData}
                        val={val}
                        handleChange={handleChange}
                      />
                    ))
                  )}
              </div>
            </Collapse>
          }
        </div>
      </div>
      <div className="mt-20">
        <Button
          className="custom-primary custom-primary"
          onClick={handleAddData}
        >
          Add events
        </Button>
        <Button
          className="custom-primary-outline ml-20 custom-primary"
          onClick={() => setStep(1)}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default Event;

import { FC, useState } from "react";

const defualtImage = "https://edexa-portal-beta.s3.ap-south-1.amazonaws.com/users/defaultImg.png";
interface IUserHeaderImage {
  url: string;
  width?: string;
  height?: string;
}

const UserHeaderImage: FC<IUserHeaderImage> = ({ url, width, height }) => {
  const [error, setError] = useState(false);
  const handleError = () => {
    setError(true);
  };
  return <img src={error ? defualtImage : url ? url : defualtImage} alt="profile" className="img-fluid" onError={handleError} width={width ? width : 0} height={height ? height : 0} />;
};

export default UserHeaderImage;

import { DEVICE_REMOVE, PRIMARY_DEVICE, SECURITY_INSERT } from "./type";

const initial_state = {
  lastPasswordUpdate: null,
  data: []
};

const securityReducer = (state = initial_state, action: { type: string; payload: any; status: any }) => {
  let newstate = { ...state };
  switch (action.type) {
    case SECURITY_INSERT: {
      newstate = action.payload;
      return newstate;
    }

    case DEVICE_REMOVE: {
      let data = [...newstate.data];
      let newdata = data.filter((item: any) => item.deviceId !== action.payload);
      newstate.data = newdata;
      return newstate;
    }

    case PRIMARY_DEVICE: {
      let data: any = [...newstate.data];
      let index = 0;
      // eslint-disable-next-line
      data.map((item: any, indx: any) => {
        if (item.deviceId === action.payload) {
          index = indx;
        }
      });
      // let newdata: any = data.filter((item: any) => item.deviceId === action.payload);
      if (action.status === "0") {
        data[index].primaryDevice = true;
      } else {
        data[index].primaryDevice = false;
      }
      newstate.data = data;
      return newstate;
    }

    default:
      return newstate;
  }
};

export default securityReducer;

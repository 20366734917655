import axios from "axios";
import { apiEndPoints } from "utils/api-endpoints";
import { getDecryptedLocalStorage } from "utils/commonFunctions";
import { localStorageKeys } from "utils/constants";

export const getEventListAPI = () => {
const url = process.env.REACT_APP_WEBHOOK_APIBASEURL + apiEndPoints.webHook.getEventList;
const token = getDecryptedLocalStorage(localStorageKeys.authenticateToken);

const headers = {
  "Content-Type": "application/json", // Replace with the appropriate content type
  'Authorization': token?.token  && `Bearer ${token.token}` // Replace with your actual authorization token
};

  return new Promise((resolve, reject) => {
    axios
      .get(url, { headers })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response || error));
  });
};

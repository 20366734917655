import { SETNOTIFICATIONCOUNT } from "./type";

const initialState: INoticationReducer = {
  count: 0
};

const NoticationReducer = (state = initialState, action: { type: string; payload: number }) => {
  const newState = { ...state };
  switch (action.type) {
    case SETNOTIFICATIONCOUNT:
      newState.count = action.payload;
      break;
  }
  return newState;
};

export default NoticationReducer;

export interface INoticationReducer {
  count: number;
}

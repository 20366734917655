import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getChainCodeListAPI = (params: any): Promise<IgetChainCodeListAPI> => {
  return HTTPService.get(apiEndPoints.universeAPI.templates.listChainCode, params);
};

export interface IgetChainCodeListAPIData {
  disable: boolean;
  name: string;
  status?: number;
  id: string;
}

interface IgetChainCodeListAPI {
  status: number;
  message: string;
  data: {
    count: number;
    data: IgetChainCodeListAPIData[];
  };
}

import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { faArrowsRotate, faShieldAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendAPI } from "actions/chat/sendAPI";
import React, { useEffect, useRef, useState } from "react";
import { Container, Spinner } from "reactstrap";
import { ChatFeature, IChatFeature } from "utils/constants";
import "./chat.scss";
import Reply from "./components/reply";
import Send from "./components/send";

const Chat = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [networkError, setNetworkError] = useState({
    status: false,
    message: ""
  });
  const [feature, setFeature] = useState<IChatFeature | null>(null);
  const [list, setList] = useState<{ type: string; message: string }[]>([]);

  const sendAction = (networkMessage?: string) => {
    if (!isLoading) {
      const message = networkMessage ? networkMessage : search.trim();
      setIsLoading(true);
      if (!networkMessage) {
        setList([...list, { type: "send", message }]);
        setSearch("");
        setNumber(30);
      }
      sendAPI({ message, userOption: feature?.status })
        .then((res) => {
          if (res.status === 200) {
            setTimeout(() => {
              if (networkMessage) {
                setList([...list, { type: "reply", message: res.data.content }]);
              } else {
                setList([...list, { type: "send", message }, { type: "reply", message: res.data.content }]);
              }
            }, 100);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setNetworkError({ status: true, message });
          setIsLoading(false);
        });
    }
  };

  const handleChange = (e: any | React.ChangeEventHandler<HTMLInputElement> | undefined) => {
    if (e.target.value.trim() !== "") {
      if (e.target.value.replace(/[^\n]/g, "").length === 0) {
        if (number !== 30) {
          setNumber((no) => 30);
        }
      }
      if (e.target.value.replace(/[^\n]/g, "").length === 1) {
        if (number !== 60) {
          setNumber((no) => 60);
        }
      }
      if (e.target.value.replace(/[^\n]/g, "").length === 2) {
        if (number !== 90) {
          setNumber((no) => 90);
        }
      }
      if (e.target.value.replace(/[^\n]/g, "").length === 3) {
        if (number !== 120) {
          setNumber((no) => 120);
        }
      }
      setSearch(e.target.value);
    } else {
      setSearch("");
      setNumber(30);
    }
  };
  const myRef = useRef(null);
  const [number, setNumber] = useState(30);
  const handleKey = (e: any) => {
    if (e.keyCode === 13 && search.trim() === "") {
      setSearch("");
      setNumber((no) => 30);
    } else {
      if (search.trim() !== "" && e.keyCode === 13 && !e.shiftKey) {
        sendAction();
      }
    }
  };

  useEffect(() => {
    if (list.length > 1) {
      //  @ts-ignore
      myRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [list]);

  const handleRemoveFeature = () => {
    setFeature(null);
    setSearch("");
    setNumber((no) => 30);
    setList([]);
  };
  const handleClearChat = () => {
    setSearch("");
    setNumber((no) => 30);
    setList([]);
  };

  return (
    <div className="home-wrapper h-100">
      <div className="d-flex chat-main h-100">
        {/* <ChatSideBar /> */}
        <div className="chat-list">
          <Container className="chat-list-container position-relative">
            <div className="chat-list-reply ">
              {list.length === 0 ? (
                <div className="d-flex justify-content-center h-100 align-items-center">
                  <div>
                    <h1 className="text-center mb-40">edeXAInnovate</h1>
                    <div className="d-flex justify-content-center flex-wrap gap-5">
                      <div>
                        <h4 className="text-center mb-20">Create Smart Contracts</h4>
                        <div className="chat-card">
                          <p className="text-center">
                            <i>
                              Could you provide a simple example of a Solidity smart contract for the Ethereum network, which timestamps a given string of data and generates a unique identifier for
                              that data?
                            </i>
                          </p>
                        </div>
                        <div className="chat-card mt-20">
                          <p className="text-center">
                            <i>
                              Could you provide an example of an open auction contract written in Solidity for the Ethereum blockchain, where participants can bid and the highest bidder at the end of
                              the auction wins?
                            </i>
                          </p>
                        </div>
                      </div>
                      <div>
                        <h4 className="text-center mb-20"> Audit Smart contract </h4>
                        <div className="chat-card">
                          <p className="text-center">Ensure the security and reliability of your smart contract by conducting a comprehensive audit with our edeXAInnovate</p>
                        </div>
                        <div className="chat-card mt-20">
                          <p className="text-center">
                            <i>
                              {
                                "Hello, I have a Solidity smart contract that I would like to get audited for vulnerabilities and assess its efficiency. Could you please review my code and provide feedback? Here is the code for the contract:<code> "
                              }
                            </i>
                          </p>
                        </div>
                      </div>
                      <div>
                        <h4 className="text-center mb-20">Resolve Blockchain and smart contract issues</h4>
                        <div className="chat-card">
                          <p className="text-center">Get help with Troubleshooting Blockchain Development Issues</p>
                        </div>
                        <div className="chat-card mt-20">
                          <p className="text-center">Facilitating Understanding of Blockchain Scaling Solutions</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                list.map((res, index) => {
                  if (res.type === "send") {
                    return (
                      <Send
                        key={index}
                        error={networkError.status ? (list.length - 1 === index || list.length - 2 === index ? true : false) : false}
                        forwordRef={list.length - 1 === index || list.length - 2 === index ? myRef : null}
                        message={res.message}
                      />
                    );
                  } else {
                    return <Reply key={index} id={`chat-list-reply-${index}`} message={res.message} />;
                  }
                })
              )}
            </div>
            {feature ? (
              <div className="position-absolute w-100" style={{ bottom: 0 }}>
                {networkError.status ? (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <span className="mb-10 fw-bold">There was an error generating a response</span>
                    <div
                      className="feature-title-selected cursor-pointer font-16"
                      title="Selected Feature"
                      onClick={() => {
                        setNetworkError({ message: networkError.message, status: false });
                        sendAction(networkError?.message);
                      }}>
                      <FontAwesomeIcon className="mr-10" icon={faArrowsRotate} /> Regenerate response
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-between gap-3 mb-10">
                      <div className="d-flex gap-3">
                        <div className="feature-title-selected font-16" title="Selected Feature">
                          <FontAwesomeIcon className="mr-20" icon={faShieldAlt} /> {feature.title}
                        </div>
                        <button disabled={isLoading} className={`feature-title-remove ${isLoading ? "disabled" : ""} font-16`} title="Remove Feature" onClick={handleRemoveFeature}>
                          <FontAwesomeIcon className="mr-20" icon={faTrash} /> Remove Feature
                        </button>
                      </div>
                      {list.length > 1 ? (
                        <button disabled={isLoading} className={`feature-title-remove ${isLoading ? "disabled" : ""} font-16`} title="Remove Feature" onClick={handleClearChat}>
                          Clear Chat
                        </button>
                      ) : null}
                    </div>
                    <div className="chat-list-search font-16">
                      <textarea
                        className={`${number < 90 ? "inner" : ""} `}
                        style={{ height: `${number}px` }}
                        rows={1}
                        value={search}
                        onChange={handleChange}
                        onKeyUp={handleKey}
                        placeholder="Search..."
                      />
                      {isLoading ? (
                        <>
                          <Spinner size={"sm"} className="mr-10 ml-10" /> Loading...
                        </>
                      ) : search ? (
                        <FontAwesomeIcon icon={faPaperPlane} className="cursor-pointer" title="Clear" onClick={() => sendAction()} />
                      ) : null}
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="chat-list-search-feature">
                {ChatFeature.map((res, index) => (
                  <div key={index} className="chat-list-feature" onClick={() => setFeature(res)}>
                    <h6 className="fw-bold font-16">{res.title}</h6>
                    <span className="font-14">
                      {res.subTitle} <FontAwesomeIcon className="ml-10" icon={res.icon} />
                    </span>
                  </div>
                ))}
              </div>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Chat;

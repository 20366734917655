import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const renameFolderAPI = (id: string, data: IrenameFolderAPIData): Promise<IrenameFolderAPI> => {
  return HTTPService.patch(apiEndPoints.universeAPI.folderTemplate.rename + id, data);
};

interface IrenameFolderAPIData {
  name: string;
}

interface IrenameFolderAPI {
  message: string;
  status: number;
}

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const networkStatusInfoAPI = (): Promise<InetworkInfoAPI> => {
  return HTTPService.get(apiEndPoints.universeAPI.network.get);
};

export interface InetworkStatusInfoAPIData {
  id: string;
  algorithmType: string;
  createdAt: string;
  deletedAt: string;
  envName: string;
  networkDescription: string;
  networkName: string;
  provider: string;
  region: string;
  status: number;
  statusReason: string;
  type: string;
  updatedAt: string;
  userId: string;
  userInfo: {
    name: string;
    email: string;
    id: string;
  };
  networkRegionType: { name: string };
  networkDomainType: { name: string };
}

interface InetworkInfoAPI {
  message: string;
  status: number;
  data: InetworkStatusInfoAPIData;
}

import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Modal } from "reactstrap";
import { IRootReducer } from "store/root-reducer";
import { closeTemplateModal, setTemplateData } from "store/templateReducer/action";

const SuccessTemplateModal = () => {
  const dispatch = useDispatch();
  const { SuccessTemplateModal } = useSelector((state: IRootReducer) => state.TemplateReducer.modal);

  return (
    <Modal
      isOpen={SuccessTemplateModal}
      className="custom-popup-channel"
      style={{ maxWidth: 699 }}
      toggle={() => dispatch(closeTemplateModal("SuccessTemplateModal"))}
      size="lg"
      centered
      external={
        <Link
          to="#"
          className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30"
          onClick={() => {
            dispatch(setTemplateData({}));
            dispatch(closeTemplateModal("SuccessTemplateModal"));
          }}>
          CLOSE
        </Link>
      }>
      <div className="p-4">
        <div className="user-management-popup confirm-popup-wrapper position-relative">
          <div className="content-wrapper flex-column ">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
              <path
                id="Path_123"
                data-name="Path 123"
                d="M98,48a50,50,0,1,0,50,50A49.992,49.992,0,0,0,98,48Zm-7.716,67.716a3.316,3.316,0,0,1-2.115,1.058,3.466,3.466,0,0,1-2.139-1.082L72.567,102.231l4.279-4.279L88.192,109.3l30-30.216,4.207,4.351Z"
                transform="translate(-48 -48)"
                fill="#007de4"
              />
            </svg>
            <h1 className="h1 f-600 mt-20">Thank you</h1>
            <p className="c-tx-ninth text-center mb-20 mt-20">Successfully your template has been added to view, please go templates listing page.</p>
            <Button className="custom-primary key mt-20" onClick={() => dispatch(closeTemplateModal("SuccessTemplateModal"))}>
              Done
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default memo(SuccessTemplateModal);

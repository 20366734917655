import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { StepperStepsPropsType } from "./Stepper";

export function SteppersecondTab({ formik, currentStep }: StepperStepsPropsType) {
  return (
    <>
      {/* Tab2 Code start */}
      {/* <div className="tab-2 tab-block h-100">
                <div className="left-block h-100"> */}
      <div className="flex-wrap-bl order-2 order-lg-1">
        <h5 className="tab-heading c-tx-primary f-700">Enter Network Details</h5>
        <FormGroup className="m-0">
          <Label for="exampleEmail">Name of this Network</Label>
          <Input
            id="networkName"
            name="networkDetails.name"
            value={formik.values.networkDetails.name}
            onChange={(event) => {
              formik.setFieldValue(event.target.name, event.target.value);
              formik.validateField("networkDetails.name");
            }}
            invalid={Boolean(formik.errors?.networkDetails?.name) && Boolean(formik.touched?.networkDetails?.name)}
            placeholder="Enter the name of this network"
            type="text"
          />
          <FormFeedback>{formik.errors?.networkDetails?.name}</FormFeedback>
        </FormGroup>
        <FormGroup className="m-0">
          <Label for="exampleEmail">Network Mission Statement</Label>
          <Input
            id="exampleEmail"
            name="networkDetails.missionStatement"
            value={formik.values.networkDetails.missionStatement}
            onChange={(event) => {
              formik.handleSubmit();
              formik.setFieldValue(event.target.name, event.target.value);
            }}
            invalid={Boolean(formik.errors?.networkDetails?.missionStatement) && Boolean(formik.touched?.networkDetails?.missionStatement)}
            placeholder="Enter the network mission statement"
            type="textarea"
          />
          <FormFeedback>{formik.errors?.networkDetails?.missionStatement}</FormFeedback>
        </FormGroup>
      </div>
      {/* <div className="d-flex align-items-center justify-content-between w-100 mt-auto mb-2 mb-lg-0 order-1 order-lg-2">
                        <div className="d-inline-flex">
                            <button className="custom-primary ext-small disable" disabled onClick={() => onClickBack()}>Back</button>
                        </div>
                        <div className="d-inline-flex">
                            <button className="custom-primary ext-small" onClick={() => onClickNext()}>Next</button>
                        </div>
                    </div>

                </div> */}
      {/* <div className="right-block">
                    <div className="flex-bl">
                        <h5 className="tab-heading c-tx-primary f-700 ">Network Summary</h5>
                        <p className="c-tx-ninth mb-2 small">A network or "consortium" is a business network comprised of a group of organizations and/or multiple members who have a shared business goal. At this step, you are tasked with supplying the following pieces of configurations:</p>
                        <div className="points-wrapper h-auto">
                            <ul className="point-list">
                                <li><div className="icon"><p className="c-tx-tenth mb-0 small lh-18">{Check}</p></div><p className="c-tx-tenth mb-0 small lh-18">Name - Identity of the consortia</p></li>
                                <li><div className="icon"><p className="c-tx-tenth mb-0 small lh-18">{Check}</p></div><p className="c-tx-tenth mb-0 small lh-18">Mission - Optional prose or legalese describing business purpose, bylaws, objectives, etc.</p></li>
                            </ul>
                        </div>
                        <p className="c-tx-ninth mb-0 small">All operations are in this tier are off-chain, and include: cloud + region whitelisting membership creation, certificate management, governance, and network auditing.</p>
                        <div className="ct-link">
                            <Link to='/' className="mb-0 readmore small f-700">Learn more </Link>
                        </div>
                    </div>
                </div> */}

      {/* </div> */}
      {/* Tab2 Code ends */}
    </>
  );
}

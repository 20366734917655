import { FC, memo } from "react";
import { useSelector } from "react-redux";
import { IRootReducer } from "store/root-reducer";

interface INoFoundData {
  columnCount: number;
}

const NoFoundData: FC<INoFoundData> = ({ columnCount }) => {
  const { isLoading } = useSelector((state: IRootReducer) => state.loading);

  return (
    <tr>
      <td colSpan={columnCount} className="text-center">
        {isLoading ? "Loading..." : "No Data Found"}
      </td>
    </tr>
  );
};

export default memo(NoFoundData);

import { faDashcube } from "@fortawesome/free-brands-svg-icons";
import { faGlobeAfrica, faHouseDamage, faMessage, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootReducer } from "store/root-reducer";
import { toastSuccess } from "utils/commonFunctions";

const iconF = <FontAwesomeIcon icon={faHouseDamage} />;
const Glob = <FontAwesomeIcon icon={faGlobeAfrica} />;
const Application = <FontAwesomeIcon icon={faDashcube} />;
const Chat = <FontAwesomeIcon icon={faMessage} />;
const User = <FontAwesomeIcon icon={faUser} />;

const MobileFooter = () => {
  const navigate = useNavigate();
  const handleRedireact = (url: string) => {
    navigate(url);
  };

  const { user } = useSelector((state: IRootReducer) => state.userDetails) || {};
  return (
    <ul className="mobile-footer">
      {!user?.universeMaintenance ? (
        <li onClick={() => handleRedireact("/")}>
          <div className="icon">{Glob}</div>
          <div className="text-ctn">Universe</div>
        </li>
      ) : null}
      <li onClick={() => handleRedireact("/application")}>
        <div className="icon">{Application}</div>
        <div className="text-ctn">Application</div>
      </li>
      {user?.ownerOrg === true ? (
        <li
          onClick={(event) => {
            event.preventDefault();
            user?.kycApproved === 1 ? handleRedireact("/user-management") : toastSuccess("To Access this feature, you need to get your kyc approved.");
          }}>
          <div className="icon">{User}</div>
          <div className="text-ctn">User</div>
        </li>
      ) : null}
      <li onClick={() => handleRedireact("/chat")}>
        <div className="icon">{Chat}</div>
        <div className="text-ctn">edeXAInnovate</div>
      </li>
      <li onClick={() => handleRedireact("/apis")}>
        <div className="icon">{iconF}</div>
        <div className="text-ctn">Apis</div>
      </li>
    </ul>
  );
};
export default memo(MobileFooter);

import Pagination from "rc-pagination";
import "rc-pagination/assets/index.css";
import { FC, memo } from "react";
import "../../styles/pagination/pagination.css";

const PaginationComponent: FC<IPaginationComponent> = ({ pagination, setPagination }) => {
  const handleClick = (e: any) => {
    setPagination({ ...pagination, page: e });
  };

  return <Pagination pageSize={pagination.limit} onChange={(e: any) => handleClick(e)} current={pagination.page} total={pagination.totalCount} />;
};

export default memo(PaginationComponent);

interface IPaginationComponent {
  pagination: any;
  setPagination: any;
}

import { FC, memo } from "react";

const LineComponent: FC<{ number: number }> = ({ number }) => {
  if (number > 3) {
    return null;
  } else {
    return <hr className="my-0" />;
  }
};

export default memo(LineComponent);

import { Modal, ModalBody } from "reactstrap";
import { toastSuccess } from "utils/commonFunctions";

/**
 *
 * @param {handleOnClickAction} - a function () => {}
 * @param {title} - a string
 * @returns null
 *
 * This modal will take a function and title as Input.
 * 1. If user click on yes then the function will execute and the respective action will be performed.
 * 2. The title will be shown as modal title.
 *
 * usages example -
 *
 * const apiSubscriptionToggle = () => {
 *   @todo - perform your operations here
 * }
 * <ConfirmationModal handleOnClickAction={apiSubscriptionToggle} title='Are you sure you want to unsubscribe' />
 *
 * You can also pass an state to handleOnClickAction which can contain a function, you may use state to send dynamic function as per the user action
 *
 * for example -
 *  const [addRemoveFunction, setAddRemoveFunction] = useState<any>({fnCall: apiSubscriptionToggle});
 *
 *  Now you can pass this state to ConfirmationModal component-
 *  <ConfirmationModal handleOnClickAction={addRemoveFunction} title='YOUR DYNAMIC TITLE' />
 */

const CommonConfirmationModal: React.FC<propTye> = ({ handleActions, featureModelVisibility, toggleFeatureModel, value, subTitle, title }) => {
  return (
    <Modal centered isOpen={featureModelVisibility} toggle={toggleFeatureModel} style={{ maxWidth: "450px" }} backdropClassName="confirmation-model-parent" backdrop="static">
      <ModalBody>
        <div style={{ padding: "30px" }}>
          <h4 className="fw-bold text-center mb-3">{title}</h4>
          <p className="fw-normal text-center font-18 " style={{ color: "#868686", fontWeight: "100" }}>
            {subTitle}
          </p>
        </div>
        <div className="row" style={{ borderTop: "1px solid #dadce0" }}>
          <div className="col-6 p-10 d-flex cursor-pointer" style={{ borderRight: "1px solid #dadce0" }} onClick={toggleFeatureModel}>
            <button className="confirmation-button text-danger">No</button>
          </div>
          <div
            className="col-6 p-10 d-flex cursor-pointer"
            onClick={() => {
              handleActions(value);
              toggleFeatureModel();
              toastSuccess("Successfully Changed");
            }}>
            <button className="confirmation-button text-success">Yes</button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CommonConfirmationModal;

interface propTye {
  handleActions: Function;
  value: any;
  subTitle: string;
  featureModelVisibility: boolean;
  title: string;
  toggleFeatureModel: any;
}

import { memo } from "react";
import { useSelector } from "react-redux";
import { IRootReducer } from "store/root-reducer";

const NoFoundDiv = () => {
  const { isLoading } = useSelector((state: IRootReducer) => state.loading);

  return (
    <>
      <li className="border-0">
        <div className="block-1 w-100">
          <p className="c-tx-primary f-700 first-title">{isLoading ? "Loading..." : "No Found Data"}</p>
        </div>
      </li>
    </>
  );
};

export default memo(NoFoundDiv);

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const RemindAgainAPI = (invitationId: string): Promise<IRemindAgainAPI> => {
  return HTTPService.patch(`${apiEndPoints.universeAPI.channelManagement.remainder}/${invitationId}`, "");
};

interface IRemindAgainAPI {
  message: string;
  status: number;
}

import { useRoutes } from "react-router-dom";
import { publicRoutesV6 } from "./Routes";

export const PublicRouteComponent = () => {
  /**
   * @routes_rendring_logic
   * render routes from an array
   */
  const renderPublicRoutes = useRoutes(publicRoutesV6);
  return <>{renderPublicRoutes}</>;
};

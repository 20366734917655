import { faEdit } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faPlus,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createWebHooksAPI } from "actions/webHook/createWebHooksAPI";
import { getEventListAPI } from "actions/webHook/getEventListAPI";
import { useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container, FormFeedback, Input, Label } from "reactstrap";
import { setLoading } from "store/loader/action";
import { redirectTo, toastError } from "utils/commonFunctions";
import { validationRegex } from "utils/constants";
import * as Yup from "yup";
import Event from "./components/event";
import "./webhooks.scss";

const WebhooksCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eventData, setEventData] = useState<any[]>([]);
  const [step, setStep] = useState(1);
  const webHookForm = useFormik({
    initialValues: {
      redirectUrl: "",
      description: "",
      action: [],
    },
    validationSchema: Yup.object().shape({
      redirectUrl: Yup.string()
        .matches(
          validationRegex.onlyHttpsRegex,
          "Redirect URL must be a valid HTTPS URL"
        )
        .required("Redirect URL is required"),
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      handleCreateWebHooks();
    },
  });
  const { touched, errors, values, handleSubmit, handleBlur, setFieldValue } =
    webHookForm;

  const handleCreateWebHooks = () => {
    const action = eventData.map((item) => item.event);
    dispatch(setLoading(true));
    let data = {
      redirectUrl: values.redirectUrl,
      description: values.description,
      action,
    };
    createWebHooksAPI(data)
      .then((res: any) => {
        if (res.status === 200) {
          navigate("/webhooks");
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        toastError(err.data.message);
      });
  };

  const [mainEventData, setMainEventData] = useState();

  const handleEvent = () => {
    dispatch(setLoading(true));
    getEventListAPI()
      .then((res: any) => {
        if (res.status === 200) {
          setMainEventData(res.data);
          setStep(2);
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        toastError(err.data.message);
      });
  };

  const eventRemove = (event: string) => {
    setEventData((data) => {
      return eventData.filter((data: any) => data.event !== event);
    });
  };

  return (
    <div className="p-4">
      <Container>
        {step === 1 ? (
          <>
            <span
              className="cursor-pointer c-tx-secondary fw-bold"
              onClick={() => navigate("/webhooks")}
            >
              <FontAwesomeIcon icon={faArrowLeft} className="mr-10" />
              Back
            </span>
            <h1 className="c-tx-primary mb-md-0 f-700 mt-1">
              Listen to bStamp events
            </h1>
            <div className="mt-20">
              <p className="c-tx-tenth h6">
                Set up your webhook endpoint to receive live events from Webhook
                or{" "}
                <b
                  className="c-tx-secondary cursor-pointer"
                  onClick={() => {
                    redirectTo(process.env.REACT_APP_moreAboutWebhooks);
                  }}
                >
                  learn more about webhooks.
                </b>
              </p>
            </div>
            <div className="mt-10">
              <div>
                <Label className="c-tx-third">Endpoint URL</Label>
                <Input
                  name="redirectUrl"
                  placeholder="https://"
                  value={values.redirectUrl}
                  onChange={(e) => {
                    if (e.target.value.trim() === "") {
                      setFieldValue(e.target.name, e.target.value.trim());
                    } else {
                      setFieldValue(e.target.name, e.target.value);
                    }
                  }}
                  onBlur={handleBlur}
                  invalid={Boolean(touched.redirectUrl && errors.redirectUrl)}
                />
                <FormFeedback invalid>
                  {touched.redirectUrl && errors.redirectUrl}
                </FormFeedback>
              </div>
              <div className="mt-10">
                <Label className="c-tx-third">Description</Label>
                <Input
                  name="description"
                  type="textarea"
                  placeholder="An optional description of what this webhook endpoint is used for..."
                  value={values.description}
                  onChange={(e) => {
                    if (e.target.value.trim() === "") {
                      setFieldValue(e.target.name, e.target.value.trim());
                    } else {
                      setFieldValue(e.target.name, e.target.value);
                    }
                  }}
                  onBlur={handleBlur}
                  invalid={Boolean(touched.description && errors.description)}
                />
                <FormFeedback invalid>
                  {touched.description && errors.description}
                </FormFeedback>
              </div>
              <div className="mt-10">
                <Label className="c-tx-third">Select events to listen to</Label>
                <div className="d-flex mb-10">
                  {eventData &&
                    eventData.map((data, index) => (
                      <div
                        key={index}
                        className={`webhooks-label-outline ${
                          index > 0 ? "ml-10" : ""
                        } cursor-pointer`}
                        onClick={() => eventRemove(data.event)}
                      >
                        {data.event}
                        <FontAwesomeIcon className="ml-10" icon={faXmark} />
                      </div>
                    ))}
                </div>
                <div className="d-flex">
                  {eventData.length > 0 ? (
                    <div
                      className="webhooks-label-fill cursor-pointer"
                      onClick={() => {
                        setStep(2);
                      }}
                    >
                      <FontAwesomeIcon className="mr-10" icon={faEdit} />
                      Change event
                    </div>
                  ) : (
                    <div
                      className="webhooks-label-fill cursor-pointer"
                      onClick={() => handleEvent()}
                    >
                      <FontAwesomeIcon className="mr-10" icon={faPlus} />
                      Add event
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-20">
                <Button
                  className="custom-primary custom-primary"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Add endpoint
                </Button>
                <Button
                  className="custom-primary-outline ml-20 custom-primary"
                  onClick={() => {
                    navigate("/webhooks");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        ) : (
          <Event
            setStep={setStep}
            eventData={eventData}
            mainEventData={mainEventData}
            setEventData={setEventData}
          />
        )}
      </Container>
    </div>
  );
};

export default WebhooksCreate;

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const viewFolderAPI = (id: string): Promise<IviewFolderAPI> => {
  return HTTPService.get(apiEndPoints.universeAPI.folderTemplate.view + id);
};

interface IrepositoryChaincode {
  id: string;
  chainCodeTemplateId: {
    name: string;
    description: string;
  };
  chainCodeUserId: { name: string; email: string; userId: string };
  chainCodeChannelId: { channelName: string; id: string };
}

export interface IviewFolderAPIResData {
  id: string;
  name: string;
  // userId: string;
  // createdAt: string;
  // updatedAt: string;
  // deletedAt: string;
  repositoryChaincode: IrepositoryChaincode[];
}

interface IviewFolderAPI {
  message: string;
  status: number;
  // data: {
  //   count: number;
  //   rows: IviewFolderAPIResData[];
  // };
  data: IviewFolderAPIResData;
}

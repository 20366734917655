import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";
import { IParams } from "./userSearchAPI";

export const installationContractListAPI = (params: IParams): Promise<IinstallationContractListAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.smartContract.installationContract, params);
};

export interface IinstallationContractListAPIResData {
  status: number;
  contrChlContract: {
    name: string;
    language: string;
  };
  updatedAt: string;
  contractId: string;
}

interface IinstallationContractListAPIRes {
  messgae: string;
  status: number;
  data: {
    count: number;
    data: IinstallationContractListAPIResData[];
  };
}

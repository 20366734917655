import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const deleteNotificationAPI = (data: IdeleteNotificationAPIData): Promise<IdeleteNotificationAPI> => {
  return HTTPService.delete(apiEndPoints.universeAPI.notification.delete + `/?read=${data.read}`, data?.notificationId?.length > 0 && data);
};

interface IdeleteNotificationAPIData {
  notificationId: string[];
  read: boolean;
}

interface IdeleteNotificationAPI {
  status: number;
  message: string;
}

import { FC, memo } from "react";
import { useSelector } from "react-redux";
import { IRootReducer } from "store/root-reducer";
import BadgeBlock from "../badgeBlocks/BadgeBlock";

interface INoFoundTemplate {
  logo: string;
  bageName: string;
  message: string;
}

const NoFoundTemplate: FC<INoFoundTemplate> = ({ logo, bageName, message }) => {
  const { isLoading } = useSelector((state: IRootReducer) => state.loading);

  return (
    <div className="element">
      <div className="templateblock">
        <div className="block-1">
          <div className="badge-bl">
            <BadgeBlock theme="dark" name={bageName} />
          </div>
          <img src={logo} className="img-fluid" alt="zip" />
        </div>
        <div className="block-2">
          <div className="bl_1 text-truncate">
            <h6 className="c-tx-primary mb-0 text-truncate">{isLoading ? "Loading..." : message}</h6>
            <p className="c-tx-ninth">-</p>
          </div>
          <div className="bl_2"></div>
        </div>
      </div>
    </div>
  );
};

export default memo(NoFoundTemplate);

import { Spinner } from "reactstrap";

const FullPageLoader = () => {
  return (
    <div className="loader-wrapper" style={{ zIndex: 199999 }}>
      <Spinner type="grow" color="light" />
    </div>
  );
};

export default FullPageLoader;

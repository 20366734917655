import { DefaultEventsMap } from "@socket.io/component-emitter";
import { createContext, FunctionComponent, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { io, Socket } from "socket.io-client";

export const WebsocketContext = createContext<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null);

const WebsocketProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }: { children: ReactNode }) => {
  const [connection, setConnection] = useState<Socket<DefaultEventsMap, DefaultEventsMap> | null>(null);

  const options = useMemo(
    () => ({
      path: process.env.REACT_APP_SOCKETPATH,
      transports: ["websocket", "polling"]
    }),
    []
  );

  useEffect(() => {
    try {
      const socketConnection = io(process.env.REACT_APP_UNIVERSESOCKETPOINT || "", options);
      setConnection(socketConnection);
    } catch (err) {
      console.log(err);
    }
  }, [options]);

  return <WebsocketContext.Provider value={connection}>{children}</WebsocketContext.Provider>;
};

export const useWebsocket = (): Socket<DefaultEventsMap, DefaultEventsMap> | null => {
  const ctx = useContext(WebsocketContext);
  if (ctx === undefined) {
    throw new Error("useWebsocket can only be used inside WebsocketContext");
  }
  return ctx;
};

export default WebsocketProvider;

import { TOGGLEUNIVERSESIDEBAR } from "./type";

const initialState = {
  isUniverseSdebarActive: false
};

const universeSidebarReducer = (state = initialState, action: { type: string; payload: boolean }) => {
  const newState = { ...state };

  switch (action.type) {
    case TOGGLEUNIVERSESIDEBAR:
      newState.isUniverseSdebarActive = action.payload;
      break;
  }
  return newState;
};

export default universeSidebarReducer;

export interface IUniverseSidebar {
  isUniverseSdebarActive: boolean;
}

import { RemindAgainAPI } from "actions/RemindAgainAPI";
import { IviewChannelAPIResData, viewChannelAPI } from "actions/viewChannelAPI";
import LoadingSpinner from "components/loader/LoadingSpinner";
import { NoFoundData } from "components/notFoundPages";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Modal, Table } from "reactstrap";
import { setLoading } from "store/loader/action";
import { getFormattedDate, handleEmail, toastSuccess } from "utils/commonFunctions";
import { ChannelListViewParticipantsEnum } from "utils/constants";
import AddParticipatComponent from "./AddParticipatComponent";

const tableHeader = ["ID", "PARTICIPANTS", "STATUS OF INVITATION", "DATE", ""];

interface IAddParticipatModalComponent {
  modal: boolean;
  id: string;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddParticipatModalComponent: FC<IAddParticipatModalComponent> = ({ modal, setModal, id }) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [participantList, setParticipantList] = useState<IviewChannelAPIResData[]>([]);
  const [paginationConfig, setPaginationConfig] = useState({
    limit: 10,
    page: 1,
    totalCount: 0
  });

  const viewChannelAction = () => {
    dispatch(setLoading(true));
    setLoader(true);
    viewChannelAPI(id).then((res) => {
      if (res.status === 200) {
        setParticipantList(res?.data?.data);
        setPaginationConfig({
          ...paginationConfig,
          totalCount: res?.data?.count
        });
        setLoader(false);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    modal && id && viewChannelAction();
    // eslint-disable-next-line
  }, [id, modal]);

  const handleStatus = (status: number) => {
    if (status === ChannelListViewParticipantsEnum.InvitationSent) {
      return "Invitation Sent";
    } else if (status === ChannelListViewParticipantsEnum.InvitationAccepted) {
      return "Accepted";
    } else {
      return "Rejected";
    }
  };
  const handleRemindAgainAction = (invitationId: string) => {
    dispatch(setLoading(true));
    RemindAgainAPI(invitationId).then((res) => {
      if (res.status === 200) {
        toastSuccess(res.message);
        dispatch(setLoading(false));
      }
    });
  };
  return (
    <>
      <Modal
        isOpen={modal}
        className="custom-popup-channel"
        toggle={() => {
          setModal(false);
        }}
        size="xl"
        centered
        external={
          <Link
            to="#"
            className="text-white cursor-pointer close modal-close close small text-uppercase thankYouCloseButtonHide font-30 fw-bold"
            onClick={() => {
              setModal(false);
            }}>
            Close
          </Link>
        }>
        <div className="custom-card-wrapper ct-modal-wrapper add-participant">
          <div className="left-block">
            <div className="title">
              <h5 className="c-tx-primary f-700 mb-20">Channel Details</h5>
            </div>
            <div className="ct-table mh-100 px-0">
              <Table>
                <thead>
                  <tr>
                    {tableHeader.map((data: string, i: number) => (
                      <th className={`${data === "" || data === "STATUS OF INVITATION" ? "action-th" : ""}`} key={i}>
                        {data}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={tableHeader.length} className="text-center">
                        <LoadingSpinner size="sm" />
                      </td>
                    </tr>
                  ) : participantList?.length > 0 ? (
                    participantList?.map((data: IviewChannelAPIResData, i: number) => (
                      <tr key={i}>
                        <td>{paginationConfig.limit * paginationConfig.page + (i + 1) - paginationConfig.limit}</td>
                        <td>{data?.invitationSentTo?.name || handleEmail(data?.invitationSentTo?.email)}</td>
                        <td>{handleStatus(data?.status)}</td>
                        <td>{getFormattedDate(data?.createdAt)}</td>
                        <td>
                          {data.status === ChannelListViewParticipantsEnum.InvitationSent ? (
                            <div className="btn-wrapper_block">
                              <Button className="custom-primary key w-100" onClick={() => handleRemindAgainAction(data?.id)}>
                                REMIND AGAIN
                              </Button>
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoFoundData columnCount={tableHeader.length} />
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          {modal && <AddParticipatComponent setModal={setModal} modal={modal} viewChannelAction={viewChannelAction} id={id} />}
        </div>
      </Modal>
    </>
  );
};

export default AddParticipatModalComponent;

import React, { useState, useEffect } from 'react';
import EnabledArrow from '../../../../assets/Images/down-arrow.svg';
import DisabledArrow from '../../../../assets/Images/disable-down-arrow.svg';
import { useNavigate } from 'react-router-dom';

const FifthSection = ({ handleNextClick }: any) => {
  const [orderersButtonDisabled, setOrderersButtonDisabled] = useState(true);
  const [smartContractsButtonDisabled, setSmartContractsButtonDisabled] =
    useState(true);
  const [isLastButtonEnabled, setIsLastButtonEnabled] = useState(false);
  const navigation = useNavigate();
  const handleLastButtonEnable = () => {
    setIsLastButtonEnabled(true);
  };

  useEffect(() => {
    const enableOrderersButton = setTimeout(() => {
      setOrderersButtonDisabled(false);
    }, 1000);

    const enableSmartContractsButton = setTimeout(() => {
      setSmartContractsButtonDisabled(false);
    }, 4000);

    const enableLastButton = setTimeout(() => {
      handleLastButtonEnable();
    }, 5000);

    return () => {
      clearTimeout(enableOrderersButton);
      clearTimeout(enableSmartContractsButton);
      clearTimeout(enableLastButton);
    };
  }, []);

  return (
    <>
      <div className="section section-custom-main">
        <div className="container-main">
          <div className="div-main">
            <div className="div-sub" style={{ marginTop: '185px' }}>
              <p className="private-blockchain-text">
                Adding configuration on Blockchain
              </p>
              <button className="go-to-orderers">Peers Node</button>
              <div className="first-arrow-div">
                <div className="first-arrow-container" id="arrowContainer">
                  <div className="first-arrow-line" />
                  <div className="first-down-arrow" />
                </div>
              </div>
              <button
                className="go-to-orderers"
                disabled={orderersButtonDisabled}
              >
                Orderers
              </button>
              <div className="second-arrow-div">
                <div className="second-arrow-container" id="arrowContainer">
                  <div className="second-arrow-line" />
                  <div className="second-down-arrow" />
                </div>
              </div>
              <button
                className="go-to-smart-contracts"
                disabled={smartContractsButtonDisabled}
              >
                Smart Contracts
              </button>
              {isLastButtonEnabled && (
                <button
                  className="go-to-dashboard"
                  onClick={() => navigation('/universe/dashbaord')}
                >
                  Go to Dashboard
                </button>
              )}
            </div>  
          </div>
        </div>
      </div>
      <div className="div-footer">
        <button className="third-footer-button">
          Congratulations ! <br />
          Your very own Private Ecosystem on Blockchain is now created. <br />
          🚀 Time to explore further – navigate to the Dashboard. Click on "Go
          to Dashboard" and immerse yourself in the possibilities.
        </button>
      </div>
    </>
  );
};

export default FifthSection;

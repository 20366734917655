import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getTemplateListAPI = (params: any): Promise<IgetTemplateListAPI> => {
  return HTTPService.get(apiEndPoints.universeAPI.templates.list, params);
};

export interface IgetTemplateListAPIData {
  description: string;
  disable: boolean;
  status?: number;
  name: string;
  id: string;
}

interface IgetTemplateListAPI {
  status: number;
  message: string;
  data: {
    count: number;
    data: IgetTemplateListAPIData[];
  };
}

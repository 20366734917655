/* eslint-disable react-hooks/rules-of-hooks */
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfirmationModal } from "components/models";
import { AddNewUser, UpdateMemberPermissions } from "components/userManagementModel";
import { useDebouncedEffect } from "hooks/UseDebouncedEffect";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Modal, Row, Spinner, Table } from "reactstrap";
import { setConfirmationModalVisibility } from "store/confirmationModule/action";
import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { deleteMemberById } from "utils/common/helper/deleteMemberById";
import { getAllMembers } from "utils/common/helper/getAllMembers";
import { reduceWhiteSpace } from "utils/commonFunctions";
import { initialPaginationConfigs } from "utils/constants";
import "../../../styles/userManagement/userManagement.scss";

const closeIconRegular = <FontAwesomeIcon icon={faTimesCircle} />;

const UserManagement = () => {
  const { user } = useSelector((state: IRootReducer) => state.userDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  if (user?.kycApproved !== 1 || user?.ownerOrg === false) {
    navigate("/");
  }

  const [addUpdateModal, setAddUpdateModal] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [updateUserId, setUpdateUserId] = useState("");
  const toggleAddUpdateModal = () => {
    setAddUpdateModal(!addUpdateModal);
  };

  // redux states
  const { isLoading } = useSelector((state: IRootReducer) => state.loading);

  /**
   * @members_action_dropdown_state
   */
  // members actions state management
  const [userActionDropdown, setUserActionDropdown] = useState<boolean[]>([]);
  const toggleDropdown = (dropdownPosition: number) => {
    // toggle the particular index position as per user action
    const userActionDropdownOperationalArray = [...userActionDropdown].map((item, index) => (index === dropdownPosition ? !item : item));
    setUserActionDropdown(userActionDropdownOperationalArray);
  };

  /**
   * @members_listing
   * @api
   */
  // user management states
  const [allMembers, setAllMembers] = useState([]);
  const [allMembersCount, setAllMembersCount] = useState(0);

  const [memberUpdated, setIsMemberUpdated] = useState(false);
  const toggleMemberUpdated = useCallback(() => {
    setIsMemberUpdated(!memberUpdated);
  }, [memberUpdated]);

  // load / fetch members api data
  const handleGetAllMembersAPICall = () => {
    let pagination: any = {};
    Object.keys(paginationConfig).forEach((data) => {
      // @ts-ignore
      if (paginationConfig[data] !== "") {
        // @ts-ignore
        pagination[data] = paginationConfig[data];
      }
    });
    dispatch(setLoading(true));
    getAllMembers(pagination).then((res: any) => {
      dispatch(setLoading(false));
      // set members listing
      setAllMembers(res?.data?.rows);
      // update dropdown toggle array
      // by default all indexes will be false
      setUserActionDropdown(res?.data?.rows.map((item: any) => false));

      // set users list count
      setAllMembersCount(res?.data?.count);
    });
  };
  /**
   * @filter
   * @search
   */
  const [paginationConfig, setPaginationConfig] = useState({
    ...initialPaginationConfigs,
    limit: 20
  });
  // debounce the api call, so It will be fired only when the user stopped typing
  useEffect(() => {
    dispatch(setLoading(true));
  }, [dispatch]);
  useDebouncedEffect(() => handleGetAllMembersAPICall(), [paginationConfig, memberUpdated], 500);

  /**
   * @delete
   * @user
   */
  const [currentDeleteMemberId, setCurrentDeleteMemberId] = useState("");
  const handleUserDeleteOperation = () => {
    dispatch(setLoading(true));
    deleteMemberById(currentDeleteMemberId).then((response: any) => {
      dispatch(setConfirmationModalVisibility(false));
      dispatch(setLoading(false));
      handleGetAllMembersAPICall();
    });
  };

  /**
   * @update
   * @app_permissions
   */
  const [userIdForAppsPermissions, setUserIdForAppsPermissions] = useState("");
  const [isUpdatePermissionsVisible, setIsUpdatePermissionsVisible] = useState(false);
  const toggleUpdatePermissions = useCallback(() => setIsUpdatePermissionsVisible(!isUpdatePermissionsVisible), [isUpdatePermissionsVisible]);

  /**
   * @infinite_scroll
   */
  // infinite scroll logic
  useEffect(() => {
    // infinite scroll
    function fetchNextData() {
      // @ts-ignore
      if (tableScroll?.scrollHeight - tableScroll?.scrollTop === tableScroll?.clientHeight) {
        // check if we have fetched the whole data from api if yes then do not call the api again
        if (allMembersCount !== allMembers?.length) {
          setPaginationConfig({
            ...paginationConfig,
            limit: paginationConfig.limit + 10
          });
        }
      }
    }
    // @ts-ignore
    let tableScroll = document.getElementsByClassName("table-responsive")?.[0];
    tableScroll?.addEventListener("scroll", fetchNextData);

    // remove scroll event listener when all data is fetched
    if (allMembersCount === allMembers?.length) {
      tableScroll?.removeEventListener("scroll", fetchNextData);
    }
    return () => {
      // remove event listener on unmount
      tableScroll?.removeEventListener("scroll", fetchNextData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationConfig, allMembersCount]);

  return (
    <>
      <div className="user-management-wrapper">
        <Container>
          <div className="list-title">
            <Row className="align-items-center justify-content-center">
              <Col md="6" sm="12" xs="12">
                <div className="title">
                  <h2 className="c-tx-primary mb-md-0 h2 f-700">User Management</h2>
                </div>
              </Col>
              <Col md="6" sm="12" xs="12">
                <div className="d-flex justify-content-end align-items-center">
                  <p onClick={() => navigate("/kyc-list")} className="custom-link cursor-pointer mb-0 mr-20">
                    {" "}
                    kYC List{" "}
                  </p>
                  <p onClick={toggleAddUpdateModal} className="custom-link cursor-pointer mb-0">
                    {" "}
                    Add User{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
          <div className="table-wrapper">
            <div className="table-search-wrap px-3">
              <Row className="align-items-center">
                <Col md="6">
                  <Input
                    placeholder="Search "
                    className="ps-0"
                    value={paginationConfig.search}
                    onChange={(event) => {
                      if (reduceWhiteSpace(event.target.value)) {
                        setPaginationConfig({
                          ...paginationConfig,
                          search: reduceWhiteSpace(event.target.value)
                        });
                      } else {
                        if (paginationConfig.search) {
                          setPaginationConfig({
                            ...paginationConfig,
                            search: ""
                          });
                        }
                      }
                    }}
                  />
                </Col>
                <Col md="6" className="align-items-center py-2 py-md-0 text-md-end">
                  <Link
                    to="/"
                    className="custom-link"
                    onClick={(event) => {
                      event.preventDefault();
                      setPaginationConfig(initialPaginationConfigs);
                    }}>
                    {" "}
                    Clear{" "}
                  </Link>
                </Col>
              </Row>
            </div>
            <Table responsive={true} className="mb-0">
              <thead style={{ position: "sticky", top: 0 }}>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {allMembers?.map((member: any, index) => (
                  <tr key={index}>
                    <td className="text-capitalize">{member.first_name}</td>
                    <td className="text-capitalize">{member.last_name}</td>
                    <td>{member.phone}</td>
                    <td>{member.email}</td>
                    <td className="text-capitalize">
                      {member?.nullPasssword ? <span title="User hasn't yet accepted your invitation">Not Yet Activated</span> : member.status === 1 ? "Active" : "InActive"}
                    </td>
                    <td className="text-center">
                      <Dropdown isOpen={userActionDropdown?.[index]} toggle={() => toggleDropdown(index)}>
                        <DropdownToggle tag="a" style={{ cursor: "pointer" }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="4" viewBox="0 0 17 4">
                            <path
                              id="Path_330"
                              data-name="Path 330"
                              d="M8.624,6.781a1.953,1.953,0,0,0-1.906-2,1.953,1.953,0,0,0-1.906,2,1.953,1.953,0,0,0,1.906,2A1.953,1.953,0,0,0,8.624,6.781Zm4.689-2a1.953,1.953,0,0,0-1.906,2,1.908,1.908,0,1,0,3.811,0A1.953,1.953,0,0,0,13.313,4.781Zm-13.189,0a1.953,1.953,0,0,0-1.906,2,1.953,1.953,0,0,0,1.906,2,1.953,1.953,0,0,0,1.906-2A1.953,1.953,0,0,0,.124,4.781Z"
                              transform="translate(1.781 -4.781)"
                              fill="#1e2835"
                            />
                          </svg>
                        </DropdownToggle>
                        <DropdownMenu className="table-drp">
                          <DropdownItem
                            onClick={() => {
                              setIsUpdate(true);
                              setUpdateUserId(member.userId);
                              toggleAddUpdateModal();
                            }}>
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setCurrentDeleteMemberId(member.userId);
                              dispatch(setConfirmationModalVisibility(true));
                            }}>
                            Delete
                          </DropdownItem>
                          {member.status && !member?.nullPasssword ? (
                            <DropdownItem
                              onClick={() => {
                                setUserIdForAppsPermissions(member.userId);
                                toggleUpdatePermissions();
                              }}>
                              App Permission
                            </DropdownItem>
                          ) : (
                            ""
                          )}
                        </DropdownMenu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
                {allMembers?.length > 0 || isLoading ? (
                  ""
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No Data Found
                    </td>
                  </tr>
                )}
                {isLoading && (
                  <tr>
                    <td colSpan={6}>
                      <Spinner size={"sm"} style={{ marginLeft: "50%" }} />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Container>
      </div>
      <div>
        {/* add new user modal */}
        {addUpdateModal && (
          <Modal
            isOpen={addUpdateModal}
            className="custom-stepper-popup "
            toggle={toggleAddUpdateModal}
            size="xl"
            centered
            keyboard={false}
            backdrop="static"
            external={
              <Link
                to="/"
                className="text-white close modal-close close small text-uppercase fw-bold"
                onClick={(event) => {
                  event.preventDefault();
                  toggleAddUpdateModal();
                  setIsUpdate(false);
                }}>
                CLOSE
              </Link>
            }>
            <AddNewUser toggleMemberUpdated={toggleMemberUpdated} isUpdate={isUpdate} updateUserId={updateUserId} setIsUpdate={setIsUpdate} toggleAddUpdateModal={toggleAddUpdateModal} />
          </Modal>
        )}
        {/* update permissions modal */}
        {isUpdatePermissionsVisible && (
          <Modal
            isOpen={isUpdatePermissionsVisible}
            className="custom-stepper-popup "
            toggle={toggleUpdatePermissions}
            size="xl"
            centered
            keyboard={false}
            backdrop="static"
            external={
              <Link
                to="/"
                className="text-white close modal-close close small text-uppercase font-30"
                onClick={(event) => {
                  event.preventDefault();
                  toggleUpdatePermissions();
                }}>
                {closeIconRegular}
              </Link>
            }>
            <UpdateMemberPermissions userIdForAppsPermissions={userIdForAppsPermissions} toggleUpdatePermissions={toggleUpdatePermissions} />
          </Modal>
        )}
      </div>
      <ConfirmationModal handleOnClickAction={handleUserDeleteOperation} title="Are you sure you want to Delete ?" />
    </>
  );
};

export default UserManagement;

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getPeerNodeAPI = (id: string): Promise<IgetPeerListAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.myBlockChain.peer.view + id);
};

export interface IgetPeerNodeAPIResData {
  createdAt: string;
  name: string;
  region: string;
  size: string;
}

interface IgetPeerListAPIRes {
  message: string;
  status: number;
  data: IgetPeerNodeAPIResData;
}

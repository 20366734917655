import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getSmartContractListAPI = (params: getSmartContractListAPIParams): Promise<getSmartContractListAPIParamsRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.smartContract.list, params);
};
export interface getSmartContractListAPIData {
  file?: string;
  status: number;
  createdAt?: string;
  updatedAt?: string;
  name: string;
  protocol: string;
  language: string;
  id: string;
  description: string;
  cntrctUser: {
    name: string;
    email: string;
    userId: string;
  };
  deletedAt?: string;
}
interface getSmartContractListAPIParams {
  limit: number;
  page: number;
}

interface getSmartContractListAPIParamsRes {
  message: string;
  status: number;
  data: {
    count: number;
    data: getSmartContractListAPIData[];
  };
}

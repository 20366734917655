import {
  DEFAULTGRAPHVISIBILITY,
  NOACCESS,
  PEERSEARCH,
  PEERSEARCHFORDROPDOWN,
  SETLOADDIFFOFCPUUSAGE,
  SETLOADDIFFOFMEMORYUSAGE,
  SETLOADDIFFOFPEER,
  SETLOADDIFFOFTRANSACTION,
  SETONCLICKBTNOFGRAPH,
  SETPEERDATA,
  SETPEERID,
  SETPEERIDOFCPUUSAGE,
  SETPEERIDOFMEMORYUSAGE,
  SETPEERIDOFTRANSACTIONAPI
} from "./type";

export const setPeerData = (payload: number) => {
  return { type: SETPEERDATA, payload };
};

export const setPeerId = (payload: string) => {
  return { type: SETPEERID, payload };
};

export const setPeerIdOfTransactionApi = (payload: string) => {
  return { type: SETPEERIDOFTRANSACTIONAPI, payload };
};

export const setPeerIdOfCPUUsageApi = (payload: string) => {
  return { type: SETPEERIDOFCPUUSAGE, payload };
};

export const setPeerIdOfMemoryUsageApi = (payload: string) => {
  return { type: SETPEERIDOFMEMORYUSAGE, payload };
};

export const setLoadDiffOfMemoryUsage = (payload: boolean) => {
  return { type: SETLOADDIFFOFMEMORYUSAGE, payload };
};

export const setLoadDiffOfCPUUsage = (payload: boolean) => {
  return { type: SETLOADDIFFOFCPUUSAGE, payload };
};

export const setLoadDiffTransaction = (payload: boolean) => {
  return { type: SETLOADDIFFOFTRANSACTION, payload };
};

export const setLoadDiffPeer = (payload: boolean) => {
  return { type: SETLOADDIFFOFPEER, payload };
};

export const setOnCLickBtnInfo = (payload: string) => {
  return { type: SETONCLICKBTNOFGRAPH, payload };
};

export const defaultGraphVisibilityFlag = (payload: boolean) => {
  return { type: DEFAULTGRAPHVISIBILITY, payload };
};

export const setNoAccess = (payload: boolean) => {
  return { type: NOACCESS, payload };
};

export const setSearchOfPeer = (payload: string) => {
  return { type: PEERSEARCH, payload };
};

// After search Peer list appears

export const setSearchOfPeersListForDropdown = (payload: any) => {
  return { type: PEERSEARCHFORDROPDOWN, payload };
};

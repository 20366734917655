import { InetworkInfoAPIResData } from "actions/networkInfoAPI";
import { networkType, peerType } from "./type";

export const setNetwork = (payload: InetworkInfoAPIResData) => {
  return { type: networkType.setNetworkData, payload };
};

export const setPeerDetails = (payload: any) => {
  return { type: peerType.peerData, payload };
};

export default function LeftArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.314" height="10.485" viewBox="0 0 20.314 10.485">
      <path
        id="Path_51"
        data-name="Path 51"
        d="M5.808,16.95a1,1,0,0,0,1.414-1.414L4.686,13H21a1,1,0,0,0,0-2H4.686L7.222,8.464A1,1,0,1,0,5.808,7.05L2.272,10.586a2,2,0,0,0,0,2.828Z"
        transform="translate(-1.686 -6.757)"
      />
    </svg>
  );
}

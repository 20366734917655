export const SETPEERDATA = "SETPEERDATA";
export const SETPEERID = "SETPEERID";
export const SETPEERIDOFTRANSACTIONAPI = "SETPEERIDOFTRANSACTIONAPI";
export const SETPEERIDOFCPUUSAGE = "SETPEERIDOFCPUUSAGE";
export const SETPEERIDOFMEMORYUSAGE = "SETPEERIDOFMEMORYUSAGE";
export const SETLOADDIFFOFMEMORYUSAGE = "SETLOADDIFFOFMEMORYUSAGE";
export const SETLOADDIFFOFTRANSACTION = "SETLOADDIFFOFTRANSACTION";
export const SETLOADDIFFOFCPUUSAGE = "SETLOADDIFFOFCPUUSAGE";
export const SETLOADDIFFOFPEER = "SETLOADDIFFOFPEER";
export const SETONCLICKBTNOFGRAPH = "SETONCLICKBTNOFGRAPH";
export const DEFAULTGRAPHVISIBILITY = "DEFAULTGRAPHVISIBILITY";
export const NOACCESS = "NOACCESS";
export const PEERSEARCH = "PEERSEARCH";
export const PEERSEARCHFORDROPDOWN = "PEERSEARCHFORDROPDOWN";

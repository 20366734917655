import { CHANNELDATA } from "./type";

const initialState = {
  storeChannelData: []
};

const channelDataReducer = (state = initialState, action: { type: string; payload: any; id: string }) => {
  const newState = { ...state };

  switch (action.type) {
    case CHANNELDATA:
      newState.storeChannelData = action.payload;

      break;
  }
  return newState;
};

export default channelDataReducer;

import { apiEndPoints } from 'utils/api-endpoints'
import HTTPService from 'utils/httpService'

export const addNewUniverseRequest = (
  params: IUniverseRequest
): Promise<IUniverseRequestResult> => {
  return HTTPService.post(apiEndPoints.universeRequest, params)
}

export interface IUniverseRequest {
  envName: string
  type: string
  networkName: string
  networkDescription: string
  region: string
  provider: string
  algorithmType: string
}

export interface IUniverseRequestResult {
  message: string
  status: number
}

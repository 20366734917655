import { memo } from "react";
import { Spinner } from "reactstrap";

const LoadingSpinner = ({ size = "sm" }: { size: "sm" | "lg" | "xl" }) => {
  return (
    <div className="text-center mt-3 mb-3">
      <Spinner
        size={size}
        style={{
          marginTop: "20%",
          marginLeft: "5%"
        }}
      />
    </div>
  );
};

export default memo(LoadingSpinner);

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getOrderListAPI = (params: getOrderListAPIParams): Promise<getOrderListAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.myBlockChain.order.list, params);
};

interface getOrderListAPIParams {
  limit: number;
  page: number;
}

export interface getOrderListAPIResData {
  id: string;
  name: string;
  orderRegionId: {
    name: string;
  };
}

interface getOrderListAPIRes {
  message: string;
  status: number;
  data: {
    count: number;
    data: getOrderListAPIResData[];
  };
}

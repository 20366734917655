import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getNetworkRegions = (networkDomainId: string): Promise<INetworkRegionsResponse> => {
  return HTTPService.get(apiEndPoints.networkRegions + `?networkDomainId=${networkDomainId}`);
};

export interface INetworkRegionsResponse {
  status: number;
  message: string;
  data: INetworkRegionData;
}

export interface INetworkRegionData {
  count: number;
  data: INetworkRegion[];
}

export interface INetworkRegion {
  id: string;
  file: string;
  name: string;
  domain: string;
  default: boolean;
  disable: boolean;
  region: string;
}

import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  getOrderListAPI,
  getOrderListAPIResData
} from 'actions/getOrderListAPI'
import LoadingSpinner from 'components/loader/LoadingSpinner'
import { NoFoundDiv } from 'components/notFoundPages'
import NoMoreData from 'components/notFoundPages/NoMoreData'
import { memo, useCallback, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ViewOrderModal from './ViewOrderModal'
const AngleRight = <FontAwesomeIcon icon={faAngleRight} />
const OrderManagementListComponent = () => {
  const [orderList, setOrderList] = useState<
    getOrderListAPIResData[]
  >([])
  const [pagination, setPagination] = useState({
    limit: 5,
    page: 1,
    totalCount: 0
  })
  const [historyLoading, setHistoryLoading] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [id, setId] = useState('')

  const getOrderListAction = useCallback(() => {
    setHistoryLoading(true)
    getOrderListAPI({
      limit: pagination.limit,
      page: pagination.page
    })
      .then((res: any) => {
        if (res?.status === 200) {
          setOrderList([...orderList, ...res?.data?.data])
          setPagination({
            ...pagination,
            totalCount: res?.data?.count
          })
          setHistoryLoading(false)
        }
      })
      .catch((err) => setHistoryLoading(false))
    // eslint-disable-next-line
  }, [pagination])

  useEffect(() => {
    getOrderListAction()
    // eslint-disable-next-line
  }, [pagination.page])

  // load next page data from api
  const incrementPage = useCallback(() => {
    setPagination({
      ...pagination,
      page: pagination.page + 1
    })
  }, [pagination])

  const handleClick = useCallback((id) => {
    setId(id)
    setViewModal(true)
  }, [])

  return (
    <div className="backdrop-block">
      <div className="title">
        <h5 className="c-tx-primary f-600 mb-10">
          Orderer Management{' '}
          {pagination.totalCount > 0 && (
            <span className="c-tx-ninth f-400">
              ({pagination.totalCount})
            </span>
          )}
        </h5>
      </div>
      <ul
        className="wrapper-block peer-management order-management"
        id="Orderer-Management"
      >
        <InfiniteScroll
          dataLength={orderList?.length}
          next={incrementPage}
          hasMore={pagination.totalCount > orderList?.length}
          loader={<LoadingSpinner size="sm" />}
          scrollableTarget="Orderer-Management"
          endMessage={orderList.length > 0 && <NoMoreData />}
        >
          {orderList.length > 0 ? (
            orderList.map(
              (data: getOrderListAPIResData, i: number) => (
                <li className="element" key={i}>
                  <div className="block-1">
                    <p className="c-tx-primary f-700 first-title">
                      {data?.name}
                    </p>
                    <p className="c-tx-ninth">
                      {data?.orderRegionId?.name}
                    </p>
                  </div>
                  <div className="block-2 align-items-center">
                    <div
                      className="angle-block cursor-pointer"
                      onClick={() => handleClick(data?.id)}
                    >
                      {AngleRight}
                    </div>
                  </div>
                </li>
              )
            )
          ) : (
            <NoFoundDiv />
          )}
        </InfiniteScroll>
        {historyLoading ? <LoadingSpinner size="sm" /> : null}
      </ul>
      <ViewOrderModal
        modal={viewModal}
        setModal={setViewModal}
        data={id}
      />
    </div>
  )
}

export default memo(OrderManagementListComponent)

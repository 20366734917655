import { addParticipantsAPI } from "actions/addParticipantsAPI";
import { SendOTPAPI } from "actions/sendOtpAPI";
import { UserSearchAPI } from "actions/userSearchAPI";
import { useFormik } from "formik";
import { useDebouncedEffect } from "hooks/UseDebouncedEffect";
import { FC, useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { Button, FormGroup, Input } from "reactstrap";
import { handleTrim, toastSuccess } from "utils/commonFunctions";
import * as Yup from "yup";
import InvitationModalComponent from "../receivedInvitations/InvitationModalComponent";
interface IUserInterface {
  value: string;
  label: string;
}

interface IAddParticipatComponent {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  modal: boolean;
  viewChannelAction: () => void;
  id: string;
}

const AddParticipatComponent: FC<IAddParticipatComponent> = ({ setModal, modal, viewChannelAction, id }) => {
  const [invitationmodal, setInvitationmodal] = useState(false);
  const [search, setSearch] = useState("");
  const [profile, setProfile] = useState<any>([]);
  const [options, setOptions] = useState<IUserInterface[]>([]);
  const [apiData, setApiData] = useState<any>();
  const formikState = useFormik({
    initialValues: {
      userId: [],
      message: ""
    },
    validationSchema: Yup.object({
      userId: Yup.array().min(1).required()
    }),
    onSubmit: (values) => {
      addParticipantsAction();
      // handleResendAction();
      // setInvitationmodal(!invitationmodal);
    }
  });
  const { values, handleSubmit, setFieldValue } = formikState;

  const handleResendAction = () => {
    SendOTPAPI(apiData, {
      requestType: "Participate",
      module: "Channel"
    }).then((res) => {
      if (res.status === 200) {
      }
    });
  };

  const handleUserId = useCallback(
    (data) => {
      const userIds = data.map((item: any) => item.value);
      setFieldValue("userId", userIds);
    },
    [setFieldValue]
  );

  const handleSerach = (e: string) => {
    if (modal) {
      let params: any = {
        limit: 20,
        page: 1,
        module: "Channel",
        moduleId: id
      };
      if (e) params.search = e;
      UserSearchAPI(params).then((res) => {
        if (res.status === 200) {
          const newoption = res?.data?.data?.map((data) => {
            return {
              label: data.name || data.email,
              value: data.userId
            };
          });
          setOptions(newoption);
        }
      });
    }
  };

  const addParticipantsAction = () => {
    let data: any = {};
    if (values?.message) {
      data = { ...values, message: values.message.trim() };
    } else {
      data.userId = values.userId;
    }
    addParticipantsAPI({ channelId: id, data }).then((res: any) => {
      if (res.status === 200) {
        setApiData({ channelId: id, ...data });
        toastSuccess(res.message);
        setSearch("");
        setProfile([]);
        formikState.resetForm();
        // viewChannelAction();
        setInvitationmodal(!invitationmodal);
        // setModal(!modal);
      }
    });
  };

  useDebouncedEffect(() => handleSerach(search), [search], 500);

  useEffect(() => {
    if (modal) handleSerach(search);
    // eslint-disable-next-line
  }, [modal]);

  return (
    <>
      <div className="right-block d-flex flex-column justify-content-between">
        <div>
          <div className="title">
            <h5 className="c-tx-primary f-700 mb-20">Add Participants</h5>
          </div>
          <FormGroup className="position-relative">
            <Select
              options={options}
              isMulti
              onInputChange={(e: string) => {
                setSearch(e);
              }}
              value={profile}
              onChange={(value) => {
                setProfile(value);
                handleUserId(value);
              }}
            />
            <p className="c-tx-ninth mb-20 small mt-2">You can select multiple participants</p>
          </FormGroup>
          <FormGroup className="">
            <Input type="textarea" name="message" placeholder="Message (Optional)" onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)} value={values.message} />
          </FormGroup>
        </div>
        <div>
          <Button
            type="button"
            disabled={profile?.length === 0}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            className="custom-primary w-100">
            Add Participants
          </Button>
        </div>
      </div>
      <InvitationModalComponent
        handleAction={viewChannelAction}
        handleResend={handleResendAction}
        invitationmodal={invitationmodal}
        setInvitationmodal={setInvitationmodal}
        requestName="Channel-update"
      />
    </>
  );
};

export default AddParticipatComponent;

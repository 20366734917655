import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
const Check = <FontAwesomeIcon icon={faCheck} />;

const APIAndApplicationFeaturesModel = ({ featureModelVisibility, toggleFeatureModel, name, description, features }: any) => {
  return (
    <Modal isOpen={featureModelVisibility} toggle={toggleFeatureModel} centered backdrop="static" style={{ maxWidth: 600 }}>
      <ModalHeader toggle={toggleFeatureModel}>{name} features</ModalHeader>
      <ModalBody>
        {description && (
          <div className="content mt-0">
            <p className="mb-0">{description}</p>
          </div>
        )}
        <div className="points-wrapper" style={{ height: "auto" }}>
          <p className="c-tx-third f-600 mb-2">Features</p>
          <ul className="point-list">
            {Array.isArray(features) &&
              features?.map((feature: string) => {
                return (
                  <li key={feature}>
                    <div className="icon">
                      <p className="mb-0">{Check}</p>
                    </div>
                    <p className="mb-0">{feature}</p>
                  </li>
                );
              })}
          </ul>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default APIAndApplicationFeaturesModel;

import { TemplateType } from "./type";

const initialState: ITemplateReducer = {
  modal: {
    IInstallationTemplateModal: false,
    ReviewConfimationTemplateModal: false,
    ReviewTemplateModal: false,
    SuccessTemplateModal: false
  },
  ReviewConfimationTemplateModal: false,
  data: {},
  id: "",
  templateGetData: {},
  submit: false
};

const TemplateReducer = (state = initialState, action: { type: string; payload: any }) => {
  const newState = { ...state };

  switch (action.type) {
    case TemplateType.setTemplateModal:
      let data: any = {};
      Object.keys(newState.modal).forEach((key: string) => {
        if (key === action.payload) {
          // @ts-ignore
          data[key] = true;
        } else {
          // @ts-ignore
          data[key] = false;
        }
      });
      newState.modal = data;
      return { ...state, modal: data };

    case TemplateType.setTemplateData:
      newState.data = {};
      newState.data = action.payload;
      break;

    case TemplateType.closeTemplateModal:
      // @ts-ignore
      newState.modal[action.payload] = false;
      return {
        ...state,
        modal: { ...state.modal, [action.payload]: false }
      };

    case TemplateType.setTemplateId:
      newState.id = action.payload;
      break;

    case TemplateType.clearTemplateData:
      newState.modal = {
        IInstallationTemplateModal: false,
        ReviewConfimationTemplateModal: false,
        ReviewTemplateModal: false,
        SuccessTemplateModal: false
      };
      newState.data = {};
      newState.id = "";
      break;
    case TemplateType.setTemplateGetData:
      newState.templateGetData = action.payload;
      break;
    case TemplateType.setSubmit:
      newState.submit = action.payload;
      break;
  }

  return newState;
};

export default TemplateReducer;

export interface ITemplateReducer {
  modal: {
    IInstallationTemplateModal: boolean;
    ReviewConfimationTemplateModal: boolean;
    ReviewTemplateModal: boolean;
    SuccessTemplateModal: boolean;
  };
  ReviewConfimationTemplateModal: boolean;
  data: any;
  id: string;
  templateGetData: any;
  submit: boolean;
}

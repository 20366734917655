import { FC } from "react";

interface IStartIconComponent {
  icon: JSX.Element;
  className?: string;
}
const StartIconComponent: FC<IStartIconComponent> = ({ icon, className }) => {
  return (
    <li>
      <p className={`small c-tx-third mb-0 ${className || null}`}>{icon}</p>
    </li>
  );
};

export default StartIconComponent;

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getNetworkDomains = (): Promise<INetworkDomainsResponse> => {
  return HTTPService.get(apiEndPoints.networkDomains);
};

// Generated by https://quicktype.io

export interface INetworkDomainsResponse {
  status: number;
  message: string;
  data: InetworkDomainData;
}

export interface InetworkDomainData {
  count: number;
  data: INetworkDomain[];
}

export interface INetworkDomain {
  id: string;
  name: string;
  visible: boolean;
  disable: boolean;
  default: boolean;
  order: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: null;
}

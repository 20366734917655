import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getCertificateListAPI = (params: getCertificateListAPIParams): Promise<getCertificateListAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.myBlockChain.certificate.list, params);
};

interface getCertificateListAPIParams {
  limit: number;
  page: number;
}

export interface getCertificateListAPIResData {
  id: string;
  status: number;
  createdAt: string;
  membership: string;
}

interface getCertificateListAPIRes {
  message: string;
  status: number;
  data: {
    count: number;
    data: getCertificateListAPIResData[];
  };
}

import { TemplateType } from "./type";

export const setTemplateModal = (payload: string) => {
  return { type: TemplateType.setTemplateModal, payload };
};

export const setTemplateData = (payload: any) => {
  return { type: TemplateType.setTemplateData, payload };
};

export const closeTemplateModal = (payload: string) => {
  return { type: TemplateType.closeTemplateModal, payload };
};

export const setTemplateIdModal = (payload: string) => {
  return { type: TemplateType.setTemplateId, payload };
};

export const clearTemplateDataModal = () => {
  return { type: TemplateType.clearTemplateData };
};

export const setTemplateGetData = (payload: any) => {
  return { type: TemplateType.setTemplateGetData, payload };
};

export const setTemplateSubmit = (payload: boolean) => {
  return { type: TemplateType.setSubmit, payload };
};

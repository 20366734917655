import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const UserSearchAPI = (paramans: IParams): Promise<IUserSearchAPI> => {
  return HTTPService.get(apiEndPoints.universeAPI.channelManagement.findUser, paramans);
};

export interface IParams {
  limit?: number;
  page?: number;
  module?: string;
  pagination?: boolean;
  search?: string;
  approved?: boolean;
  moduleId?: string;
}

export interface IUserSearchAPIData {
  name: string;
  email: string;
  username: string;
  userId: string;
}

interface comm {
  status: number;
  message: string;
}
export interface IUserSearchAPI extends comm {
  data: {
    count: number;
    data: IUserSearchAPIData[];
  };
}

import { getPeerNodeAPI } from "actions/getPeerNodeAPI";
import LoadingSpinner from "components/loader/LoadingSpinner";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import { getFormattedDate } from "utils/commonFunctions";

interface IViewPeerNodeModal {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  modal: boolean;
  data?: string;
}

const ViewPeerNodeModal: FC<IViewPeerNodeModal> = ({ setModal, modal, data }) => {
  const [item, setItem] = useState<any>();
  const [loader, setLoader] = useState(false);
  const getPeerNodeAction = useCallback(() => {
    if (data) {
      setLoader(true);
      getPeerNodeAPI(data).then((res) => {
        if (res.status === 200) {
          setItem(res.data);
          setLoader(false);
        }
      });
    }
  }, [data]);

  useEffect(() => {
    if (modal && data) {
      getPeerNodeAction();
    }
    // eslint-disable-next-line
  }, [modal, data]);
  return (
    <Modal
      isOpen={modal}
      className="custom-popup-channel"
      toggle={() => setModal(false)}
      size="sm"
      style={{ maxWidth: "390px" }}
      centered
      external={
        <Link to="#" className=" text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30 fw-bold" onClick={() => setModal(false)}>
          CLOSE
        </Link>
      }>
      <div className="erc-template-wrapper modal-pad-30">
        <div>
          <h5 className="mb-20 c-tx-primary f-600">Peer Details</h5>
        </div>
        {loader ? (
          <LoadingSpinner size="sm" />
        ) : (
          <>
            <div>
              <ul className="list-inforation">
                <li>
                  <div className="label_bl">
                    <p className="c-tx-primary f-700">Peer Name</p>
                  </div>
                  <div className="cln">:</div>
                  <div className="ct_2">
                    <p className="c-tx-primary">{item?.name}</p>
                  </div>
                </li>
                <li>
                  <div className="label_bl">
                    <p className="c-tx-primary f-700">Peer Region</p>
                  </div>
                  <div className="cln">:</div>
                  <div className="ct_2">
                    <p className="c-tx-primary">{item?.region}</p>
                  </div>
                </li>
                <li>
                  <div className="label_bl">
                    <p className="c-tx-primary f-700">Server size</p>
                  </div>
                  <div className="cln">:</div>
                  <div className="ct_2">
                    <p className="c-tx-primary text-capitalize">{item?.size}</p>
                  </div>
                </li>
                <li className="mb-0">
                  <div className="label_bl">
                    <p className="c-tx-primary f-700">Create Date </p>
                  </div>
                  <div className="cln">:</div>
                  <div className="ct_2">
                    <p className="c-tx-primary">{item?.createdAt && getFormattedDate(item?.createdAt)}</p>
                  </div>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default memo(ViewPeerNodeModal);

import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Notification = <FontAwesomeIcon icon={faBell} />;

const SolidBell = () => {
  return Notification;
};

export default SolidBell;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { makeFullS3ObjectUrl } from "utils/makeFullS3ObjectUrl";
const loading = makeFullS3ObjectUrl("loading.svg");

const ComingSoon = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="h-100 section-margin-y mx-auto d-flex align-items-center justify-content-center flex-column coming-soon-wrapper container">
        <Row className="align-items-center">
          <Col sm="12" className="text-center">
            <div className="image-wrapper" style={{ marginBottom: "40px" }}>
              <img src={loading} className="img-fluid" alt="Under Construction" />
            </div>
            <div className="helper-message">
              <h3 className="mb-3" style={{ color: "#1E2835", fontSize: "40px" }}>
                Our team is working on the edeXa Universe
              </h3>
              <p className="mb-0" style={{ color: "#1E2835", fontSize: "24px" }}>
                More updates will be coming soon
              </p>
            </div>
          </Col>
        </Row>
      </div>
      {/* <FooterUnderComing /> */}
    </>
  );
};

export default ComingSoon;

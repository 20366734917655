import { faCopy } from '@fortawesome/free-regular-svg-icons'
import {
  faDownload,
  faMagic
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC, memo } from 'react'
import { CopyBlock, dracula } from 'react-code-blocks'
import { Button } from 'reactstrap'
import { remixURL, toastSuccess } from 'utils/commonFunctions'
import UniverseLogo from './../../../../assets/Images/logoUni.png'
interface IReply {
  message: string
  id: string
  forwordRef?: any
}

const Reply: FC<IReply> = ({ message, id, forwordRef }) => {
  const handleHtml = (str: string) => {
    let d = ''
    let no = 2
    String(str)
      .split('')
      .map((res) => {
        if (res === '`') {
          if (no % 2 === 0) {
            d = d + '<b>` '
            no = no + 1
          } else {
            d = d + ' `</b>'
            no = no + 1
          }
        } else {
          d = d + res
        }
      })
    return d
  }

  const handleCopy = (className: string) => {
    const btn = document
      .getElementsByClassName(className)[0]
      .getElementsByClassName('sc-dcrmVg')[0]
    // @ts-ignore
    btn.click()
    toastSuccess('Copied')
  }
  const downloadFile = (res: any) => {
    const link = document.createElement('a')
    const content = res
    const file = new Blob([content], { type: 'text/plain' })
    link.href = URL.createObjectURL(file)
    link.download = 'sample.txt'
    link.click()
    URL.revokeObjectURL(link.href)
  }

  const handleRemix = (code: string) => {
    handleSolidity(code)
    window.open(remixURL(code, false).toString(), '_blank')
  }

  const handleSolidity = (code: string) => {
    return code.includes('pragma solidity ')
  }

  return (
    <div
      className="chat-list-reply-api"
      ref={forwordRef}
      id={`${id}`}
    >
      <div className="p-1">
        <img width={50} src={UniverseLogo} alt="ml" />
      </div>
      <div className="w-100">
        {' '}
        {message.split('```').map((res, index) => {
          if (index % 2 === 0) {
            return (
              <pre
                key={index}
                style={{ whiteSpace: 'pre-wrap' }}
                className="font-16"
                dangerouslySetInnerHTML={{
                  __html:
                    index === 0 ? handleHtml(res) : handleHtml(res)
                }}
              ></pre>
            )
          } else {
            return (
              <div key={index} className={`my-1 chat-list-${index}`}>
                <div className="d-flex justify-content-end mb-10">
                  <Button
                    className="chat-btn"
                    onClick={() => handleCopy(`chat-list-${index}`)}
                  >
                    <FontAwesomeIcon
                      className="mr-10"
                      icon={faCopy}
                    />
                    Copy
                  </Button>
                  {handleSolidity(String(res)) ? (
                    <Button
                      className="chat-btn ml-10"
                      onClick={() => handleRemix(res)}
                    >
                      <FontAwesomeIcon
                        icon={faMagic}
                        className="mr-10"
                      />
                      Deploy on Remix
                    </Button>
                  ) : null}
                  <Button
                    className="chat-btn ml-10"
                    onClick={() => downloadFile(res)}
                  >
                    <FontAwesomeIcon
                      icon={faDownload}
                      className="mr-10"
                    />
                    Download
                  </Button>
                </div>
                <CopyBlock
                  language="jsx"
                  text={res}
                  codeBlock
                  /* @ts-ignore */
                  theme={dracula}
                  showLineNumbers={true}
                  wrapLines
                />
              </div>
            )
          }
        })}
      </div>
    </div>
  )
}

export default memo(Reply)

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const updateMemberDataById = (userId: string, params: IAddNewMember): Promise<IAddNewMemberResult> => {
  return HTTPService.put(apiEndPoints.members + "/" + userId, params);
};

export interface IAddNewMember {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  place: string;
  zipcode: string;
  address: string;
  status: number;
  gender: string;
  userId: string;
}

export interface IAddNewMemberResult {
  message: string;
  status: number;
}

import {
  DEFAULTGRAPHVISIBILITY,
  NOACCESS,
  PEERSEARCH,
  PEERSEARCHFORDROPDOWN,
  SETLOADDIFFOFCPUUSAGE,
  SETLOADDIFFOFMEMORYUSAGE,
  SETLOADDIFFOFPEER,
  SETLOADDIFFOFTRANSACTION,
  SETONCLICKBTNOFGRAPH,
  SETPEERDATA,
  SETPEERID,
  SETPEERIDOFCPUUSAGE,
  SETPEERIDOFMEMORYUSAGE,
  SETPEERIDOFTRANSACTIONAPI
} from "./type";

const initialState: IPeerReducer = {
  data: [],
  peerId: null,
  peerIdOfTransactionApi: null,
  peerIdOfCPUUsageApi: null,
  peerIdOfMemoryUsageApi: null,
  loadDiffOfMemoryUsage: false,
  loadDiffOfCPUUsage: false,
  loadDiffOfTransaction: false,
  loadDiffOfPeer: false,
  onCLickBtnGraphName: "random",
  defaultGraphVisibility: false,
  noAccess: false,
  searchedPeer: "",
  searchedPeerListForDropdown: []
};

const peerReducer = (state = initialState, action: { type: string; payload: any }) => {
  const newState = { ...state };
  switch (action.type) {
    case SETPEERDATA:
      newState.data = action.payload;
      break;

    case SETPEERID:
      newState.peerId = action.payload;
      break;
    case SETPEERIDOFTRANSACTIONAPI:
      newState.peerIdOfTransactionApi = action.payload;
      break;
    case SETPEERIDOFCPUUSAGE:
      newState.peerIdOfCPUUsageApi = action.payload;
      break;
    case SETPEERIDOFMEMORYUSAGE:
      newState.peerIdOfMemoryUsageApi = action.payload;
      break;
    // --------------------------------------------------------
    case SETLOADDIFFOFMEMORYUSAGE:
      newState.loadDiffOfMemoryUsage = action.payload;
      break;

    case SETLOADDIFFOFCPUUSAGE:
      newState.loadDiffOfCPUUsage = action.payload;
      break;

    case SETLOADDIFFOFTRANSACTION:
      newState.loadDiffOfTransaction = action.payload;
      break;

    case SETLOADDIFFOFPEER:
      newState.loadDiffOfPeer = action.payload;
      break;

    case SETONCLICKBTNOFGRAPH:
      newState.onCLickBtnGraphName = action.payload;
      break;

    case DEFAULTGRAPHVISIBILITY:
      newState.defaultGraphVisibility = action.payload;
      break;

    case NOACCESS:
      newState.noAccess = action.payload;
      break;

    case PEERSEARCH:
      newState.searchedPeer = action.payload;
      break;

    case PEERSEARCHFORDROPDOWN:
      newState.searchedPeerListForDropdown = action.payload;
      break;
  }
  return newState;
};

export default peerReducer;

export interface IPeerReducer {
  data: any;
  peerId: any;
  peerIdOfTransactionApi: any;
  peerIdOfCPUUsageApi: any;
  peerIdOfMemoryUsageApi: any;
  loadDiffOfPeer: boolean;
  loadDiffOfTransaction: boolean;
  loadDiffOfCPUUsage: boolean;
  loadDiffOfMemoryUsage: boolean;
  onCLickBtnGraphName: string;
  defaultGraphVisibility: boolean;
  noAccess: boolean;
  searchedPeer: string;
  searchedPeerListForDropdown: any;
}

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const sendAPI = (data: ISendAPIParams): Promise<IChatSendAPI> => {
  return HTTPService.post(process.env.REACT_APP_CHAT_APIBASEURL + apiEndPoints.chat.send, data);
};

interface ISendAPIParams {
  message: string;
  userOption?: number;
}

interface IChatSendAPI {
  message: string;
  status: number;
  data: {
    role: string;
    content: string;
  };
}

import { uploadContractContext } from "hooks/UseUploadNewContract";
import { useContext } from "react";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { handleTrim } from "utils/commonFunctions";
const FirstTab = () => {
  const {
    contractFormik: { errors, values, touched, setFieldValue, handleBlur },
    setCurrentStep
  } = useContext(uploadContractContext);
  return (
    <>
      {/* tab1 start */}
      <div className="tab-1 tab-block h-100 w-100">
        <div className="d-flex h-100 w-100 flex-column">
          <div className="w-100 flex-grow-1 order-2 order-lg-1">
            <div className="title">
              <h5 className="c-tx-primary f-700 mb-20">Smart Contract</h5>
            </div>
            <Form className="form-wrapper">
              <Row>
                <Col md="12" lg="12" xl="12">
                  <FormGroup>
                    <Label for="name">
                      Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      id="name"
                      name="name"
                      placeholder="Name of the contract"
                      type="text"
                      value={values.name}
                      onChange={(e) => {
                        setFieldValue("name", e.target.value);
                        handleTrim(e.target.name, e.target.value, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      invalid={Boolean(touched.name && errors.name)}
                    />
                    <FormFeedback>{errors.name}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="12" lg="12" xl="12">
                  <FormGroup>
                    <Label for="description">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <Input
                      id="description"
                      name="description"
                      placeholder="Contract Description"
                      type="textarea"
                      value={values.description}
                      onChange={(e) => {
                        setFieldValue("description", e.target.value);
                        handleTrim(e.target.name, e.target.value, setFieldValue);
                      }}
                      onBlur={handleBlur}
                      invalid={Boolean(touched.description && errors.description)}
                    />
                    <FormFeedback>{errors.description}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="d-flex justify-content-between order-1 order-lg-2">
            <div className="d-inline-flex">
              <Button type="button" className="custom-primary ext-small disable" disabled>
                back
              </Button>
            </div>
            <div className="d-inline-flex">
              <Button
                disabled={!!errors.name || !!errors.description || !values.name || !values.description}
                type="button"
                className="custom-primary ext-small"
                onClick={async () => {
                  if (!errors.name && !errors.description && values.name && values.description) {
                    setCurrentStep((current: number) => {
                      return current + 1;
                    });
                  }
                }}>
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstTab;

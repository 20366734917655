import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getChannelDetailsAPI } from "actions/channelDetailsAPI";
import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Form, Modal } from "reactstrap";
import { setLoading } from "store/loader/action";

const closeIconRegular = <FontAwesomeIcon icon={faTimesCircle} />;

interface IChanelViewModalComponent {
  id: string;
  viewmodal: boolean;
  setViewModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChanelViewModalComponent: FC<IChanelViewModalComponent> = ({ viewmodal, setViewModal, id }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [channelDetails, setChannelDetails] = useState();

  const GetChannelDetailsAPI = () => {
    dispatch(setLoading(true));
    getChannelDetailsAPI(id).then((res: any) => {
      if (res.status === 200) {
        setChannelDetails(res.data);
      }
      dispatch(setLoading(false));
    });
  };

  useEffect(() => {
    if (id) {
      GetChannelDetailsAPI();
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <>
      <Modal
        isOpen={viewmodal}
        className="custom-popup-channel"
        toggle={() => setViewModal(!viewmodal)}
        size="lg"
        centered
        external={
          <Link to="#" className=" text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30" onClick={() => setViewModal(!viewmodal)}>
            {closeIconRegular}
          </Link>
        }>
        {" "}
        <div className="custom-card-wrapper ct-modal-wrapper-read add-participant">
          <div className="left-block">
            {" "}
            <h5 className="mb-20 c-tx-primary f-500">bMessage</h5>
            <div className="ct-table mh-100 px-0">
              <table>
                <thead>
                  <tr>
                    <th>NO.</th>
                    <th>PARTICIPANTS</th>
                    <th>PARTICIPANTS</th>
                    <th>DATE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td>
                    <td>Williams</td>
                    <td>Invitation Sent</td>
                    <td>15/04/2022</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Williams</td>
                    <td>Invitation Sent</td>
                    <td>15/04/2022</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Williams</td>
                    <td>Invitation Sent</td>
                    <td>15/04/2022</td>
                  </tr>{" "}
                  <tr>
                    <td>1</td>
                    <td>Williams</td>
                    <td>Invitation Sent</td>
                    <td>15/04/2022</td>
                  </tr>{" "}
                  <tr>
                    <td>1</td>
                    <td>Williams</td>
                    <td>Invitation Sent</td>
                    <td>15/04/2022</td>
                  </tr>{" "}
                  <tr>
                    <td>1</td>
                    <td>Williams</td>
                    <td>Invitation Sent</td>
                    <td>15/04/2022</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="right-block">
            <Form className="form-wrapper">
              <div>
                <h5 className="mb-20 c-tx-primary f-500">Details</h5>
                <p className="mb-10 c-tx-primary">Sent By : Williams</p>
                <p className="mb-10 c-tx-primary">Sent On : 15/04/2022</p>
              </div>
              <div>
                <Button type="button" onClick={(e) => e.preventDefault()} className="custom-primary-outline mb-10 w-100">
                  Rejected
                </Button>
                <Button type="button" onClick={(e) => e.preventDefault()} className="custom-primary w-100">
                  Accepted
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChanelViewModalComponent;

import { ConfirmationModal, KYCRejectModal } from "components/models";
import { useDebouncedEffect } from "hooks/UseDebouncedEffect";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Input, Row, Spinner, Table } from "reactstrap";
import { setConfirmationModalVisibility, setConfirmationModelId } from "store/confirmationModule/action";
import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { approveMemberKyc } from "utils/common/helper/approveMemberKyc";
import { getMemberKycList } from "utils/common/helper/kycList";
import { getFormattedDate, getKyCStatus, reduceWhiteSpace, toastSuccess } from "utils/commonFunctions";
import { initialPaginationConfigs } from "utils/constants";
import { makeFullS3ObjectUrl } from "utils/makeFullS3ObjectUrl";

const ArrowSvg = makeFullS3ObjectUrl("arrow.svg");

const KycListing = () => {
  // test
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { confirmationModelVisibility } = useSelector((state: IRootReducer) => state.confirmationModel);
  // redux states
  const { isLoading } = useSelector((state: IRootReducer) => state.loading);

  /**
   * @filter
   * @search
   */
  const [paginationConfig, setPaginationConfig] = useState({
    search: "",
    page: 1,
    limit: 10,
    filter: ""
  });
  // debounce the api call, so It will be fired only when the user stopped typing
  useEffect(() => {
    dispatch(setLoading(true));
  }, [dispatch]);
  useDebouncedEffect(() => handleGetAllMembersKycAPICall(), [paginationConfig], 500);

  /**
   * @members_listing
   * @api
   */
  // user management states
  const [allMembersKyc, setAllMembersKyc] = useState<kycAPIType[]>([]);
  const [allMembersKycCount, setAllMembersKycCount] = useState(0);
  // load teh members api data
  const handleGetAllMembersKycAPICall = () => {
    dispatch(setLoading(true));
    let pagination: any = {};
    Object.keys(paginationConfig).forEach((data) => {
      // @ts-ignore
      if (paginationConfig[data] !== "") {
        // @ts-ignore
        pagination[data] = paginationConfig[data];
      }
    });
    getMemberKycList(pagination).then((res: any) => {
      dispatch(setLoading(false));
      setAllMembersKyc(res?.data?.rows);
      setAllMembersKycCount(res?.data?.count);
    });
  };

  /**
   * @kyc_action_handler
   *
   * @param id
   * @param approvalStatus
   * @param reason
   */ const [kycActionFunction, setKycActionFunction] = useState<any>({
    fnCall: () => {}
  });
  const [confirmationModalTitle, setConfirmationModalTitle] = useState<string>("");
  // kyc reject
  const [isKycRejectModal, setIsKycRejectModal] = useState(false);
  const toggleKycRejectModal = () => {
    handleGetAllMembersKycAPICall();
    setIsKycRejectModal(!isKycRejectModal);
    dispatch(setLoading(false));
    setKycId(undefined);
  };
  const [kycId, setKycId] = useState<number>();
  const approveKyc = (id: number) => {
    const params = {
      id: id,
      status: 1,
      reason: ""
    };
    dispatch(setLoading(true));

    approveMemberKyc(params).then((res: any) => {
      handleGetAllMembersKycAPICall();
      dispatch(setLoading(false));
      dispatch(setConfirmationModalVisibility(false));
      toastSuccess(res.message);
    });
  };

  /**
   * @infinite_scroll
   */
  // infinite scroll logic
  useEffect(() => {
    // infinite scroll
    function fetchNext() {
      // @ts-ignore
      if (tableScroll?.scrollHeight - tableScroll?.scrollTop === tableScroll?.clientHeight) {
        setPaginationConfig({
          ...paginationConfig,
          limit: paginationConfig.limit + 10
        });
      }
    }
    // @ts-ignore
    let tableScroll = document.getElementsByClassName("table-responsive")?.[0];
    tableScroll?.addEventListener("scroll", fetchNext);

    // remove scroll event listener when all data is fetched
    if (allMembersKycCount === allMembersKyc?.length) {
      tableScroll?.removeEventListener("scroll", fetchNext);
    }

    return () => {
      tableScroll?.removeEventListener("scroll", fetchNext);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMembersKycCount, paginationConfig]);

  return (
    <div className="user-management-wrapper">
      <Container>
        <div className="list-title">
          <Row className="align-items-center justify-content-center">
            <Col className="d-flex">
              <img src={ArrowSvg} alt="" style={{ width: 20, transform: "rotate(90deg)" }} className="cursor-pointer" onClick={() => navigate("/user-management")} id="back-button" />
              <h2 className="c-tx-primary mb-md-0 h2 f-700 flex-grow-1 text-center">Members KYC</h2>
            </Col>
          </Row>
        </div>
        <div className="table-wrapper">
          <div className="table-search-wrap px-3">
            <Row className="align-items-center">
              <Col md="6">
                <Input
                  placeholder="Search "
                  className="ps-0"
                  value={paginationConfig.search}
                  onChange={(event) => {
                    if (reduceWhiteSpace(event.target.value)) {
                      setPaginationConfig({
                        ...paginationConfig,
                        search: reduceWhiteSpace(event.target.value)
                      });
                    } else {
                      if (paginationConfig.search) {
                        setPaginationConfig({
                          ...paginationConfig,
                          search: ""
                        });
                      }
                    }
                  }}
                />
              </Col>
              <Col md="6" className="align-items-center py-2 py-md-0 text-md-end">
                <Link
                  to="/"
                  className="custom-link"
                  onClick={(event) => {
                    event.preventDefault();
                    setPaginationConfig(initialPaginationConfigs);
                  }}>
                  {" "}
                  Clear{" "}
                </Link>
              </Col>
            </Row>
          </div>
          <Table responsive={true} className="mb-0">
            <thead style={{ position: "sticky", top: 0 }}>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>KYC applied date</th>
                <th>Status</th>
                <th className="text-center text-nowrap">Approve / Reject</th>
                <th className="text-center text-nowrap">Action</th>
              </tr>
            </thead>
            <tbody>
              {allMembersKyc?.map((member: kycAPIType, index) => (
                <tr key={index}>
                  <td className="text-capitalize">
                    {member.firstName} {member.lastName}
                  </td>
                  <td className="text-capitalize">{member.email}</td>
                  <td>{getFormattedDate(member.createdAt)}</td>
                  <td className={"text-capitalize position-relative"} style={{ width: 136 }}>
                    <div className="d-flex align-items-center">
                      {/* <span className={`status-indicator mr-10 ${getKyCStatus(member.isApproved)?.class}`}></span> */}
                      <span className={`${getKyCStatus(member.isApproved)?.class}`}>{getKyCStatus(member.isApproved)?.label}</span>
                    </div>
                  </td>
                  <td className="text-capitalize text-center text-nowrap">
                    {member.submittedBy === "org-kyc" ? (
                      <span>Approved by Admin</span>
                    ) : (
                      <>
                        <span
                          onClick={() => {
                            setConfirmationModalTitle("Are you sure to approve this KYC Request!");
                            dispatch(setConfirmationModelId(member.id));
                            setKycActionFunction({
                              fnCall: approveKyc
                            });
                            dispatch(setConfirmationModalVisibility(true));
                          }}
                          className={`cursor-pointer custom-link ${member.isApproved !== 0 ? "pe-none opacity-50" : ""}`}>
                          Approve
                        </span>{" "}
                        /
                        <span
                          onClick={() => {
                            setIsKycRejectModal(true);
                            setKycId(member.id);
                          }}
                          className={`cursor-pointer custom-link ${member.isApproved !== 0 ? "pe-none opacity-50" : ""}`}>
                          Reject
                        </span>
                      </>
                    )}
                  </td>
                  <td className="text-center text-nowrap">
                    <Link to={`/kyc-list/${member.userId}`} className="custom-link">
                      View Details
                    </Link>
                  </td>
                </tr>
              ))}
              {allMembersKyc?.length > 0 || isLoading ? (
                ""
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No Data Found
                  </td>
                </tr>
              )}
              {isLoading && (
                <tr>
                  <td colSpan={6}>
                    <Spinner size={"sm"} style={{ marginLeft: "50%" }} />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </Container>
      <React.Fragment>{confirmationModelVisibility && <ConfirmationModal handleOnClickAction={kycActionFunction.fnCall} title={confirmationModalTitle} />}</React.Fragment>
      <React.Fragment>{KYCRejectModal && <KYCRejectModal toggleKycRejectModal={toggleKycRejectModal} isKycRejectModal={isKycRejectModal} kycId={kycId} />}</React.Fragment>
    </div>
  );
};

export default KycListing;

type kycAPIType = {
  createdAt: string;
  email: string;
  firstName: string;
  id: number;
  isApproved: number;
  lastName: string;
  statusReason: string;
  userId: string;
  submittedBy: string;
};

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const addUpdateUserAppPermissions = (params: IAddUpdateUserAppPermissions): Promise<IAddUpdateUserAppPermissionsResult> => {
  return HTTPService.post(apiEndPoints.appsPermission, params);
};
interface IUserApps {
  access: boolean;
  appId: string;
  isAvailable?: number;
  logo?: string;
  logoIcon?: string;
  name: string;
}

interface IAddUpdateUserAppPermissionsResult {
  message: string;
  status: number;
}
export interface IAddUpdateUserAppPermissions {
  apps: [IUserApps];
  uid: string;
}

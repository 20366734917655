import axios from "axios";
import { apiEndPoints } from "utils/api-endpoints";
import { getDecryptedLocalStorage } from "utils/commonFunctions";
import { localStorageKeys } from "utils/constants";

export const createWebHooksAPI = (data: createWebHooksAPIParams) => {
const url = process.env.REACT_APP_WEBHOOK_APIBASEURL + apiEndPoints.webHook.createWebHooksAPI;
const token = getDecryptedLocalStorage(localStorageKeys.authenticateToken);

const headers = {
  "Content-Type": "application/json", // Replace with the appropriate content type
  "Authorization": token?.token && `Bearer ${token.token}`, // Replace with your actual authorization token
};

  return new Promise((resolve, reject) => {
    axios
      .post(url, data, { headers })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response || error));
  });
};

interface createWebHooksAPIParams {
  redirectUrl: string;
  description: string;
  action: any;
}

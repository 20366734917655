import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { handleLogout, removeDecryptedCookie } from "utils/commonFunctions";
import { cookieKeys } from "utils/constants";
const cross = <FontAwesomeIcon icon={faTimesCircle} />;

function Unauthorize() {
  return (
    <>
      <div className="custom-card-wrapper d-flex justify-content-center align-items-center" style={{ minHeight: "90vh" }}>
        <div className="user-management-popup confirm-popup-wrapper position-relative">
          <div className="content-wrapper flex-column ">
            <span className="text-danger font-50">{cross}</span>
            <div className="content-title">
              <h1 className="h1">Unauthorized</h1>
            </div>

            <div className="content">
              <h5 className="c-tx-ninth text-center f-400 mb-0">
                You are not authorized to access this page.{" "}
                <Link
                  to="/"
                  className="custom-light-link f-700"
                  onClick={(e) => {
                    e.preventDefault();
                    localStorage.clear();
                    window.location.href = "/";
                  }}>
                  Reload
                </Link>{" "}
                or{" "}
                <Link
                  to="/"
                  className="custom-light-link f-700"
                  onClick={(e) => {
                    e.preventDefault();
                    removeDecryptedCookie(cookieKeys.cookieUser);
                    handleLogout();
                  }}>
                  Login{" "}
                </Link>
                again.
              </h5>
            </div>
          </div>
          <div className="footer-bl">
            <p className="mb-0">
              for further inquiries, please contact{" "}
              <a href="mailto:helpdesk@edexa.com?subject=Support Request" target="_blank" rel="noreferrer" className="custom-light-link c-tx-secondary text-lowercase f-700">
                helpdesk@edexa.com
              </a>
              .
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Unauthorize;

const AppMenu = ({ color }: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill={color} viewBox="0 0 18 18">
      <g id="Group_11" data-name="Group 11" transform="translate(-1 -1)">
        <path
          id="Path_42"
          data-name="Path 42"
          d="M4,8.5H8.5V4H4ZM10.75,22h4.5V17.5h-4.5ZM4,22H8.5V17.5H4Zm0-6.75H8.5v-4.5H4Zm6.75,0h4.5v-4.5h-4.5ZM17.5,4V8.5H22V4ZM10.75,8.5h4.5V4h-4.5Zm6.75,6.75H22v-4.5H17.5Zm0,6.75H22V17.5H17.5Z"
          transform="translate(-3 -3)"
        />
      </g>
    </svg>
  );
};

export default AppMenu;

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getOrderDetailsAPI = (id: string): Promise<IgetPeerListAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.myBlockChain.order.view + id);
};

export interface IgetOrderDetailsAPIResData {
  name: string;
  region: string;
  size: string;
  createdAt: string;
}

interface IgetPeerListAPIRes {
  message: string;
  status: number;
  data: IgetOrderDetailsAPIResData;
}

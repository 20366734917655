import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const ChannelInvitationAPI = (parmas: any): Promise<IChannelInvitationAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.channelManagement.invitation, parmas);
};

export interface IChannelInvitationAPIResData {
  invitationChannelId: { channelName: string; id: string };
  invitationSentBy: { email: string; name: string };
  status: number;
  createdAt: string;
  totalParticipate: number;
  id: string;
}

interface IChannelInvitationAPIRes {
  status: number;
  message: string;
  data: {
    count: number;
    data: IChannelInvitationAPIResData[];
  };
}

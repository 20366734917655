import { VerificationOtpAPI } from "actions/verificationOtpAPI";
import { useFormik } from "formik";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Button, FormGroup, Input, Modal } from "reactstrap";

import { setLoading } from "store/loader/action";
import { toastSuccess } from "utils/commonFunctions";
import { validationRegex } from "utils/constants";

import * as Yup from "yup";

interface IInvitationModalComponent {
  invitationmodal: boolean;
  setInvitationmodal: React.Dispatch<React.SetStateAction<boolean>>;
  handleAction?: () => void;
  handleResend?: (values: boolean) => void;
  requestName: string;
}
const InvitationModalComponent: FC<IInvitationModalComponent> = ({ setInvitationmodal, invitationmodal, handleAction, handleResend, requestName }) => {
  const dispatch = useDispatch();
  const interval: any = useRef(null);

  const [resendOTP, setResendOTP] = useState(false);
  const [validate, setValidate] = useState({
    isValidate: false,
    isDublicate: false
  });
  const [otpExpireTime, setOtpExpireTime] = useState({
    status: false,
    time: {
      seconds: 0,
      minutes: 0
    }
  });

  const formikState = useFormik({
    initialValues: {
      otp: ""
    },
    validationSchema: Yup.object({
      otp: Yup.string().required("OTP is a required").matches(validationRegex.onlyNumberRegex, "OTP number must be numbers only.")
    }),
    onSubmit: (values) => {
      dispatch(setLoading(true));
      VerificationOtpAPI({
        otp: String(values.otp).trim(),
        requestName: requestName
      }).then((res) => {
        if (res.status === 409) {
          setFieldError("otp", res.message);
          setValidate({ ...validate, isDublicate: true });
          dispatch(setLoading(false));
        } else if (res.status === 200) {
          setValidate({ isDublicate: false, isValidate: false });
          handleAction && handleAction();
          setInvitationmodal(!invitationmodal);
          dispatch(setLoading(false));
          toastSuccess("Your Create Channel request successfully added");
        }
      });
    }
  });

  const { errors, values, handleSubmit, resetForm, setFieldError, setFieldValue } = formikState;

  const resendOTPinterval = (newDate: any) => {
    setResendOTP(false);
    interval.current = setInterval(() => {
      const currentDate: any = new Date();
      const difference: any = new Date(newDate - currentDate);
      const minutes = difference.getUTCMinutes() > 9 ? difference.getUTCMinutes() : "0" + difference.getUTCMinutes();
      const seconds = difference.getUTCSeconds() > 9 ? difference.getUTCSeconds() : "0" + difference.getUTCSeconds();
      setOtpExpireTime({
        ...otpExpireTime,
        status: true,
        time: {
          minutes: Number(minutes),
          seconds: Number(seconds)
        }
      });
      if (difference < 1) {
        clearInterval(interval.current);
        setOtpExpireTime({
          status: false,
          time: {
            minutes: 0,
            seconds: 0
          }
        });
        setResendOTP(true);
      }
    }, 1000);
  };

  useEffect(() => {
    if (invitationmodal) {
      resendOTPinterval(new Date(new Date().getTime() + 60 * 1000));
    } else {
      clearInterval(interval.current);
      setOtpExpireTime({
        status: false,
        time: {
          minutes: 0,
          seconds: 0
        }
      });
      setResendOTP(true);
      resetForm();
    }
    // eslint-disable-next-line
  }, [invitationmodal]);

  const handleToggle = () => {
    clearInterval(interval.current);
    setOtpExpireTime({
      status: false,
      time: {
        seconds: 0,
        minutes: 0
      }
    });
    resetForm();
    setValidate({ isDublicate: false, isValidate: false });
    setInvitationmodal(!invitationmodal);
  };

  const handleValidate = () => {
    setValidate({ ...validate, isValidate: true });
    if (!validate.isDublicate) {
      handleSubmit();
    }
  };
  const handleOTPChange = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    setValidate({ ...validate, isDublicate: false });
    setFieldValue("otp", e?.target.value.trim());
  };
  return (
    <>
      <Modal
        isOpen={invitationmodal}
        className="custom-popup-channel invitation-popup"
        style={{ maxWidth: "450px" }}
        centered
        external={
          <Link to="#" className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30" onClick={handleToggle}>
            CLOSE
          </Link>
        }>
        <div className="custom-card-wrapper h-auto">
          <div className="title">
            <h5 className="c-tx-primary f-700 mb-20">OTP Verification</h5>
          </div>
          <FormGroup className="position-relative">
            <Input id="otp" name="otp" onChange={handleOTPChange} invalid={Boolean(validate?.isDublicate ? true : errors?.otp)} value={values.otp} placeholder="Enter OTP" type="number" />
            {validate?.isDublicate ? <span className="text-danger">Invalid OTP</span> : errors?.otp && <span className="text-danger">{errors?.otp}</span>}
            <div className="sendlink">
              {resendOTP ? (
                <Link
                  to="#"
                  className="link-resend"
                  onClick={() => {
                    handleResend && handleResend(true);
                    resendOTPinterval(new Date(new Date().getTime() + 60 * 1000));
                  }}>
                  Resend
                </Link>
              ) : (
                <Link to="#" className="link-resend">
                  ({otpExpireTime.time.minutes} : {otpExpireTime.time.seconds})
                </Link>
              )}
            </div>
          </FormGroup>
          <p className="c-tx-ninth mb-20" style={{ lineHeight: "16px" }}>
            To accept the invitation, Please enter the OTP which has been sent to you.
          </p>
          <Button type="button" onClick={handleValidate} className="custom-primary w-100">
            Validate
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default InvitationModalComponent;

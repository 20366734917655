import { getEncryptedCookie, handleLogout } from "utils/commonFunctions";
import { cookieKeys } from "utils/constants";
import { LOGGEDIN, LOGOUT, TOUR } from "./type";

const isUserLoggedIn = () => {
  const cookie = getEncryptedCookie(cookieKeys.cookieUser);
  if (cookie && cookie.token) {
    return true;
  } else {
    return false;
  }
};

const initialState = {
  isLoggedIN: isUserLoggedIn(),
  tour: false
};

const authReducer = (state = initialState, action: { type: string; payload: boolean }) => {
  const newState = { ...state };

  switch (action.type) {
    case LOGGEDIN:
      newState.isLoggedIN = true;
      break;

    case LOGOUT:
      handleLogout();
      newState.isLoggedIN = false;
      break;

    case TOUR:
      newState.tour = action.payload;
      break;
  }
  return newState;
};

export default authReducer;

export interface IAuth {
  tour: boolean;
  isLoggedIN: any;
}

import { memo } from "react";
import { useLocation } from "react-router-dom";
import { makeFullS3ObjectUrl } from "utils/makeFullS3ObjectUrl";
import "../../styles/common/common.scss";
import "../../styles/header/header.scss";
const logo = makeFullS3ObjectUrl("logo_full.svg");
const universeLogo = makeFullS3ObjectUrl("universe.svg");

const HeaderUnderComing = () => {
  const location = useLocation();
  return (
    <div className="position-relative" style={{ top: "10px", left: "10px" }}>
      <img src={location.pathname === "/under-maintenance" ? logo : universeLogo} alt="logo" width={120} />
    </div>
  );
};

export default memo(HeaderUnderComing);

import { FormFeedback, FormGroup, Input, Label, Spinner } from "reactstrap";
import { trimExtraSpacesFromString } from "utils/commonFunctions";
import { StepperStepsPropsType } from "./Stepper";

export function StepperFirstTab({ formik, networkDomains }: StepperStepsPropsType) {
  const { values, errors, setFieldValue, touched, handleSubmit } = formik;

  return (
    <>
      <div className="flex-wrap-bl order-2 order-lg-1">
        <h5 className="tab-heading c-tx-primary f-700">Enter Environment Details</h5>
        <FormGroup className="m-0">
          <Label for="exampleEmail">Name of this Environment</Label>
          <Input
            id="exampleEmail"
            name="enviornmentDetails.name"
            placeholder="Enter the name of this enviornment"
            type="email"
            value={values.enviornmentDetails.name}
            onChange={(event) => {
              handleSubmit();
              setFieldValue(event.target.name, trimExtraSpacesFromString(event.target.value));
            }}
            invalid={Boolean(errors?.enviornmentDetails?.name) && Boolean(touched?.enviornmentDetails?.name)}
          />
          <FormFeedback>{errors?.enviornmentDetails?.name}</FormFeedback>
        </FormGroup>
        <div className="detail-block">
          <h5 className="tab-heading c-tx-primary f-700 mb-1">Environment Details</h5>
          <p className="c-tx-ninth">Your private ecosystem will be setup on AWS - edeXa will enable soon more multi regions.</p>
          <div className={`out-wrap overflow-auto  flex-wrap ${errors?.enviornmentDetails?.cloudType && touched.enviornmentDetails?.cloudType ? "is-invalid" : ""}`} style={{ height: 150 }}>
            {networkDomains?.length! > 0 ? (
              networkDomains?.map((network) => {
                return (
                  <div className={`sm-wrap ${errors?.enviornmentDetails?.cloudType && touched.enviornmentDetails?.cloudType ? " border-red" : ""}`} key={network.id} style={{ alignSelf: "stretch" }}>
                    <label>
                      <input
                        type="radio"
                        disabled={network?.disable}
                        name="product"
                        className="card-input-element"
                        checked={values.enviornmentDetails.cloudType === network.id || false}
                        onChange={(event) => event.target.checked && setFieldValue("enviornmentDetails.cloudType", network.id)}
                      />
                      <div className="panel panel-default card-input">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="text-bl">
                            <p className="mb-0 f-700">{network.name}</p>
                          </div>
                          <div className="custom-radio-btn"></div>
                        </div>
                      </div>
                    </label>
                  </div>
                );
              })
            ) : (
              <Spinner />
            )}
          </div>
          <FormFeedback>{errors?.enviornmentDetails?.cloudType}</FormFeedback>
          <div className="ct-15">
            <p className="c-tx-ninth"> </p>
          </div>
        </div>
      </div>
    </>
  );
}

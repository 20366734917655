import { ConfirmationModal, KYCRejectModal } from "components/models";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Container, Modal, Row } from "reactstrap";

import { setConfirmationModalVisibility } from "store/confirmationModule/action";
import { setLoading } from "store/loader/action";
import { approveMemberKyc } from "utils/common/helper/approveMemberKyc";
import { getMemberKyc, IKyc } from "utils/common/helper/getMemberKyc";
import { capitalizeFirstLetter, getFormattedDate, toastSuccess } from "utils/commonFunctions";
import { makeFullS3ObjectUrl } from "utils/makeFullS3ObjectUrl";

const ArrowSvg = makeFullS3ObjectUrl("arrow.svg");

const KycDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [kycDetails, setKycDetails] = useState<IKyc>();
  const getMemberKycDetails = useCallback(() => {
    getMemberKyc(String(id))
      .then((res) => {
        setKycDetails(res.data);
        dispatch(setLoading(false));
      })
      .catch((err) => navigate("/kyc-list"));
  }, [dispatch, id, navigate]);
  useEffect(() => {
    if (id) {
      dispatch(setLoading(true));
      getMemberKycDetails();
    }
  }, [dispatch, getMemberKycDetails, id]);

  const [imageModal, setImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState<string | undefined>("");
  const toggleImageModal = () => setImageModal(!imageModal);

  /**
   * @kyc_action_handler
   *
   * @param id
   * @param approvalStatus
   * @param reason
   */
  const urlParam: any = useParams();
  const approveKyc = () => {
    const params = {
      id: parseInt(urlParam?.id),
      status: 1,
      reason: ""
    };
    dispatch(setLoading(true));

    approveMemberKyc(params).then((res: any) => {
      getMemberKycDetails();
      dispatch(setLoading(false));
      dispatch(setConfirmationModalVisibility(false));
      toastSuccess(res.message);
    });
  };
  const [kycActionFunction, setKycActionFunction] = useState<any>({
    fnCall: () => {}
  });
  const [confirmationModalTitle, setConfirmationModalTitle] = useState<string>("");
  // kyc reject
  const [isKycRejectModal, setIsKycRejectModal] = useState(false);
  const toggleKycRejectModal = () => setIsKycRejectModal(!isKycRejectModal);

  return (
    <>
      <div className="user-management-wrapper">
        <Container>
          <div className="list-title">
            <Row className="align-items-center justify-content-center">
              <Col md="12" sm="12" xs="12">
                <div className="d-flex justify-content-between align-items-center">
                  <img src={ArrowSvg} alt="" style={{ width: 20, transform: "rotate(90deg)" }} className="cursor-pointer" onClick={() => navigate("/kyc-list")} id="back-button" />
                  <h2 className="c-tx-primary mb-md-0 h2 f-700 flex-grow-1 text-center">KYC Details</h2>
                  {/* buttons */}
                  {kycDetails?.isApproved === 0 && (
                    <div className="d-flex align-items-center flex-wrap">
                      <Button
                        className="mr-10 c-bg-secondary primaryButton"
                        onClick={() => {
                          dispatch(setConfirmationModalVisibility(true));
                          setKycActionFunction({ fnCall: approveKyc });
                          setConfirmationModalTitle("Are you sure to approve this KYC Request!");
                        }}>
                        Approve
                      </Button>
                      <Button
                        color="danger"
                        onClick={() => {
                          setIsKycRejectModal(true);
                          getMemberKycDetails();
                        }}>
                        Reject
                      </Button>
                    </div>
                  )}
                </div>
              </Col>
              <Col md="6" sm="12" xs="12"></Col>
            </Row>
          </div>

          {/* personal info */}
          <div className="table-wrapper h-auto mb-5">
            <Row className="align-items-center">
              <Col className="">
                <h4 className="p-4">{"Personal Information"}</h4>
                <hr />
              </Col>
            </Row>

            <Row className="align-items-center p-2 px-4">
              <Col sm="12" md="3">
                <p className="text-uppercase">First Name</p>
              </Col>
              <Col sm="12" md="8">
                <p className="f-700">{kycDetails?.firstName}</p>
              </Col>
            </Row>
            <hr />
            <Row className="align-items-center p-2 px-4">
              <Col sm="12" md="3">
                <p className="text-uppercase">Last Name</p>
              </Col>
              <Col sm="12" md="8">
                <p className="f-700">{kycDetails?.lastName}</p>
              </Col>
            </Row>
            <hr />
            {kycDetails?.email && (
              <>
                <Row className="align-items-center p-2 px-4">
                  <Col sm="12" md="3">
                    <p className="text-uppercase">Email</p>
                  </Col>
                  <Col sm="12" md="8">
                    <p className="f-700">{kycDetails?.email}</p>
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {kycDetails?.countryOfOrigin && (
              <>
                <Row className="align-items-center p-2 px-4">
                  <Col sm="12" md="3">
                    <p className="text-uppercase">Country Of Origin</p>
                  </Col>
                  <Col sm="12" md="8">
                    <p className="f-700">{kycDetails?.countryOfOrigin}</p>
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {kycDetails?.countryOfResidence && (
              <>
                <Row className="align-items-center p-2 px-4">
                  <Col sm="12" md="3">
                    <p className="text-uppercase">Country Of Residence</p>
                  </Col>
                  <Col sm="12" md="8">
                    <p className="f-700">{kycDetails?.countryOfResidence}</p>
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {kycDetails?.gender && (
              <>
                {" "}
                <Row className="align-items-center p-2 px-4 pb-4">
                  <Col sm="12" md="3">
                    <p className="text-uppercase">Gender</p>
                  </Col>
                  <Col sm="12" md="8">
                    <p className="f-700">{kycDetails?.gender}</p>
                  </Col>
                </Row>
              </>
            )}
          </div>
          {/* documents */}
          <div className="table-wrapper mb-5 h-auto">
            <Row className="align-items-center">
              <Col className="">
                <h4 className="p-4 m-0">{"Documents Uploaded"}</h4>
              </Col>
            </Row>
            <hr />
            {!kycDetails?.documentType && (
              <Row className="align-items-center p-2 px-4 mb-3">
                <Col>
                  <p className="text-uppercase">No Documents Uploaded</p>
                </Col>
              </Row>
            )}
            {kycDetails?.documentType && (
              <>
                <Row className="align-items-center p-2 px-4">
                  <Col sm="12" md="3">
                    <p className="text-uppercase">Document Type</p>
                  </Col>
                  <Col sm="12" md="8">
                    <p className="f-700">{kycDetails?.documentType === "other" ? capitalizeFirstLetter(kycDetails.documentName) : capitalizeFirstLetter(kycDetails.documentType)}</p>
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {kycDetails?.documentFrontUrl && (
              <>
                {" "}
                <Row className="align-items-center p-2 px-4">
                  <Col sm="12" md="3">
                    <p className="text-uppercase">Document Front Side</p>
                  </Col>
                  <Col sm="12" md="8">
                    {kycDetails?.documentFrontUrl && (
                      <p
                        className="f-700 cursor-pointer color-blue"
                        onClick={() => {
                          setCurrentImage(kycDetails?.documentFrontUrl);
                          toggleImageModal();
                        }}>
                        View Image
                      </p>
                    )}
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {kycDetails?.documentBackUrl && (
              <>
                <Row className="align-items-center p-2 px-4">
                  <Col sm="12" md="3">
                    <p className="text-uppercase">Document Back Side</p>
                  </Col>
                  <Col sm="12" md="8">
                    {kycDetails?.documentBackUrl && (
                      <p
                        className="f-700 cursor-pointer color-blue"
                        onClick={() => {
                          setCurrentImage(kycDetails?.documentBackUrl);
                          toggleImageModal();
                        }}>
                        View Image
                      </p>
                    )}
                  </Col>
                </Row>
                <hr />
              </>
            )}
            {kycDetails?.liveImageUrl && (
              <Row className="align-items-center p-2 px-4 pb-4">
                <Col sm="12" md="3">
                  <p className="text-uppercase">Selfie with document</p>
                </Col>
                <Col sm="12" md="8">
                  {kycDetails?.liveImageUrl && (
                    <p
                      className="f-700 cursor-pointer color-blue"
                      onClick={() => {
                        setCurrentImage(kycDetails?.liveImageUrl);
                        toggleImageModal();
                      }}>
                      View Image
                    </p>
                  )}
                </Col>
              </Row>
            )}
          </div>
          {/* status */}
          <div className="table-wrapper mb-5 pb-4 h-auto">
            <Row className="align-items-center">
              <Col className="">
                <h4 className="p-4">{"KYC Status"}</h4>
                <hr />
              </Col>
            </Row>
            <Row className="align-items-center p-2 px-4">
              <Col sm="12" md="3">
                <p className="text-uppercase">KYC RESULT</p>
              </Col>
              <Col sm="12" md="8">
                <p className="f-700">{kycDetails?.isApproved === 1 ? "Approved" : kycDetails?.isApproved === 0 ? "Pending" : "Rejected"}</p>
              </Col>
            </Row>
            <hr />
            <Row className="align-items-center p-2 px-4">
              <Col sm="12" md="3">
                <p className="text-uppercase">KYC applied date</p>
              </Col>
              <Col sm="12" md="8">
                <p className="f-700">{getFormattedDate(kycDetails?.createdAt || "")}</p>
              </Col>
            </Row>
            <hr />
            <Row className="align-items-center p-2 px-4">
              <Col sm="12" md="3">
                <p className="text-uppercase">KCY LAST UPDATED DATE</p>
              </Col>
              <Col sm="12" md="8">
                <p className="f-700">{getFormattedDate(kycDetails?.updatedAt || "")}</p>
              </Col>
            </Row>
            {kycDetails?.isApproved === 2 ? (
              <>
                <hr />
                <Row className="align-items-center p-2 px-4">
                  <Col sm="12" md="3">
                    <p className="text-uppercase">REJECTION DETAILS</p>
                  </Col>
                  <Col sm="12" md="8">
                    <p className="f-700">{kycDetails?.statusReason || ""}</p>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
          </div>
        </Container>
      </div>

      {/**
       * @modals
       */}
      {imageModal && (
        <React.Fragment>
          <Modal
            isOpen={imageModal}
            className="custom-stepper-popup"
            toggle={toggleImageModal}
            size="xl"
            centered
            external={
              <Link
                to="/"
                className=" text-white close modal-close close small text-uppercase"
                onClick={(e) => {
                  e.preventDefault();
                  toggleImageModal();
                }}>
                Close
              </Link>
            }>
            <img src={currentImage || ""} alt="" />
          </Modal>
        </React.Fragment>
      )}
      <React.Fragment>
        <ConfirmationModal handleOnClickAction={kycActionFunction.fnCall} title={confirmationModalTitle} />
      </React.Fragment>
      <React.Fragment>
        <KYCRejectModal toggleKycRejectModal={toggleKycRejectModal} isKycRejectModal={isKycRejectModal} kycId={undefined} />
      </React.Fragment>
    </>
  );
};

export default KycDetails;

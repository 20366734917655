import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getCloudConfigurationsListAPI = (params: getCloudConfigurationsListAPIParams): Promise<getCloudConfigurationsListAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.peerNode.cloudConfigurations.list, params);
};

interface getCloudConfigurationsListAPIParams {
  limit: number;
  page: number;
}

interface getCloudConfigurationsListAPIRes {
  message: string;
  status: number;
  data: {
    count: number;
    data: any[];
  };
}

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const createFolderAPI = (data: IcreateFolderAPIData): Promise<IcreateFolderAPI> => {
  return HTTPService.post(apiEndPoints.universeAPI.folderTemplate.create, data);
};

interface IcreateFolderAPIData {
  name: string;
}

interface IcreateFolderAPI {
  message: string;
  status: number;
}

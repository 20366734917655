// import FooterUnderComing from "Pages/common/FooterUnderComing";
// import HeaderUnderComing from "Pages/common/HeaderUnderComing";

import FooterUnderComing from "components/footer/FooterUnderComing";
import { HeaderUnderComing } from "components/header";

const UnderMaintenance = () => {
  return (
    <div className="p-20 pb-0">
      <div className="mx-auto under-maintanance-wrapper">
        <HeaderUnderComing />
        {/* <Row> */}
        {/* <Col md={6}> */}
        <div className="under-maintenance-content">
          <h3 className="mb-3">WE ARE UNDER</h3>
          <h3 className="mb-3" style={{ opacity: "1" }}>
            MAINTENANCE
          </h3>
          <p className="mb-0">Will be back soon!</p>
        </div>
        {/* </Col>
          <Col md={6} className="underMain-Img">
            <img src={underMaintenance} className="under-construction-image img-fluid" alt="Under Construction" />
          </Col>
        </Row> */}
      </div>
      <FooterUnderComing />
    </div>
  );
};

export default UnderMaintenance;

import { uploadContractContext } from "hooks/UseUploadNewContract";
import { useContext } from "react";
import Select from "react-select";
import { Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import { handleTrim } from "utils/commonFunctions";
export const SecondTab = () => {
  const {
    contractFormik: { errors, values, touched, setFieldValue, handleBlur },
    setCurrentStep
  } = useContext(uploadContractContext);
  const languageOptions = [
    { value: "node", label: "Node" },
    { value: "go", label: "Go" }
  ];
  return (
    <>
      {/* tab1 start */}
      <div className="tab-1 tab-block h-100 w-100">
        <div className="d-flex h-100 w-100 flex-column">
          <div className="w-100 flex-grow-1 order-2 order-lg-1">
            <div className="title">
              <h5 className="c-tx-primary f-700 mb-20">Smart Contract</h5>
            </div>
            <Form className="form-wrapper">
              <Row>
                <Col md="12" lg="12" xl="12">
                  <FormGroup className="">
                    <Label for="protocol">
                      Protocol <span className="text-danger">*</span>
                    </Label>
                    <Input
                      id="protocol"
                      name="protocol"
                      placeholder="Enter the protocol name"
                      type="text"
                      onChange={(e) => handleTrim(e.target.name, e.target.value, setFieldValue)}
                      invalid={Boolean(touched.protocol && errors.protocol)}
                      onBlur={handleBlur}
                      value={values.protocol}
                    />
                    <FormFeedback>{errors.protocol}</FormFeedback>
                  </FormGroup>
                </Col>
                <Col md="12" lg="12" xl="12">
                  <FormGroup className="">
                    <Label for="language">
                      Language <span className="text-danger">*</span>
                    </Label>
                    <Select
                      options={languageOptions}
                      defaultValue={{ ...languageOptions[0] }}
                      id="language"
                      placeholder="Select Language"
                      onChange={(language) => {
                        setFieldValue("language", language?.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="d-flex justify-content-between order-1 order-lg-2">
            <div className="d-inline-flex">
              <Button
                type="button"
                className="custom-primary-outline ext-small"
                onClick={() =>
                  setCurrentStep((current: number) => {
                    return current - 1;
                  })
                }>
                Back
              </Button>
            </div>
            <div className="d-inline-flex">
              <Button
                disabled={!!errors.protocol || !!errors.language || !values.language || !values.protocol}
                type="button"
                className="custom-primary ext-small "
                onClick={() => {
                  if (!errors.language && !errors.protocol && values.language && values.protocol) {
                    setCurrentStep((current: number) => {
                      return current + 1;
                    });
                  }
                }}>
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SecondTab;

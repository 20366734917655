import { memo } from "react";

const NoMoreData = () => {
  // return (
  //   <li className="element">
  //     <div className="block-1 w-100">
  //       <p className="c-tx-primary text-center f-700 first-title">No more results to show</p>
  //     </div>
  //   </li>
  // );
  return null;
};

export default memo(NoMoreData);

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const addParticipantsAPI = (params: addParticipantsAPIParams) => {
  return HTTPService.post(apiEndPoints.universeAPI.channelManagement.addParticipantsAPI + params.channelId, params.data);
};

interface addParticipantsAPIParams {
  channelId: string;
  data: any;
}

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
const Tickmark = <FontAwesomeIcon icon={faTimesCircle} />;

const FailedPop = () => {
  return (
    <>
      <div className="erc-template-wrapper">
        <div className="title mt-30 mb-30">
          <h3 className="big-font-icons red text-center  mb-0">{Tickmark}</h3>
        </div>
        <h4 className="c-tx-primary text-center mb-20">Your uploaded smart contract is not compatible.</h4>
        <div className="btn-wrapper text-center mb-30">
          <Button className="custom-primary new-btn">GO TO LISTING PAGE</Button>
        </div>
      </div>
    </>
  );
};

export default FailedPop;

import { GlobalSettingType } from "./type";

const init_State: IGlobalSettingReducer = {
  underMaintenance: false,
  comingSoon: false
};

const GlobalSettingReducer = (state = init_State, action: { type: string; payload: any }) => {
  let newState = { ...state };
  switch (action.type) {
    case GlobalSettingType.SET_GLOBAL_SETTING:
      newState = action.payload;
      break;
  }
  return newState;
};

export { GlobalSettingReducer };

export interface IGlobalSettingReducer {
  underMaintenance: boolean;
  comingSoon: boolean;
}

import useUploadNewContract, { uploadContractContext } from "hooks/UseUploadNewContract";
import SmartContractModal from "./SmartContractModal";
import UploadNewContractModal from "./UploadNewContractModal";

interface IUploadNewContract {
  stepperModalOpen: boolean;
  toggleStepperModal: () => void;
}
const UploadNewContract = ({ stepperModalOpen, toggleStepperModal }: IUploadNewContract) => {
  const { contractContextValues } = useUploadNewContract(stepperModalOpen, toggleStepperModal);
  return (
    <uploadContractContext.Provider value={contractContextValues}>
      <SmartContractModal stepperModalOpen={stepperModalOpen} />
      <UploadNewContractModal />
    </uploadContractContext.Provider>
  );
};

export default UploadNewContract;

import { ContactSteper } from "components/template";
import { uploadContractContext } from "hooks/UseUploadNewContract";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";

interface IUploadNewContract {
  stepperModalOpen: boolean;
}

const SmartContractModal = ({ stepperModalOpen }: IUploadNewContract) => {
  const contractContextValues = useContext(uploadContractContext);

  return (
    <Modal
      isOpen={stepperModalOpen}
      className="custom-stepper-popup"
      toggle={() => {
        contractContextValues.toggleStepperModal();
      }}
      size="lg"
      centered
      external={
        <Link to="#" className="f-700 text-white close modal-close close small text-uppercase thankYouCloseButtonHide font-30" onClick={() => contractContextValues.toggleStepperModal()}>
          CLOSE
        </Link>
      }>
      {" "}
      <ContactSteper />
    </Modal>
  );
};

export default SmartContractModal;

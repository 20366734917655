import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const approveMemberKyc = (params: IAddMemberKycRequest): Promise<IAddMemberKycResponse> => {
  return HTTPService.post(apiEndPoints.approveKyc, params);
};

export interface IAddMemberKycRequest {
  id: number;
  reason: string;
  status: number;
}

export interface IAddMemberKycResponse {
  message: string;
  status: number;
}

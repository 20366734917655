import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { ThankYou } from 'components/ThankyouPage'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import {
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner
} from 'reactstrap'
import { setLoading } from 'store/loader/action'
import { apiEndPoints } from 'utils/api-endpoints'
import { addNewMember } from 'utils/common/helper/addNewMember'
import { addUpdateUserAppPermissions } from 'utils/common/helper/addUpdateUserAppPermissions'
import { getMemberDataById } from 'utils/common/helper/getMemberDataById'
import { getMemberKyc } from 'utils/common/helper/getMemberKyc'
import { updateMemberDataById } from 'utils/common/helper/updateMemberDataById'
import {
  capitalizeFirstLetter,
  countryFlag,
  getBase64,
  getEncryptedCookie,
  handleTrim,
  returnFormattedBigFileNames,
  toastError,
  toastSuccess
} from 'utils/commonFunctions'
import {
  ReactSelectCustomstyle,
  cookieKeys,
  validationRegex
} from 'utils/constants'
import { countries } from 'utils/countries'
import * as Yup from 'yup'
const Check = <FontAwesomeIcon icon={faCheck} />

const serchableCountriesOptions = countries.map((country) => {
  return {
    label: (
      <p>
        {/* if this api url is not working and show broken image use countryFlag2() api see this function in commonFunctions.tsx for more detials */}
        <img
          src={countryFlag(country.code)}
          alt=""
          height="20"
          width="20"
        />
        &nbsp; &nbsp;
        {country.name}
      </p>
    ),
    value: country.name
  }
})

const AddNewUser = ({
  toggleMemberUpdated,
  updateUserId,
  isUpdate,
  setIsUpdate,
  toggleAddUpdateModal
}) => {
  // current form steps
  // initially form will be at step 1
  const [formStep, setFormStep] = useState(1)
  // loader of button
  const dispatch = useDispatch()
  const [newUserId, setNewUserId] = useState('')
  const [userApps, setUserApps] = useState([])
  const { isLoading } = useSelector((state) => state.loading)
  const [UserDetailButtonDisabled, setUserDetailButtonDisabled] =
    useState(true)
  const [KycButtonDisabled, setKycButtonDisabled] = useState(true)
  const [appsButtonDisabled, setAppsButtonDisabled] = useState(true)

  // scroll user kyc into view when user is created
  const userKycRef = useRef(null)
  const scrollKycIntoView = () => {
    setTimeout(() => {
      userKycRef?.current?.scrollIntoView({
        behavior: 'smooth'
      })
    }, 500)
  }

  // user form validation
  const userValidationSchema = Yup.object().shape({
    first_name: Yup.string()
      .min(3, 'First Name must be at least 3 characters long')
      .max(26, 'First Name should not exceed 26 characters limit')
      .required('First Name is required'),
    last_name: Yup.string()
      .min(3, 'Last Name must be at least 3 characters long')
      .max(26, 'Last Name should not exceed 26 characters limit')
      .required('Last Name is required'),
    email: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
    phone: Yup.string()
      .min(3, 'Invalid Phone Number')
      .max(14, 'Invalid Phone Number')
      .required('Phone Number is required')
      .matches(
        validationRegex.onlyNumberRegex,
        'Only Number is allowed'
      ),
    place: Yup.string()
      .min(2, 'Place Name must be at least 2 characters long')
      .max(26, 'Place Name should not exceed 26 characters limit')
      .matches(
        validationRegex.onlyCharacterNoSpaceRegex,
        'Only alphabet is allowed'
      )
      .required('Place is required'),
    zipcode: Yup.string()
      .required('Zipcode is required')
      .min(4, 'Zipcode must be at least 4 characters long')
      .max(8, 'Zipcode should not exceed 8 characters limit')
      .nullable(),
    address: Yup.string()
      .min(10, 'Address must be at least 10 characters long')
      .required('Address is required')
      .max(35, 'Address should not exceed 35 characters limit'),
    gender: Yup.string().required('Gender is required')
  })

  /**
   * @user
   * @kyc
   */
  // user details handling with formik
  const newUserFormik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      place: '',
      zipcode: '',
      address: '',
      status: '1',
      gender: ''
    },
    validationSchema: userValidationSchema,
    onSubmit: (values) => {
      dispatch(setLoading(true))
      if (isUpdate) {
        updateMemberDataById(updateUserId, values).then((res) => {
          if (res.status === 409) {
            toastSuccess(res.message)
          }
          // setUserSubmitKyc(false);
          dispatch(setLoading(false))
          setFormStep(formStep + 1)
          scrollKycIntoView()

          // refresh member listing
          toggleMemberUpdated()
          toastSuccess(res.message)
        })
      } else {
        addNewMember(values)
          .then((response) => {
            // setUserSubmitKyc(false);
            if (response.status === 409) {
              toastSuccess(response.message)
            }
            dispatch(setLoading(false))
            setFormStep(formStep + 1)
            // scroll kyc into view
            // after form step is increase then scroll otherwise it will not found the element to be scrolled
            scrollKycIntoView()

            // set user Id to fetch the user details
            setNewUserId(response.data?.userId)
            /**
             * @update
             * @kyc_listing_table
             */
            toggleMemberUpdated()
            toastSuccess(response?.message)
          })
          .catch((err) => {
            toastError(err.data.message)
          })
      }
    }
  })

  /**
   * @user_kyc
   * @formik
   */
  const [userSubmitKyc, setUserSubmitKyc] = useState({
    user: true,
    none: false,
    approve: false
  })
  const [isKycApproved, setIsKycApproved] = useState(false)
  const [documentType, setDocumentType] = useState('')

  // submit user kyc onbutton click
  const submitUserKycFormik = useFormik({
    initialValues: {
      userKyc: {
        countryOfOrigin: '',
        countryOfResidence: '',
        documentType: '',
        documentNumber: '',
        documentName: '',
        kycDocumentFront: '',
        kycDocumentBack: '',
        selfieWithDocument: ''
      }
    },
    validationSchema: Yup.object().shape({
      userKyc: Yup.object({
        countryOfOrigin: userSubmitKyc.approve
          ? ''
          : Yup.string().required('Country Of Origin is required'),
        countryOfResidence: userSubmitKyc.approve
          ? ''
          : Yup.string().required('Country Of Residence is required'),
        documentType: userSubmitKyc.approve
          ? ''
          : Yup.string()
              .nullable()
              .required('Document Type is required'),
        documentName:
          userSubmitKyc.approve || documentType !== 'other'
            ? ''
            : Yup.string()
                .nullable()
                .matches(
                  validationRegex.onlyCharacterWithLimit,
                  'Only Alphabat are allowed'
                )
                .required('Document Name is required'),
        documentNumber: userSubmitKyc.approve
          ? ''
          : Yup.string()
              .nullable()
              .matches(
                validationRegex.onlyNumberAndCharRegex,
                'Invalid Number'
              )
              .min(
                5,
                'Document Number should be minimum 5 character long'
              )
              .max(
                14,
                'Document Number can be maximum of 14 character'
              )
              .required('Document Number is required'),
        kycDocumentFront: userSubmitKyc.approve
          ? ''
          : Yup.string()
              .nullable()
              .required('Document Front Image is required'),
        kycDocumentBack: userSubmitKyc.approve
          ? ''
          : Yup.string()
              .nullable()
              .required('Document Back Image is required'),
        selfieWithDocument: userSubmitKyc.approve
          ? ''
          : Yup.string()
              .nullable()
              .required('Selfie with Document is required')
      })
    }),
    onSubmit: async (kycValues) => {
      dispatch(setLoading(true))
      /**
       * @member
       * @kyc
       */

      // backend only accepts selfie in base 64 so we are converting it to base 64 format
      let selfieInBase64 = await getBase64(
        kycValues.userKyc.selfieWithDocument
      )

      const userKycData = {
        firstName: newUserFormik.values.first_name,
        lastName: newUserFormik.values.last_name,
        gender: newUserFormik.values.gender,
        phone: newUserFormik.values.phone,
        email: newUserFormik.values.email,
        countryOfOrigin: kycValues.userKyc.countryOfOrigin,
        countryOfResidence: kycValues.userKyc.countryOfResidence,
        documentType: kycValues.userKyc.documentType,
        documentName: kycValues.userKyc.documentName,
        documentNumber: kycValues.userKyc.documentNumber,
        kycDocumentFront: kycValues.userKyc.kycDocumentFront,
        kycDocumentBack: kycValues.userKyc.kycDocumentBack,
        selfieWithDocument: selfieInBase64,
        userId: newUserId || updateUserId
      }
      const formData = new FormData()
      Object.keys(userKycData)?.forEach((key, index) => {
        formData.append(key, userKycData?.[key])
      })

      // update kyc logic
      // if is update is true then the kyc will be updated if the handle submit get called
      // admin will submit the kyc
      if (isUpdate && !userSubmitKyc.approve) {
        // delete documents or do not send document
        // if admin didn't updated
        // when documents don't have .name which we can find in a file object then delete this doc because admin didn't changed it
        // and the previous doc will remain as it is on server no update.
        if (!userKycData.kycDocumentFront?.name) {
          formData.delete('kycDocumentFront')
        }
        if (!userKycData.kycDocumentBack?.name) {
          formData.delete('kycDocumentBack')
        }
        if (!userKycData.selfieWithDocument?.name) {
          formData.delete('selfieWithDocument')
        }
        const cookie = getEncryptedCookie(cookieKeys.cookieUser)
        const url = `${process.env.REACT_APP_ACCOUNTS_APIBASEURL}${apiEndPoints.submitUserKyc}`
        await axios({
          method: 'post',
          url: url,
          data: formData,
          headers: { Authorization: `Bearer ${cookie.token}` }
        })
          .then((res) => {
            // submitUserKyc(formData).then((response) => {
            dispatch(setLoading(false))
            toggleMemberUpdated()
            toggleAddUpdateModal()
            toastSuccess(res.data?.message)
            // setUserSubmitKyc(!userSubmitKyc);
          })
          .catch((err) => {
            dispatch(setLoading(false))
          })

        // kyc will be approved by default by admin
        // choosed approve user kyc
      } else if (userSubmitKyc.approve) {
        const approveFormData = new FormData()
        approveFormData.append('firstName', userKycData.firstName)
        approveFormData.append('lastName', userKycData.lastName)
        approveFormData.append('gender', userKycData.gender)
        approveFormData.append('userId', userKycData.userId)
        approveFormData.append('email', userKycData.email)

        const cookie = getEncryptedCookie(cookieKeys.cookieUser)
        const url = `${process.env.REACT_APP_ACCOUNTS_APIBASEURL}${apiEndPoints.submitUserKyc}`
        await axios({
          method: 'post',
          url: url,
          data: approveFormData,
          headers: { Authorization: cookie.token }
        })
          .then((response) => {
            // submitUserKyc(approveFormData).then((response) => {
            dispatch(setLoading(false))
            setFormStep(formStep + 1)
            // toggleAddUpdateModal();
            toastSuccess(response?.data?.message)
            // setUserSubmitKyc(!userSubmitKyc);
          })
          .catch((err) => {
            dispatch(setLoading(false))
          })
      } else {
        // kyc submit first time
        const cookie = getEncryptedCookie(cookieKeys.cookieUser)
        const url = `${process.env.REACT_APP_ACCOUNTS_APIBASEURL}${apiEndPoints.submitUserKyc}`
        await axios({
          method: 'post',
          url: url,
          data: formData,
          headers: { Authorization: cookie.token }
        })
          .then((response) => {
            // submitUserKyc(formData).then((response) => {
            dispatch(setLoading(false))
            setFormStep(formStep + 1)
            toastSuccess(response?.message)
            // setUserSubmitKyc(!userSubmitKyc);
          })
          .catch((err) => {
            dispatch(setLoading(false))
          })
      }
    }
  })
  useEffect(() => {
    setDocumentType(submitUserKycFormik.values.userKyc.documentType)
  }, [submitUserKycFormik.values.userKyc.documentType])

  const getAllAvailableAppsByUserId = async () => {
    // getAllAvailableAppsByUserId(newUserId).then((res) => {
    const cookie = getEncryptedCookie(cookieKeys.cookieUser)
    const url = `${process.env.REACT_APP_ACCOUNTS_APIBASEURL}${apiEndPoints.getAPPlicationsByUserId}${newUserId}`
    await axios
      .get(url, {
        headers: { Authorization: `Bearer ${cookie.token}` }
      })
      .then((res) => {
        dispatch(setLoading(false))
        const { apps } = res.data
        let userApps = apps?.map((item) => {
          // is access is true then the checkbox will be checked by default
          // if you make an access true onload then that particular checkbox will be checked.
          // for example portal is the default app for the user and It will always be checked.
          return {
            appId: item.appId,
            logoIcon: item.logoIcon,
            name: item.name,
            // if its portal then the access will be true by default
            access:
              item.appId === 'yPAwp5kqUbsZdql16dlZD7U1z'
                ? true
                : item?.access,
            isAvailable: item?.isAvailable
          }
        })
        setUserApps(userApps)
      })
  }

  /**
   * @user_permissions
   */
  // set user app permisions on component render
  useEffect(() => {
    // if we have the new users id then fetch its detail
    if (newUserId) {
      getAllAvailableAppsByUserId()
    }
    // eslint-disable-next-line
  }, [newUserId])
  //
  // user app permission formik state
  const appPermissionsFormik = useFormik({
    initialValues: {
      apps: [userApps],
      uid: ''
    },
    validationSchema: '',
    onSubmit: (values) => {
      dispatch(setLoading(true))
      const apiData = {
        uid: newUserId,
        apps: userApps.map((app) => {
          // return an object with appId and app.Access - It will alter the object
          // if access is true then It will be add otherwise it will be remove or as It is.
          return {
            appId: app?.appId,
            access:
              app?.access === true
                ? 'add'
                : app?.access === false
                ? 'remove'
                : app?.access,
            name: app?.name
          }
        })
      }
      addUpdateUserAppPermissions(apiData).then((response) => {
        dispatch(setLoading(false))
        toggleMemberUpdated()
        toastSuccess('User Permissions updated successfully')
        // send to thank you step
        setFormStep(formStep + 1)
      })
    }
  })

  /**
   * @user_update_logic
   */
  // set initial formik values if its an update request
  useEffect(() => {
    if (isUpdate) {
      ;(async function () {
        dispatch(setLoading(true))
        await getMemberDataById(updateUserId).then((res) => {
          Object.keys(res.data).forEach((key) => {
            newUserFormik.setFieldValue(key, res.data[key])
          })
        })
        await getMemberKyc(updateUserId).then((res) => {
          Object.keys(res.data).forEach((key) => {
            if (
              Object.keys(
                submitUserKycFormik.values.userKyc
              ).includes(key)
            ) {
              submitUserKycFormik.setFieldValue(
                'userKyc.' + key,
                res?.data?.[key]
              )
            }

            if (res.data?.submittedBy) {
              // this condition will check who submitted the kyc last time and as per that It will by default select the radio buttons
              // when user is about to update the kyc.
              res.data.submittedBy === 'org-kyc' &&
                setUserSubmitKyc({
                  user: false,
                  none: false,
                  approve: true
                })
              res.data.submittedBy === 'org' &&
                setUserSubmitKyc({
                  user: false,
                  none: true,
                  approve: false
                })
              res.data.submittedBy === 'user' &&
                setUserSubmitKyc({
                  user: true,
                  none: false,
                  approve: false
                })
            }
            if (res.data.isApproved === 1) {
              setIsKycApproved(true)
            }

            submitUserKycFormik.setFieldValue(
              'userKyc.kycDocumentFront',
              res.data?.documentFrontUrl
            )
            submitUserKycFormik.setFieldValue(
              'userKyc.kycDocumentBack',
              res.data?.documentBackUrl
            )
            submitUserKycFormik.setFieldValue(
              'userKyc.selfieWithDocument',
              res.data?.liveImageUrl
            )
          })
        })
        dispatch(setLoading(false))
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUserId])
  const {
    values,
    setFieldValue,
    errors,
    touched,
    handleSubmit,
    handleBlur
  } = newUserFormik

  useEffect(() => {
    if (userSubmitKyc?.user) {
      setKycButtonDisabled(false)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="custom-card-wrapper ">
        {/* if form is in step 1 and 2 don't show thank you page */}
        {formStep < 4 ? (
          <div className="user-management-popup">
            <div className="user-form">
              {/**
               * @user_details
               * @user_kyc_details
               */}
              {formStep < 3 && (
                <>
                  <h5 className="c-tx-primary f-700 mb-1">
                    User Basic Details
                  </h5>
                  <div className="detail-block">
                    <Form className="form-wrapper">
                      {/**
                       * @basic_details
                       */}
                      <Row>
                        <Col md="12" lg="4" xl="4">
                          <FormGroup className="">
                            <Label for="firstname">First Name</Label>
                            <Input
                              id="firstname"
                              name="first_name"
                              onBlur={handleBlur}
                              placeholder="ex. Jonathan"
                              type="text"
                              value={values.first_name}
                              onChange={(e) => {
                                setUserDetailButtonDisabled(false)
                                handleTrim(
                                  e.target.name,
                                  e.target.value,
                                  setFieldValue
                                )
                              }}
                              invalid={
                                Boolean(errors?.first_name) &&
                                Boolean(touched?.first_name)
                              }
                            />
                            <FormFeedback>
                              {errors?.first_name}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md="12" lg="4" xl="4">
                          <FormGroup className="">
                            <Label for="Last Name">Last Name</Label>
                            <Input
                              id="lastname"
                              name="last_name"
                              placeholder="ex. Doe"
                              type="text"
                              value={values.last_name}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setUserDetailButtonDisabled(false)
                                handleTrim(
                                  e.target.name,
                                  e.target.value,
                                  setFieldValue
                                )
                              }}
                              invalid={
                                Boolean(errors?.last_name) &&
                                Boolean(touched?.last_name)
                              }
                            />
                            <FormFeedback>
                              {errors?.last_name}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md="12" lg="4" xl="4">
                          <FormGroup className="">
                            <Label for="email">Email</Label>
                            <Input
                              id="email"
                              name="email"
                              placeholder="ex. jonathan@gmail.com"
                              type="text"
                              value={values.email}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setUserDetailButtonDisabled(false)
                                handleTrim(
                                  e.target.name,
                                  e.target.value,
                                  setFieldValue
                                )
                              }}
                              invalid={
                                Boolean(errors?.email) &&
                                Boolean(touched?.email)
                              }
                            />
                            <FormFeedback>
                              {errors?.email}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md="12" lg="4" xl="4">
                          <FormGroup className="">
                            <Label for="phonenumber">
                              Phone Number
                            </Label>
                            <Input
                              id="phonenumber"
                              name="phone"
                              placeholder="ex. +41 98765 43210"
                              type="number"
                              value={values.phone}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setUserDetailButtonDisabled(false)
                                handleTrim(
                                  e.target.name,
                                  e.target.value,
                                  setFieldValue
                                )
                              }}
                              invalid={
                                Boolean(errors?.phone) &&
                                Boolean(touched?.phone)
                              }
                            />
                            <FormFeedback>
                              {errors?.phone}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md="12" lg="4" xl="4">
                          <FormGroup className="">
                            <Label for="place">Place</Label>
                            <Input
                              id="place"
                              name="place"
                              placeholder="ex. Switzerland"
                              type="text"
                              value={values.place}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setUserDetailButtonDisabled(false)
                                handleTrim(
                                  e.target.name,
                                  e.target.value,
                                  setFieldValue
                                )
                              }}
                              invalid={
                                Boolean(errors?.place) &&
                                Boolean(touched?.place)
                              }
                            />
                            <FormFeedback>
                              {errors?.place}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md="12" lg="4" xl="4">
                          <FormGroup className="">
                            <Label for="Zipcode">Zipcode</Label>
                            <Input
                              id="Zipcode"
                              name="zipcode"
                              placeholder="ex. 001 001"
                              type="text"
                              onBlur={handleBlur}
                              value={values.zipcode}
                              onChange={(e) => {
                                setUserDetailButtonDisabled(false)
                                handleTrim(
                                  e.target.name,
                                  e.target.value,
                                  setFieldValue
                                )
                              }}
                              invalid={
                                Boolean(errors?.zipcode) &&
                                Boolean(touched?.zipcode)
                              }
                            />
                            <FormFeedback>
                              {errors?.zipcode}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md="12" lg="8">
                          <FormGroup className="">
                            <Label for="Address">Address</Label>
                            <Input
                              id="Address"
                              name="address"
                              placeholder="ex. Saint Street, Alberta"
                              type="text"
                              value={values.address}
                              onBlur={handleBlur}
                              onChange={(e) => {
                                setUserDetailButtonDisabled(false)
                                handleTrim(
                                  e.target.name,
                                  e.target.value,
                                  setFieldValue
                                )
                              }}
                              invalid={
                                Boolean(errors?.address) &&
                                Boolean(touched?.address)
                              }
                            />
                            <FormFeedback>
                              {errors?.address}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        {/* gender */}
                        <Col md="12" lg="4">
                          <FormGroup className="file-input-wrapper">
                            <Label for="Address">Gender</Label>
                            <div
                              className={`gender-wrapper ${
                                errors?.gender && touched?.gender
                                  ? ' is-invalid'
                                  : ''
                              }`}
                            >
                              {['Male', 'Female', 'other'].map(
                                (item, index) => {
                                  return (
                                    <div className="pg" key={index}>
                                      <label>
                                        <input
                                          type="radio"
                                          name={item}
                                          className="card-input-element"
                                          onChange={(event) => {
                                            setUserDetailButtonDisabled(
                                              false
                                            )
                                            event.target.checked &&
                                              setFieldValue(
                                                'gender',
                                                event.target.value
                                              )
                                          }}
                                          value={item}
                                          checked={
                                            values.gender === item
                                          }
                                        />
                                        <div
                                          className={`panel panel-default card-input ${
                                            errors?.gender &&
                                            touched?.gender
                                              ? 'border-red'
                                              : ''
                                          }`}
                                        >
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div className="bl-b-1 bl-b">
                                              <p className="mb-0 c-tx-primary f-700">
                                                {capitalizeFirstLetter(
                                                  item
                                                )}
                                              </p>
                                            </div>
                                            <div className="custom-radio-btn"></div>
                                          </div>
                                        </div>
                                      </label>
                                    </div>
                                  )
                                }
                              )}
                            </div>
                            <FormFeedback>
                              {errors?.gender}
                            </FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md="12" lg="4" xl="4">
                          <FormGroup className="mb-0 d-flex align-items-center justify-content-between">
                            <Label
                              className="mb-md-0"
                              for="First Name"
                            >
                              Status
                            </Label>
                            <div className="switch-block py-0">
                              <p className="mb-0 c-tx-ninth">
                                Inactive
                              </p>
                              <div className="switch-container">
                                <label>
                                  <input
                                    className="switch"
                                    type="checkbox"
                                    name="status"
                                    checked={
                                      values.status === '1'
                                        ? true
                                        : false
                                    }
                                    onClick={(e) => {
                                      setUserDetailButtonDisabled(
                                        false
                                      )
                                      setFieldValue(
                                        e.target.name,
                                        e.target.checked ? '1' : '0'
                                      )
                                    }}
                                  />
                                  <div>
                                    <div></div>
                                  </div>
                                </label>
                              </div>
                              <p className="c-tx-ninth mb-0">
                                Active
                              </p>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      {/**
                       * @user_Kyc
                       */}
                      {formStep === 2 && (
                        <>
                          <h5 className="c-tx-primary f-700 cb-20 ct-15 mb-0">
                            User KYC
                          </h5>
                          <div ref={userKycRef}></div>
                          <FormGroup
                            className="d-flex flex-wrap flex-lg-nowrap ml-20"
                            style={{ maxWidth: 700 }}
                          >
                            {/* user will submit kyc */}
                            <Label
                              className={`d-flex align-items-center ${
                                isKycApproved
                                  ? 'cursor-not-allowed'
                                  : 'cursor-pointer'
                              }`}
                            >
                              <Input
                                name="userKyc"
                                type="radio"
                                onChange={(event) => {
                                  // setKycButtonDisabled(false);
                                  if (event.target.checked) {
                                    setUserSubmitKyc({
                                      none: false,
                                      user: true,
                                      approve: false
                                    })
                                  }
                                }}
                                className="mt-0"
                                defaultChecked={userSubmitKyc?.user}
                                disabled={isKycApproved}
                              />
                              <p style={{ paddingLeft: 5 }}>
                                {' '}
                                Let the user submit kyc
                              </p>
                            </Label>
                            {/* approve user kyc */}
                            <Label
                              className={`d-flex align-items-center ${
                                isKycApproved
                                  ? 'cursor-not-allowed'
                                  : 'cursor-pointer'
                              }`}
                            >
                              <Input
                                name="userKyc"
                                type="radio"
                                onChange={(event) => {
                                  setKycButtonDisabled(false)
                                  if (event.target.checked) {
                                    setUserSubmitKyc({
                                      none: false,
                                      user: false,
                                      approve: true
                                    })
                                  }
                                }}
                                className="mt-0"
                                defaultChecked={
                                  userSubmitKyc?.approve
                                }
                                disabled={isKycApproved}
                              />
                              <p style={{ paddingLeft: 5 }}>
                                {' '}
                                Approve User Kyc
                              </p>
                            </Label>
                            {/* none */}
                            <Label
                              className={`d-flex align-items-center ${
                                isKycApproved
                                  ? 'cursor-not-allowed'
                                  : 'cursor-pointer'
                              }`}
                            >
                              <Input
                                name="userKyc"
                                type="radio"
                                onChange={(event) => {
                                  setKycButtonDisabled(false)
                                  if (event.target.checked) {
                                    setUserSubmitKyc({
                                      none: true,
                                      user: false,
                                      approve: false
                                    })
                                    scrollKycIntoView()
                                  }
                                }}
                                className="mt-0"
                                defaultChecked={userSubmitKyc?.none}
                                disabled={isKycApproved}
                              />
                              <p style={{ paddingLeft: 5 }}> None</p>
                            </Label>
                          </FormGroup>
                          {/* kyc form */}
                          {userSubmitKyc.none === true && (
                            <>
                              <Row>
                                {/* country of origin */}
                                <Col sm="6" className="">
                                  <FormGroup>
                                    <Label className="font-14 fw-bold mb-10">
                                      Country of Origin
                                    </Label>
                                    <Select
                                      options={
                                        serchableCountriesOptions
                                      }
                                      placeholder="Select country"
                                      className={`select-country font-14 color-black2 custom-select border-gray ${
                                        Boolean(
                                          submitUserKycFormik.errors
                                            ?.userKyc
                                            ?.countryOfOrigin &&
                                            submitUserKycFormik
                                              .touched?.userKyc
                                              ?.countryOfOrigin
                                        )
                                          ? 'is-invalid border-red'
                                          : ''
                                      }`}
                                      classNamePrefix="kycselect"
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          neutral50: '#4c4f53' // Placeholder color
                                        }
                                      })}
                                      styles={ReactSelectCustomstyle}
                                      onChange={(selectedOption) => {
                                        setKycButtonDisabled(false)
                                        submitUserKycFormik.setFieldValue(
                                          'userKyc.countryOfOrigin',
                                          selectedOption.value
                                        )
                                        // without settimeout formik will not update the errors object and It will show the error still
                                        setTimeout(() => {
                                          submitUserKycFormik.setFieldValue(
                                            'userKyc.countryOfResidence',
                                            selectedOption.value
                                          )
                                        }, 10)
                                      }}
                                      value={
                                        submitUserKycFormik.values
                                          .userKyc.countryOfOrigin
                                          ? {
                                              label:
                                                submitUserKycFormik
                                                  .values.userKyc
                                                  .countryOfOrigin,
                                              value:
                                                submitUserKycFormik
                                                  .values.userKyc
                                                  .countryOfOrigin
                                            }
                                          : ''
                                      }
                                    />
                                    <FormFeedback>
                                      {
                                        submitUserKycFormik.errors
                                          ?.userKyc?.countryOfOrigin
                                      }
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                {/* country of residence */}
                                <Col sm="6" className="mb-3 mb-md-0">
                                  <FormGroup>
                                    <Label className="font-14 fw-bold mb-10">
                                      Country of Residence
                                    </Label>
                                    <Select
                                      options={
                                        serchableCountriesOptions
                                      }
                                      placeholder="Select country"
                                      className={`select-country font-14 color-black2 custom-select border-gray ${
                                        Boolean(
                                          submitUserKycFormik.errors
                                            ?.userKyc
                                            ?.countryOfResidence &&
                                            submitUserKycFormik
                                              .touched?.userKyc
                                              ?.countryOfResidence
                                        )
                                          ? 'is-invalid border-red'
                                          : ''
                                      }`}
                                      classNamePrefix="kycselect"
                                      theme={(theme) => ({
                                        ...theme,
                                        colors: {
                                          ...theme.colors,
                                          neutral50: '#4c4f53' // Placeholder color
                                        }
                                      })}
                                      styles={ReactSelectCustomstyle}
                                      onChange={(selectedOption) => {
                                        setKycButtonDisabled(false)
                                        submitUserKycFormik.setFieldValue(
                                          'userKyc.countryOfResidence',
                                          selectedOption.value
                                        )
                                      }}
                                      value={
                                        submitUserKycFormik.values
                                          .userKyc.countryOfResidence
                                          ? {
                                              label:
                                                submitUserKycFormik
                                                  .values.userKyc
                                                  .countryOfResidence,
                                              value:
                                                submitUserKycFormik
                                                  .values.userKyc
                                                  .countryOfResidence
                                            }
                                          : ''
                                      }
                                    />
                                    <FormFeedback>
                                      {
                                        submitUserKycFormik.errors
                                          ?.userKyc
                                          ?.countryOfResidence
                                      }
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12" lg="4">
                                  <FormGroup>
                                    <Label for="documentType">
                                      Document Type
                                    </Label>
                                    <Input
                                      id="documentType"
                                      name="userKyc.documentType"
                                      type="select"
                                      onChange={(event) => {
                                        setKycButtonDisabled(false)
                                        submitUserKycFormik.setFieldValue(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }}
                                      invalid={
                                        Boolean(
                                          submitUserKycFormik.errors
                                            ?.userKyc?.documentType
                                        ) &&
                                        Boolean(
                                          submitUserKycFormik.touched
                                            ?.userKyc?.documentType
                                        )
                                      }
                                      value={
                                        submitUserKycFormik.values
                                          .userKyc.documentType
                                      }
                                    >
                                      <option
                                        value=""
                                        defaultValue
                                        disabled
                                      >
                                        Select Document Type
                                      </option>
                                      <option value="passport">
                                        Passport
                                      </option>
                                      <option value="driving-licence">
                                        Driving licence
                                      </option>
                                      <option value="identity-card">
                                        Identity Card
                                      </option>
                                      <option value="other">
                                        Other
                                      </option>
                                    </Input>
                                    <FormFeedback>
                                      {
                                        submitUserKycFormik.errors
                                          ?.userKyc?.documentType
                                      }
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                {submitUserKycFormik.values.userKyc
                                  .documentType === 'other' ? (
                                  <Col md="12" lg="4">
                                    <FormGroup>
                                      <Label
                                        for="documentName"
                                        className=""
                                      >
                                        Document Name
                                      </Label>
                                      <Input
                                        id="documentName"
                                        name="userKyc.documentName"
                                        placeholder="ex. Id Card"
                                        type="text"
                                        onChange={(event) => {
                                          setKycButtonDisabled(false)
                                          submitUserKycFormik.setFieldValue(
                                            event.target.name,
                                            event.target.value
                                          )
                                        }}
                                        invalid={
                                          Boolean(
                                            submitUserKycFormik.errors
                                              ?.userKyc?.documentName
                                          ) &&
                                          Boolean(
                                            submitUserKycFormik
                                              .touched?.userKyc
                                              ?.documentName
                                          )
                                        }
                                        value={
                                          submitUserKycFormik.values
                                            .userKyc.documentName
                                        }
                                      />
                                      <FormFeedback>
                                        {
                                          submitUserKycFormik.errors
                                            ?.userKyc?.documentName
                                        }
                                      </FormFeedback>
                                    </FormGroup>
                                  </Col>
                                ) : (
                                  ''
                                )}
                                <Col md="12" lg="4">
                                  <FormGroup>
                                    <Label
                                      for="documentNumber"
                                      className=""
                                    >
                                      Document Number
                                    </Label>
                                    <Input
                                      id="documentNumber"
                                      name="userKyc.documentNumber"
                                      placeholder="ex. AB234569120"
                                      type="text"
                                      onChange={(event) => {
                                        setKycButtonDisabled(false)
                                        if (
                                          event.target.value.trim() !==
                                          ''
                                        ) {
                                          submitUserKycFormik.setFieldValue(
                                            event.target.name,
                                            event.target.value
                                          )
                                        } else {
                                          submitUserKycFormik.setFieldValue(
                                            event.target.name,
                                            ''
                                          )
                                        }
                                      }}
                                      invalid={
                                        Boolean(
                                          submitUserKycFormik.errors
                                            ?.userKyc?.documentNumber
                                        ) &&
                                        Boolean(
                                          submitUserKycFormik.touched
                                            ?.userKyc?.documentNumber
                                        )
                                      }
                                      value={
                                        submitUserKycFormik.values
                                          .userKyc.documentNumber
                                      }
                                    />
                                    <FormFeedback>
                                      {
                                        submitUserKycFormik.errors
                                          ?.userKyc?.documentNumber
                                      }
                                    </FormFeedback>
                                  </FormGroup>
                                </Col>
                                {[
                                  'kycDocumentFront',
                                  'kycDocumentBack',
                                  'selfieWithDocument'
                                ].map((document, index) => {
                                  return (
                                    <Col md="12" lg="4" key={index}>
                                      <FormGroup className="file-input-wrapper">
                                        <Label
                                          for={document}
                                          className=""
                                        >
                                          {index === 0 &&
                                            'Document Front Image'}
                                          {index === 1 &&
                                            'Document Back Image'}
                                          {index === 2 &&
                                            'Selfie With Document'}
                                        </Label>
                                        <Input
                                          id={document}
                                          name={`userKyc.${document}`}
                                          placeholder="123.jpg"
                                          type="file"
                                          accept="image/*"
                                          className="d-none"
                                          onChange={(event) => {
                                            setKycButtonDisabled(
                                              false
                                            )
                                            submitUserKycFormik.setFieldValue(
                                              event.target.name,
                                              event.target.files?.[0]
                                            )
                                          }}
                                        />
                                        <Label htmlFor={document}>
                                          <div
                                            className={`position-relative ${
                                              Boolean(
                                                submitUserKycFormik
                                                  .errors?.userKyc?.[
                                                  document
                                                ]
                                              ) &&
                                              Boolean(
                                                submitUserKycFormik
                                                  .touched?.userKyc?.[
                                                  document
                                                ]
                                              )
                                                ? 'is-invalid border-red'
                                                : ''
                                            }`}
                                            ref={userKycRef}
                                          >
                                            <Input
                                              type="button"
                                              style={{
                                                cursor: 'auto'
                                              }}
                                              value={
                                                returnFormattedBigFileNames(
                                                  submitUserKycFormik
                                                    .values.userKyc?.[
                                                    document
                                                  ]?.name ||
                                                    submitUserKycFormik
                                                      .values
                                                      .userKyc?.[
                                                      document
                                                    ]
                                                ) || 'No file chosen'
                                              }
                                              invalid={
                                                Boolean(
                                                  errors?.userKyc?.[
                                                    document
                                                  ]
                                                ) &&
                                                Boolean(
                                                  touched?.userKyc?.[
                                                    document
                                                  ]
                                                )
                                              }
                                            />
                                            <div className="file-btn">
                                              <p className="cursor-pointer custom-link mb-0 f-400">
                                                Choose File
                                              </p>
                                            </div>
                                          </div>
                                          <FormFeedback className="fw-normal">
                                            {
                                              submitUserKycFormik
                                                .errors?.userKyc?.[
                                                document
                                              ]
                                            }
                                          </FormFeedback>
                                        </Label>
                                      </FormGroup>
                                    </Col>
                                  )
                                })}
                              </Row>
                            </>
                          )}
                        </>
                      )}
                    </Form>
                  </div>
                </>
              )}
              {/**
               * @app_permissions
               */}
              {formStep === 3 && (
                <>
                  <h5 className="c-tx-primary f-700 mb-1">
                    App Permission
                  </h5>
                  <div className="detail-block">
                    <Form className="form-wrapper">
                      <Row>
                        {userApps?.map((app, index) => {
                          return (
                            <Col
                              xl="2"
                              lg="3"
                              md="4"
                              sm="6"
                              key={index}
                            >
                              <Label
                                onClick={(event) => {
                                  setAppsButtonDisabled(false)
                                  // @ts-ignore
                                  let appsData = userApps
                                  appsData[index] = {
                                    // @ts-ignore
                                    ...appsData[index],
                                    access: event.target.checked
                                      ? true
                                      : false
                                  }
                                  setUserApps(appsData)
                                }}
                                className={`${
                                  app?.isAvailable
                                    ? app?.appId ===
                                        'yPAwp5kqUbsZdql16dlZD7U1z' ||
                                      app?.name === 'portal'
                                      ? 'disable'
                                      : ''
                                    : 'disable'
                                }`}
                              >
                                <input
                                  type="checkbox"
                                  name="product"
                                  className="card-input-check-element"
                                  defaultChecked={
                                    app?.name === 'portal'
                                      ? true
                                      : Boolean(
                                          userApps?.[index]?.access &&
                                            userApps?.[index]
                                              ?.isAvailable
                                        )
                                  }
                                />
                                <div
                                  // if the app is portal then the the checkbox will be checked by default,
                                  // and It will be disabled as the admin cannot remove portal permission from user, It will be default,
                                  className={`panel panel-default card-input-check ${
                                    app?.isAvailable
                                      ? app?.appId ===
                                          'yPAwp5kqUbsZdql16dlZD7U1z' ||
                                        app?.name === 'portal'
                                        ? 'disable'
                                        : ''
                                      : 'disable'
                                  }`}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="text-bl flex-column w-100 align-items-center">
                                      <div className="img-wrap-bl">
                                        <img
                                          src={app?.logoIcon}
                                          className="img-fluid"
                                          alt={app?.name}
                                        />
                                      </div>
                                      <div className="">
                                        <h6 className="f-700 c-tx-primary">
                                          {app?.name}
                                        </h6>
                                      </div>
                                    </div>
                                    <div className="custom-check-btn">
                                      <p className="text-white mb-0 checkmark-bl ">
                                        {Check}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </Label>
                            </Col>
                          )
                        })}
                      </Row>
                    </Form>
                  </div>
                </>
              )}
            </div>

            {/**
             * @buttons
             */}
            <div className="d-flex align-items-center justify-content-between w-100 mt-auto mb-2 mb-lg-0 order-1 order-lg-2">
              <div className="d-inline-flex">
                {/* <button className="custom-primary-outline ext-small " onClick={() => onClickBack()}>Back</button> */}
                {/* <button className="custom-primary ext-small " onClick={() => setFormStep(formStep - 1)}>Back</button> */}
              </div>
              <div className="d-inline-flex">
                {formStep === 1 && (
                  <button
                    disabled={UserDetailButtonDisabled}
                    className={`custom-primary ext-small ${
                      UserDetailButtonDisabled
                        ? 'cursor-not-allowed disabled-button'
                        : ''
                    }`}
                    onClick={() => {
                      handleSubmit()
                    }}
                  >
                    {!isLoading ? (
                      isUpdate ? (
                        'update'
                      ) : (
                        'Next'
                      )
                    ) : (
                      <Spinner
                        size="small"
                        className="text-white"
                        style={{ width: 15, height: 15 }}
                      />
                    )}
                  </button>
                )}
                {formStep === 2 && (
                  <button
                    // only disable
                    disabled={KycButtonDisabled}
                    className={`custom-primary ext-small ${
                      KycButtonDisabled
                        ? 'cursor-not-allowed disabled-button'
                        : ''
                    }`}
                    onClick={() => {
                      // if admin checked user will submit kyc then
                      // hide the modal onupdateclick
                      if (userSubmitKyc?.user === true && isUpdate) {
                        toggleAddUpdateModal()
                        return
                      }
                      // if user submit kyc is checkd then send admin to next screen
                      // else submit the kyc docs
                      if (userSubmitKyc.user === true) {
                        setFormStep(formStep + 1)
                      } else {
                        submitUserKycFormik.handleSubmit()
                      }
                    }}
                  >
                    {!isLoading ? (
                      isUpdate ? (
                        'update'
                      ) : (
                        'Next'
                      )
                    ) : (
                      <Spinner
                        size="small"
                        className="text-white"
                        style={{ width: 15, height: 15 }}
                      />
                    )}
                  </button>
                )}
                {formStep === 3 && (
                  <button
                    disabled={appsButtonDisabled}
                    className={`custom-primary ext-small ${
                      appsButtonDisabled
                        ? 'cursor-not-allowed disabled-button'
                        : ''
                    }`}
                    onClick={() => {
                      appPermissionsFormik.handleSubmit()
                    }}
                  >
                    {!isLoading ? (
                      'Save'
                    ) : (
                      <Spinner
                        size="small"
                        className="text-white"
                        style={{ width: 15, height: 15 }}
                      />
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="user-management-popup">
            <ThankYou />
          </div>
        )}
      </div>
    </>
  )
}

export default AddNewUser

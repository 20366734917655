import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const listFolderAPI = (data: IlistFolderAPIData): Promise<IlistFolderAPI> => {
  return HTTPService.get(apiEndPoints.universeAPI.folderTemplate.list, data);
};

interface IlistFolderAPIData {
  limit: number;
  page: number;
}

export interface IlistFolderAPIResData {
  id: string;
  name: string;
}

interface IlistFolderAPI {
  message: string;
  status: number;
  data: {
    count: number;
    data: IlistFolderAPIResData[];
  };
}

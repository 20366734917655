import { InetworkInfoAPIResData } from "actions/networkInfoAPI";
import { getDecryptedLocalStorage } from "utils/commonFunctions";
import { localStorageKeys } from "utils/constants";
import { networkType, peerType } from "./type";

const initialState: InetworkInfoAPIResData = getDecryptedLocalStorage(localStorageKeys.networkInfo);

const networkReducer = (
  state = initialState,

  action: { type: ""; payload: InetworkInfoAPIResData }
) => {
  let newState = { ...state };
  switch (action.type) {
    case networkType.setNetworkData:
      newState = { ...newState, ...action.payload };
      break;
    case peerType.peerData:
      newState.peerData = action.payload;
      break;
  }
  return newState;
};

export { networkReducer };

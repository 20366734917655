import { InetworkInfoAPIResData } from "actions/networkInfoAPI";
import { combineReducers } from "redux";
import authReducer, { IAuth } from "./auth/reducer";
import channelDataReducer from "./channelData/reducer";
import confirmationModelReducer from "./confirmationModule/reducer";
import { GlobalSettingReducer, IGlobalSettingReducer } from "./globalSettingReducer/reducer";
import { loadingReducer } from "./loader/reducer";
import { networkReducer } from "./networkReducer/reducer";
import NoticationReducer, { INoticationReducer } from "./notification/reducer";
import peerReducer, { IPeerReducer } from "./peerData/reducer";
import { IFirstTimeTourReducer, firstTimeTourReducer } from "./reactour/reducer";
import { scrollingReducer } from "./scrolling/reducer";
import securityReducer from "./security/reducer";
import universeSidebarReducer, { IUniverseSidebar } from "./sidebar/reducer";
import TemplateReducer, { ITemplateReducer } from "./templateReducer/reducer";
import { toastReducer } from "./toast/reducer";
import userDetailsReducer from "./user/reducer";

const rootReducer = combineReducers({
  auth: authReducer,
  loading: loadingReducer,
  toaster: toastReducer,
  userDetails: userDetailsReducer,
  securityDetails: securityReducer,
  scrollingReducer: scrollingReducer,
  confirmationModel: confirmationModelReducer,
  TemplateReducer: TemplateReducer,
  NetworkReducer: networkReducer,
  NoticationReducer: NoticationReducer,
  firstTimeTourReducer: firstTimeTourReducer,
  universeSidebarReducer: universeSidebarReducer,
  GlobalSettingReducer: GlobalSettingReducer,
  channelDataReducer: channelDataReducer,
  peerReducer: peerReducer
});

export default rootReducer;

export interface IRootReducer {
  auth: IAuth;
  loading: any;
  toaster: any;
  userDetails: any;
  securityDetails: any;
  scrollingReducer: any;
  confirmationModel: any;
  CommonConfirmationModal: any;
  TemplateReducer: ITemplateReducer;
  NetworkReducer: InetworkInfoAPIResData;
  NoticationReducer: INoticationReducer;
  firstTimeTourReducer: IFirstTimeTourReducer;
  universeSidebarReducer: IUniverseSidebar;
  GlobalSettingReducer: IGlobalSettingReducer;
  channelDataReducer: any;
  peerReducer: IPeerReducer;
}

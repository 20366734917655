import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getMemberDataById = (userId: string): Promise<IMemberDataById> => {
  return HTTPService.get(apiEndPoints.members + "/" + userId);
};

interface IMember {
  address: "700 Oak Street, Brockton MA 2301";
  first_name: "kyc rajesh";
  gender: "";
  id: 974;
  last_name: "sdfgsfd";
  phone: "04903849384";
  place: "india";
  status: 1;
  userId: "13c85bf5-d22a-4c78-89c5-32e95a989f6f";
  zipcode: "938493849";
}
export interface IMemberDataById {
  message: string;
  status: number;
  user: IMember;
}

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getMemberKyc = (userId: string): Promise<IMemberKyc> => {
  return HTTPService.get(apiEndPoints.kycList + "/" + userId);
};

export interface IKyc {
  firstName: string;
  lastName: string;
  email: string;
  countryOfOrigin: string;
  countryOfResidence: string;
  createdAt: string;
  documentBackUrl: string;
  documentFrontUrl: string;
  documentMimeType: string;
  documentName: string;
  documentNumber: string;
  documentType: string;
  gender: "male" | "female" | "other";
  id: number;
  isApproved: number;
  liveImageMimeType: string;
  liveImageName: string;
  liveImageUrl: string;
  orgId: string;
  statusReason: string;
  updatedAt: string;
  userId: string;
}

export interface IMemberKyc {
  data: IKyc;
  message: string;
  status: number;
}

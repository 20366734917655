import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThankYou } from "components/ThankyouPage";
import { FormikProps, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "store/loader/action";
import { setUserInfo } from "store/user/action";
import { addNewUniverseRequest } from "utils/common/helper/addNewUniverseRequest";
import { getNetworkDomains, INetworkDomain } from "utils/common/helper/get-network-domains";
import { toastError, toastSuccess } from "utils/commonFunctions";
import { validationRegex } from "utils/constants";
import * as Yup from "yup";
import { StepperFirstTab } from "./StepperFirstTab";
import { StepperLink } from "./StepperLink";
import { SteppersecondTab } from "./SteppersecondTab";
import { StepperSettingTab } from "./StepperSettingTab";
import { StepperThirdTab } from "./StepperThirdTab";

const Check = <FontAwesomeIcon icon={faCheck} />;

export function CustomStepper({ toggle }: any) {
  const dispatch = useDispatch();
  /**
   * @stepper
   * @state_manage
   */
  const [currentStep, setCurrentStep] = useState(0);
  const handleNextClick = () => {
    if (currentStep === 4) return;
    setCurrentStep(currentStep + 1);
  };
  const handleBackClick = () => {
    if (currentStep === 0) return;
    setCurrentStep(currentStep - 1);
  };

  /**
   * @formik
   *
   */
  const [requestButtonClicked, setRequestButtonClicked] = useState(false);
  const edexaUniverseValidationSchema = Yup.object().shape({
    enviornmentDetails: Yup.object({
      name: Yup.string()
        .max(26, "Name should not exceed 26 characters limit")
        .min(3, "Name must be at least 3 characters long")
        .matches(validationRegex.onlyCharacterNoSpaceRegex, "Only Alphabet are Allowed")
        .required("Name is Required"),
      cloudType: Yup.string().required("Cloud Type is Required")
    }),
    networkDetails: Yup.object({
      name: Yup.string()
        .max(26, "Name should not exceed 26 characters limit")
        .min(3, "Name must be at least 3 characters long")
        .matches(validationRegex.onlyCharacterNoSpaceRegex, "Only Alphabet are Allowed")
        .required("Name is Required"),
      missionStatement: Yup.string().required("Mission Statement is Required")
    }),
    homeRegion: Yup.string().required("Home Region is Required"),
    providerSettings: Yup.object({
      provider: Yup.string().required("Provider is Required"),
      consensusAlgorithm: Yup.string().required("Consensus Alogrithm is Required")
    })
  });
  const edexaUniverseFormik = useFormik({
    initialValues: {
      enviornmentDetails: {
        name: "",
        cloudType: ""
      },
      networkDetails: {
        name: "",
        missionStatement: ""
      },
      homeRegion: "",
      providerSettings: {
        provider: "Hyperledger Fabric",
        consensusAlgorithm: "Raft"
      }
    },
    validationSchema: edexaUniverseValidationSchema,
    onSubmit: (universeValues) => {
      if (!requestButtonClicked) return;
      const makeAPIParams = {
        envName: universeValues.enviornmentDetails.name,
        type: universeValues.enviornmentDetails.cloudType,
        networkName: universeValues.networkDetails.name,
        networkDescription: universeValues.networkDetails.missionStatement,
        region: universeValues.homeRegion,
        provider: universeValues.providerSettings.provider,
        algorithmType: universeValues.providerSettings.consensusAlgorithm
      };
      dispatch(setLoading(true));
      addNewUniverseRequest(makeAPIParams).then((response) => {
        setRequestButtonClicked(false);
        dispatch(setLoading(false));
        dispatch(setUserInfo(true));
        toastSuccess(response.message);
        handleNextClick();
      });
    }
  });

  const { errors, touched, setFieldValue } = edexaUniverseFormik;

  // set network domains
  const [networkDomains, setNetworkDomains] = useState<INetworkDomain[]>([]);
  useEffect(() => {
    getNetworkDomains()
      .then((response) => {
        setNetworkDomains(response.data.data);
        // set default enviornment cloud type
        setFieldValue("enviornmentDetails.cloudType", response.data.data.find((item) => item.default)?.id || "");
      })
      .catch((err) => {
        toastError(err.message);
      });

    return () => {}; // eslint-disable-next-line
  }, []);

  const hideTopCloseButton = `
  .thankYouCloseButtonHide{display: none}
  `;

  return (
    <>
      {/**
       * @steps
       */}
      {currentStep !== 4 && (
        <div className="stepper-link-wrapper">
          <ul className="list-step">
            <StepperLink title="Environment Type" status={currentStep === 0 && "active"} count="1" complete={currentStep > 0} />
            <StepperLink title="Protocol" status={currentStep === 1 && "active"} count="2" complete={currentStep > 1} />
            <StepperLink title="Environment Details" status={currentStep === 2 && "active"} count="3" complete={currentStep > 2} />
            <StepperLink title="Settings" status={currentStep === 3 && "active"} count="4" complete={currentStep > 3} />
          </ul>
        </div>
      )}

      {/**
       * @form
       */}
      <div className="custom-card-wrapper">
        <div className="tab-1 tab-block h-100">
          {/**
           * @left_side
           * @stepper_form
           */}

          <div className={`left-block h-100 ${currentStep === 4 && "w-100"}`}>
            {currentStep === 0 && <StepperFirstTab formik={edexaUniverseFormik} currentStep={currentStep} networkDomains={networkDomains} />}
            {currentStep === 1 && <SteppersecondTab formik={edexaUniverseFormik} currentStep={currentStep} />}
            {currentStep === 2 && <StepperThirdTab formik={edexaUniverseFormik} currentStep={currentStep} />}
            {currentStep === 3 && <StepperSettingTab formik={edexaUniverseFormik} currentStep={currentStep} />}
            {/* close icon on last screen */}
            {currentStep === 4 && (
              <FontAwesomeIcon
                onClick={() => toggle()}
                style={{
                  width: 20,
                  height: 20,
                  marginLeft: "auto",
                  cursor: "pointer"
                }}
                icon={faTimesCircle}
              />
            )}
            {currentStep === 4 && (
              <>
                <ThankYou />
                <style>{hideTopCloseButton}</style>
              </>
            )}

            {/* buttons */}
            <div className="d-flex align-items-center justify-content-between w-100 mt-auto mb-2 mb-lg-0 order-1 order-lg-2">
              {currentStep !== 4 && (
                <div className="d-inline-flex">
                  <button
                    className={`ext-small ${currentStep === 0 ? "custom-primary  disable" : "custom-primary-outline"}`}
                    onClick={() => handleBackClick()}
                    disabled={currentStep === 0}
                    type="button">
                    Back
                  </button>
                </div>
              )}
              <div className="d-inline-flex">
                {currentStep === 0 && (
                  <button
                    type="button"
                    className="custom-primary ext-small "
                    onClick={() => {
                      edexaUniverseFormik.handleSubmit();
                      // check if there is no errors in first step then handle next
                      if (Boolean(touched?.enviornmentDetails?.name)) {
                        if (!errors?.enviornmentDetails) {
                          // set next step touched false so error won't show up while we visit first time
                          edexaUniverseFormik.setFieldTouched("networkDetails.name", false);
                          edexaUniverseFormik.setFieldTouched("networkDetails.missionStatement", false);
                          handleNextClick();
                        }
                      }
                    }}>
                    Next
                  </button>
                )}
                {currentStep === 1 && (
                  <button
                    type="button"
                    className="custom-primary ext-small"
                    onClick={() => {
                      edexaUniverseFormik.handleSubmit();
                      // check if there is no errors in first step then handle next
                      if (Boolean(touched?.networkDetails?.name)) {
                        if (!errors?.networkDetails) {
                          edexaUniverseFormik.setFieldTouched("homeRegion", false);
                          handleNextClick();
                        }
                      }
                    }}>
                    Next
                  </button>
                )}
                {currentStep === 2 && (
                  <button
                    type="button"
                    className="custom-primary ext-small"
                    onClick={() => {
                      edexaUniverseFormik.handleSubmit();
                      // check if there is no errors in first step then handle next
                      if (Boolean(touched?.homeRegion)) {
                        if (!errors?.homeRegion) {
                          edexaUniverseFormik.setFieldTouched("providerSettings.provider", false);
                          edexaUniverseFormik.setFieldTouched("providerSettings.consensusAlgorithm", false);
                        }
                      }
                      handleNextClick();
                    }}>
                    Next
                  </button>
                )}
                {currentStep === 3 && (
                  <button
                    type="button"
                    className="custom-primary ext-small"
                    onClick={() => {
                      setRequestButtonClicked(true);
                      edexaUniverseFormik.handleSubmit();
                      // check if there is no errors in first step then handle next
                      // if (Boolean(touched?.providerSettings?.provider)) {
                      //     if (!errors?.providerSettings) {

                      //     }
                      // }
                    }}>
                    REQUEST
                  </button>
                )}
              </div>
            </div>
          </div>

          {/**
           * @right_side
           * @messages
           */}
          {currentStep !== 4 && (
            <div className="right-block">
              <div className="flex-bl">
                {/* tab 1 right portion start */}
                {currentStep === 0 && (
                  <>
                    <h5 className="tab-heading c-tx-primary f-700 ">Managing your own infrastructure.</h5>
                    <p className="c-tx-ninth mb-2 small">Your environment contains :</p>
                    <div className="points-wrapper h-auto">
                      <ul className="point-list">
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">{Check}</p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">A firewall isolated network</p>
                        </li>
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">{Check}</p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">The core Blockchain technology</p>
                        </li>
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">{Check}</p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">Full-stack of services for your Private ecosystem</p>
                        </li>
                      </ul>
                    </div>
                    <p className="c-tx-ninth mb-0 small lh-18">
                      The network you’ll deploy will include one organization, owning one peer, and a single ordering service organization that owns a single ordering node.
                    </p>
                    {/* <div className="ct-link">
                      <a
                        href="https://edexa.network"
                        target={"_blank"}
                        rel="noreferrer"
                        className="mb-0 readmore small f-700"
                      >
                        Learn more{" "}
                      </a>
                    </div> */}
                  </>
                )}
                {/* tab 1 right portion end */}

                {/* tab 2 right portion start */}
                {currentStep === 1 && (
                  <>
                    <h5 className="tab-heading c-tx-primary f-700 ">Network Summary</h5>
                    <p className="c-tx-ninth mb-2 small lh-18">
                      A network or "consortium" is a business network comprised of a group of organizations and/or multiple members who have a shared business goal. At this step, you are tasked with
                      supplying the following pieces of configurations:
                    </p>
                    <div className="points-wrapper h-auto">
                      <ul className="point-list">
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">{Check}</p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">Name - Identity of the consortia</p>
                        </li>
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">{Check}</p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">Mission - Optional prose or legalese describing business purpose, bylaws, objectives, etc.</p>
                        </li>
                      </ul>
                    </div>
                    <p className="c-tx-ninth mb-0 small lh-18">
                      All operations are in this tier are off-chain, and include: cloud + region whitelisting membership creation, certificate management, governance, and network auditing.
                    </p>
                    {/* <div className="ct-link">
                      <a
                        href="https://edexa.network"
                        target={"_blank"}
                        rel="noreferrer"
                        className="mb-0 readmore small f-700"
                      >
                        Learn more{" "}
                      </a>
                    </div> */}
                  </>
                )}
                {/* tab 2 right portion end */}
                {/* tab 3 right portion start */}

                {currentStep === 2 && (
                  <>
                    <h5 className="tab-heading c-tx-primary f-700 ">What is a Home Region ?</h5>
                    <p className="c-tx-ninth mb-2 small lh-18">
                      The home region is the cloud provider and underlying region (such as membership details) is stored. The available cloud provider at AWS.
                    </p>
                    {/* <div className="points-wrapper h-auto">
                      <ul className="point-list">
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">
                              {Check}
                            </p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">AWS</p>
                        </li>
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">
                              {Check}
                            </p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">
                            Microsoft Azure
                          </p>
                        </li>
                      </ul>
                    </div> */}
                    <p className="c-tx-ninth mb-0 small lh-18">Additional regions and cloud providers will be available soon.</p>
                    {/* <div className="ct-link">
                      <a
                        href="https://edexa.network"
                        target={"_blank"}
                        rel="noreferrer"
                        className="mb-0 readmore small f-700"
                      >
                        Learn more{" "}
                      </a>
                    </div> */}
                  </>
                )}
                {/* tab 3 right portion end */}

                {/* tab 4 right portion start */}
                {currentStep === 3 && (
                  <>
                    {" "}
                    <h5 className="tab-heading c-tx-primary f-700 ">Minimum Recommended Configuration</h5>
                    <p className="c-tx-ninth mb-2 small lh-18">For reliable operation of your blockchain, edeXa strongly recommends a minimum of 3 orderer nodes for every channel :</p>
                    <div className="points-wrapper h-auto">
                      <ul className="point-list">
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">{Check}</p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">2 or more user-provided orderer nodes + system monitor node, or</p>
                        </li>
                        <li>
                          <div className="icon">
                            <p className="c-tx-tenth mb-0 small lh-18">{Check}</p>
                          </div>
                          <p className="c-tx-tenth mb-0 small lh-18">3 or more user-provided orderer nodes</p>
                        </li>
                      </ul>
                    </div>
                    {/* <p className="c-tx-ninth mb-0 small lh-18">
                      The monitor node is free and automatically a part of all
                      environments.
                    </p> */}
                    {/* <div className="ct-link">
                      <a
                        href="https://edexa.network"
                        target={"_blank"}
                        rel="noreferrer"
                        className="mb-0 readmore small f-700"
                      >
                        Learn more{" "}
                      </a>
                    </div> */}
                  </>
                )}
                {/* tab 4 right portion end */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

// formik initial values
type valuesErrorsTouched = {
  enviornmentDetails: {
    name: string;
    cloudType: string;
  };
  networkDetails: {
    name: string;
    missionStatement: string;
  };
  homeRegion: string;
  providerSettings: {
    provider: string;
    consensusAlgorithm: string;
  };
};
// formik type
export type StepperStepsPropsType = {
  formik: FormikProps<valuesErrorsTouched>;
  currentStep: number;
  networkDomains?: INetworkDomain[];
};

import { useState } from 'react'
import Tour from 'reactour'
import { FormGroup, Label } from 'reactstrap'
export function StepperFirstTab({
  formik,
  networkDomains,
  setQRCode,
  QRcode,
  handleNextClick
}: any) {
  const { values, errors, setFieldValue, touched, handleSubmit } =
    formik
  const [isTourOpen, setTourOpen] = useState(false)
  const tourFirstConfig = [
    {
      target: '.login-link',
      content: (
        <div className="reactour-text-container font-14">
          <p>
            <p>
              Navigate to the two-factor authentication section to
              enhance the security of your account.
            </p>
            <b>
              Discover and explore the private account features
              offered by Edexa
            </b>
            <br />
          </p>
          <a href="https://accounts.io-world.com/security">
            https://accounts.io-world.com/security
          </a>
        </div>
      )
    }
  ]

  const closeTour = () => {
    setTourOpen(false)
  }
  return (
    <>
      <div className="flex-wrap-bl order-2 order-lg-1">
        <Tour
          startAt={0}
          onRequestClose={closeTour}
          steps={tourFirstConfig}
          highlightedMaskClassName="masked-highlight"
          isOpen={isTourOpen}
          className={'reactour_touristGuide '}
          maskSpace={0}
          closeWithMask={false}
          rounded={0}
          accentColor="#073D83"
          disableFocusLock={false}
          disableInteraction={true}
          disableDotsNavigation={true}
          lastStepNextButton={
            <span
              onClick={() => {
                closeTour()
              }}
            >
              Finish
            </span>
          }
        />
        <h5 className="tab-heading c-tx-primary f-700">
          edeXa Business Blockchain Deployment Wizard
        </h5>
        <span
          style={{
            color: ' grey',
            marginBottom: '15px',
            marginTop: ' -5px'
          }}
        >
          Build your own Private Blockchain ecosystem with easy steps
        </span>
        <FormGroup className="m-0">
          <Label for="exampleEmail">2FA - Google Authenticator</Label>
          <button
            className="custom-primary"
            type="button"
            style={{
              width: '30%',
              height: '45px',
              fontSize: 'smaller'
            }}
            onClick={() => {
              setTourOpen(true)
            }}
          >
            Start Setup 2FA
          </button>
        </FormGroup>
        <span
          style={{
            color: 'grey',
            marginBottom: '15px',
            marginTop: '-5px',
            cursor: 'pointer'
          }}
          onClick={() => handleNextClick()}
        >
          Skip For Now
        </span>
      </div>
    </>
  )
}

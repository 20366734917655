import React, { useState } from 'react'
import DummyHeader from '../DummyHeader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Success from '../../../../assets/Images/success.svg'
import { FormFeedback } from 'reactstrap'

const ThirdSection = ({ handleNextClick }: any) => {
  const [flow, setFlow] = useState(0)

  const FormikState = useFormik({
    initialValues: {
      organizationName: '',
      channelName: ''
    },
    // validationSchema: Yup.object({
    //   organizationName: Yup.string()
    //     .required('Organization Name is required')
    //     .matches(/^\S+$/, 'Organization Name not allow to space')
    //     .min(3, 'Organization Name must be at least 3 characters')
    //     .max(50, 'Organization Name must be at most 50 characters'),
    //   channelName: Yup.string()
    //     .required('Channel Name is required')
    //     .matches(/^\S+$/, 'Channel Name not allow to space')
    //     .min(3, 'Channel Name must be at least 3 characters')
    //     .max(50, 'Channel Name must be at most 50 characters')
    // }),
    onSubmit: (values) => {
      localStorage.setItem(
        'channelName',
        JSON.stringify([values?.channelName])
      )
      localStorage.setItem(
        'organizationName',
        values?.organizationName
      )
    }
  })

  const {
    handleSubmit,
    values,
    errors,
    setFieldValue,
    handleBlur,
    touched
  } = FormikState

  const handleNext = () => {
    if (values?.organizationName === '') {
      FormikState.setFieldError(
        'organizationName',
        'OrganizationName is Required'
      )
    } else if (
      values?.channelName === '' &&
      values?.organizationName !== '' &&
      flow === 1
    ) {
      FormikState.setFieldError(
        'channelName',
        'ChannelName is Required'
      )
    }
    if (values?.organizationName !== '') {
      setFlow(1)
      if (values?.channelName !== '') {
        setFlow(2)
        handleSubmit()
        setTimeout(() => {
          handleNextClick()
        }, 1000)
      }
    }
  }

  return (
    <>
      <DummyHeader />
      <div className="section section-custom-main">
        <div className="container-main">
          <div className="div-main">
            <div
              className="div-sub"
              style={{ alignItems: 'flex-start', width: '85vw' }}
            >
              <h1 className="div-text">
                edeXa Blockchain Deployment Wizard
              </h1>
              <p className="div-desc">
                Expand rapidly, scale efficiently, and ensure
                steadfast security.
              </p>
              <h5 className="div-input-text">Organization name</h5>
              <div className="org-name">
                <div>
                  <input
                    type="text"
                    name="organizationName"
                    className="org-input-field"
                    placeholder="Type organization name"
                    value={values?.organizationName}
                    onChange={(e) =>
                      setFieldValue(
                        'organizationName',
                        e.target.value
                      )
                    }
                    onBlur={handleBlur}
                  />
                  {touched?.organizationName &&
                    !errors?.organizationName &&
                    values?.organizationName && (
                      <img
                        src={Success}
                        loading="lazy"
                        alt="Logo"
                        className="success-icon"
                      />
                    )}
                </div>
                <FormFeedback>
                  {errors?.organizationName}
                </FormFeedback>
              </div>

              {(flow === 1 || flow === 2) && (
                <>
                  <div className="create-channel">
                    <h5 className="div-input-text">
                      Create your channel
                    </h5>
                    <div>
                      <input
                        type="text"
                        name="channelName"
                        className="channel-input-field"
                        placeholder="Type channel name"
                        value={values?.channelName}
                        onChange={(e) =>
                          setFieldValue('channelName', e.target.value)
                        }
                        onBlur={handleBlur}
                      />
                      {touched?.channelName &&
                        !errors?.channelName &&
                        values?.channelName && (
                          <img
                            src={Success}
                            loading="lazy"
                            alt="Logo"
                            className="success-icon"
                          />
                        )}
                    </div>
                    <FormFeedback>{errors?.channelName}</FormFeedback>
                  </div>
                </>
              )}
              {flow === 2 && (
                <p className="org-paragraph">
                  Deploy your ecosystem on edeXa Master Channel
                </p>
              )}

              <button
                className="div-next-button"
                onClick={() => handleNext()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="div-footer">
        <button className="third-footer-button">
          {flow === 0 &&
            `Set Up the Organization is your first step. Decide on the organization
          structure for your network. Define the names and roles of the
          organizations involved. Each organization may represent a participant
          or stakeholder in your private blockchain. In this free plan you can
          only add 1 organization. In case you can always scale later.`}
          {flow === 1 &&
            `Organization name is set – fantastic move! Now, let's elevate your private Blockchain experience. Time to create a channel.`}
          {flow === 2 &&
            `🚀 Channel created! 🌐✨ Now, it's deployment time for your ecosystem. Click on "Deploy" and watch your vision come to life!  #BlockchainAdventure`}
        </button>
      </div>
    </>
  )
}

export default ThirdSection

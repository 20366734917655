import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const viewChannelAPI = (id: string): Promise<IviewChannelAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.channelManagement.view + id);
};

export interface IviewChannelAPIResData {
  createdAt: string;
  id: string;
  invitationSentTo: {
    name: string;
    email: string;
  };
  status: number;
}

interface IviewChannelAPIRes {
  status: number;
  message: string;
  data: {
    count: number;
    data: IviewChannelAPIResData[];
  };
}

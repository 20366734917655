import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const uploadSmartContractAPI = (params: FormData): Promise<IUploadSmartContractType> => {
  return HTTPService.post(apiEndPoints.universeAPI.contracts.upload, params);
};

// interface IContractUploadParams {
//   name: string;
//   description: string;
//   language: "node" | "go";
//   protocol: string;
//   file: File;
// }

// Generated by https://quicktype.io

export interface IUploadSmartContractType {
  status: number;
  message: string;
  data: Data;
}

export interface Data {
  file: string;
  status: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  description: string;
  protocol: string;
  language: string;
  id: string;
  userId: string;
  deletedAt: null;
}

import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCertificateListAPI, getCertificateListAPIResData } from "actions/getCertificateListAPI";
import BadgeBlock from "components/badgeBlocks/BadgeBlock";
import LoadingSpinner from "components/loader/LoadingSpinner";
import { NoFoundDiv } from "components/notFoundPages";
import NoMoreData from "components/notFoundPages/NoMoreData";
import React, { memo, useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { getFormattedDate, toastSuccess } from "utils/commonFunctions";

const copy = <FontAwesomeIcon icon={faCopy} />;

const CertificateAuthoritiesListComponent = () => {
  const dispatch = useDispatch();

  const [certificateList, setCertificateList] = useState<getCertificateListAPIResData[]>([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 4,
    totalCount: 0
  });
  const [historyLoading, setHistoryLoading] = useState(false);

  const networkInfo = useSelector((state: IRootReducer) => state.NetworkReducer);

  const getCertificateListAction = useCallback(() => {
    setHistoryLoading(true);
    dispatch(setLoading(true));
    getCertificateListAPI({
      page: pagination.page,
      limit: pagination.limit
    })
      .then((res) => {
        if (res.status === 200) {
          setCertificateList([...certificateList, ...res.data.data]);
          setPagination({ ...pagination, totalCount: res.data.count });
        }
        setHistoryLoading(false);
        dispatch(setLoading(false));
      })
      .catch((err) => setHistoryLoading(false));
  }, [certificateList, dispatch, pagination]);

  useEffect(() => {
    getCertificateListAction();
    // eslint-disable-next-line
  }, [pagination.page]);

  // load next page data from api
  const incrementPage = useCallback(() => {
    setPagination({
      ...pagination,
      page: pagination.page + 1
    });
  }, [pagination]);

  const handleCopyTransaction = (text?: string) => {
    if (text) {
      window.navigator.clipboard.writeText(text).then(() => {
        toastSuccess("Copied to Clipboard");
      });
    }
  };

  return (
    <div className="backdrop-block">
      <div className="title">
        <h5 className="c-tx-primary f-600 mb-10">Certificate Authorities {pagination.totalCount > 0 && <span className="c-tx-ninth f-400">({pagination.totalCount})</span>}</h5>
      </div>
      <ul id="Certificate-Authorities" className="wrapper-block peer-management order-management">
        <InfiniteScroll
          dataLength={certificateList?.length}
          next={incrementPage}
          hasMore={pagination.totalCount > certificateList?.length}
          loader={<LoadingSpinner size="sm" />}
          scrollableTarget="Certificate-Authorities"
          endMessage={certificateList.length > 0 && <NoMoreData />}>
          {certificateList.length > 0 ? (
            <>
              {certificateList.map((data: getCertificateListAPIResData, i: number) => (
                <React.Fragment key={i}>
                  <li className="element" key={i}>
                    <div className="block-1">
                      <p className="c-tx-primary f-700 first-title">Status</p>
                    </div>
                    {data?.status === 1 && (
                      <div className="block-2 align-items-center">
                        <div>
                          <BadgeBlock name="LOCKED" theme="dark" />
                        </div>
                      </div>
                    )}
                  </li>
                  <li className="element">
                    <div className="block-1">
                      <p className="c-tx-primary f-700 first-title">ID</p>
                      <p className="c-tx-ninth">{data?.id}</p>
                    </div>
                  </li>
                  <li className="element">
                    <div className="block-1">
                      <p className="c-tx-primary f-700 first-title">Membership</p>
                      <p className="c-tx-ninth">{data?.membership}</p>
                    </div>
                  </li>
                  <li className="element">
                    <div className="block-1">
                      <p className="c-tx-primary f-700 first-title">Creation Date</p>
                      <p className="c-tx-ninth">{getFormattedDate(data?.createdAt)}</p>
                    </div>
                  </li>
                  <li className="element">
                    <div className="block-1">
                      <p className="c-tx-primary f-700 first-title">Certificate Authority URL</p>
                      <Link className="c-tx-ninth" to="#">
                        {networkInfo?.certAuthUrl || "-"}
                      </Link>
                    </div>
                    <div className="icons cursor-pointer" onClick={() => handleCopyTransaction(networkInfo?.certAuthUrl)}>
                      {copy}
                    </div>
                  </li>
                </React.Fragment>
              ))}
            </>
          ) : (
            <NoFoundDiv />
          )}
        </InfiniteScroll>
        {historyLoading ? <LoadingSpinner size="sm" /> : null}
      </ul>
    </div>
  );
};

export default memo(CertificateAuthoritiesListComponent);

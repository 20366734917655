import "../../src/../styles/home/home.scss";
import { StepperStepsPropsType } from "./Stepper";

export function StepperSettingTab({ formik, currentStep }: StepperStepsPropsType) {
  return (
    <>
      {/* <div className="tab-4 tab-block h-100">
                <div className="left-block h-100"> */}
      <div className="flex-wrap-bl order-2 order-lg-1">
        <h5 className="c-tx-primary f-700 mb-1">Select Provider Settings - edeXa Universe</h5>
        <p className="c-tx-ninth mb-3">Choose your provider and associated algorithm.</p>
        <div className="detail-block">
          <h5 className="c-tx-primary f-700 sub-tl mb-0">Provider</h5>
          <div className="out-wrap ct-20">
            <div className="select-wrap">
              <div className="pg">
                <label>
                  <input
                    type="radio"
                    name="providerSettings.provider"
                    defaultChecked={formik.values.providerSettings.provider === "Hyperledger Fabric"}
                    className="card-input-element"
                    onChange={(event) => {
                      if (event.target.checked) {
                        formik.setFieldValue(event.target.name, "Hyperledger Fabric");
                      }
                    }}
                  />
                  <div className="panel panel-default card-input">
                    <div id="hyperledger-radio-btn" className="d-md-flex align-items-center justify-content-between">
                      <div className="bl-b-1 bl-b flex-column align-items-start">
                        <p className="mb-0 c-tx-primary f-700">Hyperledger Fabric</p>
                        <p className="c-tx-ninth mb-0">Hyperledger Fabric 1.4.9</p>
                      </div>
                      <div className="custom-radio-btn"></div>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="detail-block">
          <h5 className="c-tx-primary f-700 sub-tl mb-0">Consensus Algorithm</h5>
          <ul className="d-flex flex-wrap ct-20">
            <li className="d-inline-flex align-items-center me-3 mb-2">
              <label>
                <input
                  type="radio"
                  name="providerSettings.consensusAlgorithm"
                  className="card-input-element "
                  defaultChecked={formik.values.providerSettings.consensusAlgorithm === "Raft"}
                  onChange={(event) => {
                    if (event.target.checked) {
                      formik.setFieldValue(event.target.name, "Raft");
                    }
                  }}
                />
                <div className="panel panel-default card-input p-0 border-0 d-inline-flex align-items-center">
                  <div className="custom-radio-btn me-2"></div>
                  <p className="mb-0 c-tx-primary f-700">Raft</p>
                </div>
              </label>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="d-flex align-items-center justify-content-between w-100 mt-auto mb-2 mb-lg-0 order-1 order-lg-2">
                        <div className="d-inline-flex">
                            <button className="custom-primary-outline ext-small" onClick={() => onClickBack()}>Back</button>
                        </div>
                        <div className="d-inline-flex">
                            <button className="custom-primary ext-small" onClick={() => onClickNext()}>Next</button>
                        </div>
                    </div>

                </div> */}
      {/* <div className="right-block">
                    <div className="flex-bl">
                        <h5 className="tab-heading c-tx-primary f-700 ">What is a Home Region ?</h5>
                        <p className="c-tx-ninth mb-2 small">The home region is the cloud provider and underlying region where information about the network (such as membership details) is stored. The available cloud providers are :</p>
                        <div className="points-wrapper h-auto">
                            <ul className="point-list">
                                <li><div className="icon"><p className="c-tx-tenth mb-0 small lh-18">{Check}</p></div><p className="c-tx-tenth mb-0 small lh-18">AWS</p></li>
                                <li><div className="icon"><p className="c-tx-tenth mb-0 small lh-18">{Check}</p></div><p className="c-tx-tenth mb-0 small lh-18">Microsoft Azure</p></li>
                            </ul>
                        </div>
                        <p className="c-tx-ninth mb-0 small">Additional regions may be whitelisted during the next and final step of creating a network.</p>
                        <div className="ct-link">
                            <Link to='/' className="mb-0 readmore small f-700">Learn more </Link>
                        </div>
                    </div>
                </div> */}

      {/* </div> */}
    </>
  );
}

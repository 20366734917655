import "bootstrap/dist/css/bootstrap.min.css";
import { FullPageLoader } from "components/loader";
import { GlobalErrorFallBackUI } from "core/errorBoundries/GlobalErrorFallBackUI";
import RoutingComponent from "core/Routes/Routing";
import { Suspense } from "react";
import { ErrorBoundary as GlobalErrorBoundary } from "react-error-boundary";
import WebsocketProvider from "utils/WebSocketProvider";
import "./App.css";
import "./styles/common/common.scss";
import "./styles/style.scss";

function App() {
  return (
    <GlobalErrorBoundary FallbackComponent={GlobalErrorFallBackUI}>
      <WebsocketProvider>
        <Suspense fallback={<FullPageLoader />}>
          <RoutingComponent />
        </Suspense>
      </WebsocketProvider>
    </GlobalErrorBoundary>
  );
}

export default App;

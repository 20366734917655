import { StepperLink } from "components/edexaUniverseStepper/StepperLink";
import { uploadContractContext } from "hooks/UseUploadNewContract";

import { useContext } from "react";
import "../../styles/home/home.scss";
import "../../styles/template/template.scss";
import FirstTab from "./FirstTab";
import SecondTab from "./Secondtab";
import ThirdTab from "./ThirdTab";

const ContactSteper = () => {
  const contractContextValues = useContext(uploadContractContext);

  return (
    <>
      <div className="stepper-link-wrapper">
        <ul className="list-step">
          <StepperLink title="Add Information" status={contractContextValues.currentStep === 0 && "active"} count="1" complete={contractContextValues.currentStep > 0} />
          <StepperLink title="Protocol and Type" status={contractContextValues.currentStep === 1 && "active"} count="2" complete={contractContextValues.currentStep > 1} />
          <StepperLink title="Preview" status={contractContextValues.currentStep === 2 && "active"} count="3" complete={contractContextValues.currentStep > 2} />
        </ul>
      </div>
      <div className="custom-card-wrapper modal-pad-30">
        {/* information */}
        {contractContextValues.currentStep === 0 && <FirstTab />}
        {/* tab1 end */}

        {/* protocol */}
        {contractContextValues.currentStep === 1 && <SecondTab />}
        {/* tab2 end */}

        {/* preview */}
        {contractContextValues.currentStep === 2 && <ThirdTab />}
        {/* third tab end */}
      </div>
    </>
  );
};

export default ContactSteper;

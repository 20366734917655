import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { FC } from "react";

const Filledstar = <FontAwesomeIcon icon={faStar} />;

interface IIconComponent {
  icon: any;
  className?: string;
}

const IconComponent: FC<IIconComponent> = ({ icon, ...rset }) => {
  return (
    <li>
      <p {...rset}>{Filledstar}</p>
    </li>
  );
};

export default IconComponent;

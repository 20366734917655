import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getChannelListingAPI = (data: IgetChannelListingAPIParams): Promise<IgetChannelListingAPIRes> => {
  return HTTPService.get(apiEndPoints.universeAPI.channelManagement.list, data);
};

interface IgetChannelListingAPIParams {
  limit?: number;
  page?: number;
  search?: string;
  pagination?: boolean;
  channelName?: string;
}

export interface IgetChannelListingAPIResData {
  channelHeight: number;
  channelName: string;
  id: string;
  totalParticipate: number;
}

interface IgetChannelListingAPIRes {
  status: number;
  message: string;
  data: {
    count: number;
    data: IgetChannelListingAPIResData[];
  };
}

import { FC, memo } from "react";

const TitleComponent: FC<{ title: string }> = ({ title }) => {
  return (
    <div className="page-title">
      <h5 className="c-tx-primary f-700 mb-20">{title}</h5>
    </div>
  );
};

export default memo(TitleComponent);

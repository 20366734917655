// src/components/StepperForm.js
import { useState } from 'react'
import './StepperForm.css'

const Steps = () => {
  const [step, setStep] = useState(3)

  return (
    <>
      <div className="stepper-form">
        <div className="steps">
          <div className={`step ${step === 1 ? 'active' : ''}`}>
            <div className="step-icon" onClick={() => setStep(1)}>
              1
            </div>
            <div className="line"></div>
            <span className="d-flex" style={{ marginLeft: '-50px' }}>
              {' '}
              Your 2FA setup is done{' '}
            </span>
          </div>
          <div className={`step ${step === 2 ? 'active' : ''}`}>
            <div className="step-icon " onClick={() => setStep(2)}>
              2
            </div>
            <div className="line"></div>
            <span className="d-flex" style={{ marginLeft: '-90px' }}>
              {' '}
              Your Channel and Network Misson is Created
            </span>
          </div>
          <div className={`step ${step === 3 ? 'active' : ''}`}>
            <div className="step-icon" onClick={() => setStep(3)}>
              3
            </div>
            <span className="d-flex"> Deploying</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default Steps

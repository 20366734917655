import axios from "axios";
import { apiEndPoints } from "utils/api-endpoints";

export const webHookAuthenticateApi = (headers: any) => {
  const url =
    process.env.REACT_APP_WEBHOOK_APIBASEURL +
    apiEndPoints.webHook.webHookAuthenticate;

  return new Promise((resolve, reject) => {
    axios
      .post(url, {}, { headers })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response || error));
  });
};

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const moveFolderAPI = (id: string, data: ImoveFolderAPIData): Promise<ImoveFolderAPI> => {
  return HTTPService.post(apiEndPoints.universeAPI.folderTemplate.move + id, data);
};

interface ImoveFolderAPIData {
  chaincodeId: string[];
}

export interface ImoveFolderAPIResData {
  id: string;
  repositoryId: string;
  chaincodeId: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

interface ImoveFolderAPI {
  message: string;
  status: number;
  data: ImoveFolderAPIResData[];
}

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const createPeerAPI = (data: IcreatePeerAPIParams): Promise<createPeerAPIRes> => {
  return HTTPService.post(apiEndPoints.universeAPI.myBlockChain.peer.create, data);
};
interface IcreatePeerAPIParams {
  name: string;
  region: string;
}

interface createPeerAPIRes {
  status: number;
  message: string;
}

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const VerificationOtpAPI = (data: IVerificationOtpAPIParamas): Promise<IVerificationOtpAPI> => {
  return HTTPService.post(apiEndPoints.universeAPI.channelManagement.verificationOtp, data);
};

interface IVerificationOtpAPIParamas {
  otp: string;
  requestName: string;
}

interface IVerificationOtpAPI {
  data: { status: boolean };
  status: number;
  message: string;
}

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button } from "reactstrap";
import "../../styles/template/template.scss";
const Tickmark = <FontAwesomeIcon icon={faCheckCircle} />;
const SucessfulPop = () => {
  const [drp, setdrp] = useState(false);
  // eslint-disable-next-line
  const toggle = () => {
    setdrp(!drp);
  };
  return (
    <>
      <div className="erc-template-wrapper">
        <div className="title mt-30 mb-30">
          <h3 className="big-font-icons green text-center f-700 mb-0">{Tickmark}</h3>
        </div>
        <h4 className="c-tx-primary text-center mb-20">Successfully uploaded contract</h4>
        <div className="btn-wrapper text-center mb-30">
          <Button className="custom-primary new-btn">GO TO LISTING PAGE</Button>
        </div>
      </div>
    </>
  );
};

export default SucessfulPop;

import { uploadContractContext } from "hooks/UseUploadNewContract";
import { useContext } from "react";
import Select from "react-select";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { capitalizeFirstLetter } from "utils/commonFunctions";
const ThirdTab = () => {
  const contractContextValues = useContext(uploadContractContext);
  return (
    <>
      {/* tab1 start */}
      <div className="tab-1 tab-block h-100 w-100">
        <div className="d-flex h-100 w-100 flex-column">
          <div className="w-100 flex-grow-1 order-2 order-lg-1">
            <div className="title">
              <h5 className="c-tx-primary f-700 mb-20">Smart Contract</h5>
            </div>
            <Form className="form-wrapper">
              <Row>
                <Col md="12" lg="12" xl="12">
                  <FormGroup className="">
                    <Label for="name">
                      Name of Contract <span className="text-danger">*</span>
                    </Label>
                    <Input type="text" id="name" disabled value={contractContextValues.contractFormik.values.name} />
                  </FormGroup>
                </Col>
                <Col md="12" lg="12" xl="12">
                  <FormGroup className="">
                    <Label for="description">
                      Description <span className="text-danger">*</span>
                    </Label>
                    <Input type="textarea" id="description" value={contractContextValues.contractFormik.values.description} disabled />
                  </FormGroup>
                </Col>
                <Col md="12" lg="6" xl="6">
                  <FormGroup className="">
                    <Label for="protocol">
                      Protocol <span className="text-danger">*</span>
                    </Label>
                    <Input id="protocol" name="protocol" value={contractContextValues.contractFormik.values.protocol} type="text" disabled />
                  </FormGroup>
                </Col>
                <Col md="12" lg="6" xl="6">
                  <FormGroup className="custome-select-mk">
                    <Label for="firstname">
                      Language <span className="text-danger">*</span>
                    </Label>
                    <Select
                      options={[]}
                      value={{
                        label: capitalizeFirstLetter(contractContextValues.contractFormik.values.language),
                        value: contractContextValues.contractFormik.values.language
                      }}
                      placeholder="Select type"
                      isDisabled
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="d-flex justify-content-between order-1 order-lg-2">
            <div className="d-inline-flex">
              <Button
                type="button"
                className="custom-primary ext-small"
                onClick={() =>
                  contractContextValues.setCurrentStep((current: number) => {
                    return current - 2;
                  })
                }>
                EDIT
              </Button>
            </div>
            <div className="d-inline-flex">
              <Button type="button" className="custom-primary ext-small" onClick={() => contractContextValues.toggleUploadModal()}>
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ThirdTab;

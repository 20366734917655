import { FIRSTTIMEVISITORMODAL, FIRSTTIMEVISITORTOURVISIBLE  ,PRIVATE_BLOCKCHAIN_FIRST} from "./type";

const initialState = {
  visitorTourVisible: false,
  visitorModalVisible: true,
  privateBlockchain: true , 
};

const firstTimeTourReducer = (state = initialState, action: { type: string; payload: boolean }) => {
  const newState = { ...state };
  switch (action.type) {
    case FIRSTTIMEVISITORMODAL:
      newState.visitorModalVisible = action.payload;
      break;
    case FIRSTTIMEVISITORTOURVISIBLE:
      newState.visitorTourVisible = action.payload;
      break;
    case FIRSTTIMEVISITORMODAL:
      newState.visitorModalVisible = action.payload;
      break;
    case PRIVATE_BLOCKCHAIN_FIRST:
      newState.privateBlockchain = action.payload;
      break;
  }
  return newState;
};

export { firstTimeTourReducer };

export interface IFirstTimeTourReducer {
  visitorTourVisible: boolean;
  visitorModalVisible: boolean;
  privateBlockchain : boolean
}

import * as TYPE from "./type";

export const onUserLoggedIn = () => {
  return { type: TYPE.LOGGEDIN };
};

export const setUserTour = (value: boolean) => {
  return { type: TYPE.TOUR, payload: value };
};

export const onUserLogOut = () => {
  return { type: TYPE.LOGOUT };
};

import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Form, Label, Row, Spinner } from 'reactstrap'
import { setLoading } from 'store/loader/action'
import { apiEndPoints } from 'utils/api-endpoints'
import { addUpdateUserAppPermissions } from 'utils/common/helper/addUpdateUserAppPermissions'
import {
  getEncryptedCookie,
  toastSuccess
} from 'utils/commonFunctions'
import { cookieKeys } from 'utils/constants'
const Check = <FontAwesomeIcon icon={faCheck} />

const UpdateMemberPermissions = ({
  userIdForAppsPermissions,
  toggleUpdatePermissions
}: any) => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state: any) => state.loading)
  const [userApps, setUserApps] = useState<any>([])
  const [appsButtonDisabled, setAppsButtonDisabled] = useState(true)

  const getAllAvailableAppsByUserId = async () => {
    // getAllAvailableAppsByUserId(newUserId).then((res) => {
    const cookie = getEncryptedCookie(cookieKeys.cookieUser)
    const url = `${process.env.REACT_APP_ACCOUNTS_APIBASEURL}${apiEndPoints.getAPPlicationsByUserId}${userIdForAppsPermissions}`
    await axios
      .get(url, {
        headers: { Authorization: `Bearer ${cookie.token}` }
      })
      .then((res: any) => {
        dispatch(setLoading(false))
        const { apps } = res.data
        let userApps = apps?.map((item: any) => {
          // if api is active then make access to add by default so checkbox will be checked onload otherwise make it remove
          return {
            appId: item.appId,
            logoIcon: item.logoIcon,
            name: item.name,
            access:
              item.appId === 'yPAwp5kqUbsZdql16dlZD7U1z'
                ? true
                : item?.access,
            isAvailable: item?.isAvailable
          }
        })
        setUserApps(userApps)
      })
  }

  useEffect(() => {
    // if we have the new users id then fetch its detail
    if (userIdForAppsPermissions) {
      // dispatch(setLoading(true));
      getAllAvailableAppsByUserId()
      // getAllAvailableAppsByUserId(userIdForAppsPermissions).then((res: any) => {
      //   dispatch(setLoading(false));
      //   const { apps } = res;
      //   let userApps = apps?.map((item: any) => {
      //     // if api is active then make access to add by default so checkbox will be checked onload otherwise make it remove
      //     return {
      //       appId: item.appId,
      //       logoIcon: item.logoIcon,
      //       name: item.name,
      //       access: item.appId === "yPAwp5kqUbsZdql16dlZD7U1z" ? true : item?.access,
      //       isAvailable: item?.isAvailable,
      //     };
      //   });
      //   setUserApps(userApps);
      //   dispatch(setAccountsAPI(false));
      // });
    } // eslint-disable-next-line
  }, [dispatch, userIdForAppsPermissions])

  // user app permission formik state
  const appPermissionsFormik = useFormik({
    initialValues: {
      apps: [userApps],
      uid: ''
    },
    validationSchema: '',
    onSubmit: (values: any) => {
      dispatch(setLoading(true))
      const apiData = {
        uid: userIdForAppsPermissions,
        apps: userApps.map((app: any) => {
          // return an object with appId and app.Access - It will alter the object
          // if access is true then It will be add otherwise it will be remove or as It is.
          return {
            appId: app?.appId,
            access:
              app?.access === true
                ? 'add'
                : app?.access === false
                ? 'remove'
                : app?.access,
            name: app?.name
          }
        })
      }
      addUpdateUserAppPermissions(apiData).then((response) => {
        dispatch(setLoading(false))
        toggleUpdatePermissions()
        toastSuccess('User Permissions updated successfully')
      })
    }
  })

  return (
    <>
      <div className="custom-card-wrapper ">
        <div className="user-management-popup">
          <div className="user-form">
            <h5 className="c-tx-primary f-700 mb-1">
              App Permission
            </h5>
            <div className="detail-block">
              <Form className="form-wrapper">
                <Row>
                  {userApps?.map((app: any, index: number) => {
                    return (
                      <Col xl="2" lg="3" md="4" sm="6" key={index}>
                        <Label
                          onClick={(event: any) => {
                            setAppsButtonDisabled(false)
                            // @ts-ignore
                            let appsData = userApps
                            appsData[index] = {
                              // @ts-ignore
                              ...appsData[index],
                              access: event.target.checked
                                ? true
                                : false
                            }
                            setUserApps(appsData)
                          }}
                          className={`${
                            app?.isAvailable
                              ? app?.appId ===
                                  'yPAwp5kqUbsZdql16dlZD7U1z' ||
                                app?.name === 'portal'
                                ? 'disable cursor-not-allowed'
                                : ''
                              : 'disable'
                          }`}
                        >
                          <input
                            type="checkbox"
                            name="product"
                            className="card-input-check-element"
                            defaultChecked={
                              userApps?.[index]?.access &&
                              userApps?.[index]?.isAvailable
                            }
                          />
                          <div
                            className={`panel panel-default card-input-check ${
                              app?.isAvailable
                                ? app?.appId ===
                                    'yPAwp5kqUbsZdql16dlZD7U1z' ||
                                  app?.name === 'portal'
                                  ? 'disable cursor-not-allowed'
                                  : ''
                                : 'disable'
                            }`}
                          >
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="text-bl flex-column w-100 align-items-center">
                                <div className="img-wrap-bl">
                                  {' '}
                                  <img
                                    src={app?.logoIcon}
                                    className="img-fluid"
                                    alt={app?.name}
                                  />
                                </div>
                                <div className="">
                                  <h6 className="f-700 c-tx-primary">
                                    {app?.name}
                                  </h6>
                                </div>
                              </div>
                              <div className="custom-check-btn">
                                <p className="text-white mb-0 checkmark-bl ">
                                  {Check}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Label>
                      </Col>
                    )
                  })}
                </Row>
              </Form>
            </div>
          </div>
          {/**
           * @buttons
           */}
          <div className="d-flex align-items-center justify-content-between w-100 mt-auto mb-2 mb-lg-0 order-1 order-lg-2">
            <div className="d-inline-flex">
              {/* <button className="custom-primary-outline ext-small " onClick={() => onClickBack()}>Back</button> */}
              {/* <button className="custom-primary ext-small " onClick={() => setFormStep(formStep - 1)}>Back</button> */}
            </div>
            <div className="d-inline-flex">
              <button
                disabled={appsButtonDisabled}
                className={`custom-primary ext-small ${
                  appsButtonDisabled
                    ? 'cursor-not-allowed disabled-button'
                    : ''
                }`}
                onClick={() => {
                  appPermissionsFormik.handleSubmit()
                }}
              >
                {!isLoading ? (
                  'Save'
                ) : (
                  <Spinner
                    size="small"
                    className="text-white"
                    style={{ width: 15, height: 15 }}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UpdateMemberPermissions

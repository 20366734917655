import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import { setConfirmationModalVisibility } from "store/confirmationModule/action";
import { setLoading } from "store/loader/action";
import { IRootReducer } from "store/root-reducer";
import { approveMemberKyc } from "utils/common/helper/approveMemberKyc";
import { toastSuccess } from "utils/commonFunctions";
import { rejectionReasons } from "utils/constants";

const KYCRejectModal: React.FC<IRejectKyc> = ({ toggleKycRejectModal, isKycRejectModal, kycId }) => {
  const dispatch = useDispatch();
  const [reason, setReason] = useState("");

  const { isLoading } = useSelector((state: IRootReducer) => state.loading);
  const [isOther, setIsOther] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  const handleReason = (reason: string) => {
    if (reason !== "Other") {
      setReason(reason);
      setIsOther(false);
    } else {
      setIsOther(true);
      setReason("");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  };

  const urlParam: any = useParams();
  const handleSave = () => {
    dispatch(setLoading(true));

    const params = {
      id: parseInt(urlParam?.id || kycId),
      status: 2,
      reason: reason
    };
    dispatch(setLoading(true));

    approveMemberKyc(params)
      .then((res: any) => {
        dispatch(setLoading(false));
        dispatch(setConfirmationModalVisibility(false));
        toggleKycRejectModal();
        toastSuccess(res.message);
      })
      .catch((err) => {
        toggleKycRejectModal();
      });
  };
  // clear state once component unmount
  useEffect(() => {
    return () => {
      setReason("");
    };
  }, []);

  return (
    <Modal isOpen={isKycRejectModal} backdropClassName="confirmation-model-parent" centered backdrop="static">
      <ModalHeader
        toggle={toggleKycRejectModal}
        className="border-0 bg-blue text-white"
        close={
          <span onClick={() => toggleKycRejectModal()} className="cursor-pointer">
            x
          </span>
        }>
        Reason For Rejection
      </ModalHeader>
      <ModalBody>
        <div className="radio-buttons">
          {rejectionReasons?.map((reason, index) => {
            return (
              <FormGroup check key={index}>
                <Input type="radio" name={"radio1"} id={`${index}`} onChange={() => handleReason(reason)} style={{ cursor: "pointer" }} />
                <Label check htmlFor={`${index}`}>
                  {reason}
                </Label>
              </FormGroup>
            );
          })}
          {isOther && (
            <Input
              // @ts-ignore
              innerRef={inputRef}
              type="text"
              className="other-reason"
              placeholder="Enter your reason"
              onChange={(e) => setReason(e.target.value)}
            />
          )}
        </div>
      </ModalBody>
      <ModalFooter className="border-0">
        <div className="fotter-layout">
          {/* eslint-disable-next-line  */}
          <Button
            className="border-0 bg-blue"
            disabled={!Boolean(reason)}
            onClick={handleSave}
            // eslint-disable-next-line
          >
            {isLoading ? <Spinner className="color-blue mx-3 text-white" size={"sm"} /> : "Save"}
          </Button>{" "}
        </div>
      </ModalFooter>
    </Modal>
  );
};

interface IRejectKyc {
  toggleKycRejectModal: any;
  isKycRejectModal: boolean;
  kycId: number | undefined;
}

export default KYCRejectModal;

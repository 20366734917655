import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const getAllMembers = (params: IALLMembersParams): Promise<IMemberList> => {
  return HTTPService.get(apiEndPoints.members, params);
};

interface IALLMembersParams {
  search?: string;
  page: number;
  limit: number;
  filter?: string;
}

interface IRows {
  email: string;
  firstName: string;
  id: number;
  lastName: string;
  phone: string;
  status: number;
  userId: string;
}

export interface IMemberList {
  users: {
    count: number;
    rows: [IRows];
  };
  message: string;
  status: number;
}

import { apiEndPoints } from "utils/api-endpoints";
import HTTPService from "utils/httpService";

export const SendOTPAPI = (body: any, params: ISendOTPAPIParams): Promise<ISendOTPAPIRes> => {
  return HTTPService.post(apiEndPoints.universeAPI.channelManagement.sendOtp, body, params);
};

interface ISendOTPAPIParams {
  requestType: string;
  module: string;
}

interface ISendOTPAPIRes {
  message: string;
  status: number;
}

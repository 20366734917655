import { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { getNetworkRegions, INetworkRegion } from "utils/common/helper/get-network-regions";
import { toastError } from "utils/commonFunctions";
import { StepperStepsPropsType } from "./Stepper";

export function StepperThirdTab({ formik }: StepperStepsPropsType) {
  const { values, setFieldValue } = formik;
  const [networkRegions, setNetworkRegions] = useState<INetworkRegion[]>([]);
  const [regionsLoading, setRegionsLoading] = useState(false);
  useEffect(() => {
    if (values.enviornmentDetails.cloudType) {
      setRegionsLoading(true);
      getNetworkRegions(values.enviornmentDetails.cloudType)
        .then((response) => {
          setRegionsLoading(false);
          setNetworkRegions(response.data.data);
          setFieldValue("homeRegion", response.data.data.find((item) => item.default)?.id || "");
        })
        .catch((err) => {
          setRegionsLoading(false);
          toastError(err.message);
        });
    } else {
      toastError("Please select a cloud type first.");
    }

    return () => {};
  }, [setFieldValue, values.enviornmentDetails.cloudType]);

  return (
    <>
      {/* Tab1 Code start */}
      {/* <div className="tab-3 tab-block h-100">
                <div className="left-block h-100"> */}
      <div className="flex-wrap-bl order-2 order-lg-1">
        <div className="detail-block mt-0">
          <h5 className="c-tx-primary f-700 mb-2 mt-3 mt-md-0">Set Home Region</h5>
          <p className="c-tx-ninth"></p>
          <div className="out-wrap">
            <div className="select-wrap overflow-auto" style={{ height: 320 }}>
              {regionsLoading ? <Spinner /> : null}
              {networkRegions.map((region) => {
                return (
                  <div className="pg" key={region.id}>
                    <label>
                      <input
                        type="radio"
                        disabled={region?.disable}
                        name="homeRegion"
                        checked={formik.values.homeRegion === region.id || false}
                        className="card-input-element"
                        onChange={(event: any) => {
                          if (event.target.checked) {
                            formik.setFieldValue(event.target.name, region.id);
                          }
                        }}
                      />
                      <div className="panel panel-default card-input">
                        <div className=" d-flex align-items-center justify-content-between">
                          <div className="wrapper-bl-block-first d-inline-flex w-80 flex-wrap">
                            <div className="bl-b-1 bl-b">
                              <img src={region.file} width={30} alt="flag-1" className="img-fluid" />
                              <p className="mb-0 c-tx-primary f-700">{region.name}</p>
                            </div>
                            <div className="bl-b-2 bl-b">
                              <p className="mb-0 c-tx-ninth">{region.domain}</p>
                            </div>
                            <div className="bl-b-3 bl-b">
                              <p className="mb-0 c-tx-ninth">{region.region}</p>
                            </div>
                          </div>
                          <div className="wrapper-bl-block-second d-inline-flex">
                            <div className="custom-radio-btn"></div>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="d-flex align-items-center justify-content-between w-100 mt-auto mb-2 mb-lg-0 order-1 order-lg-2">
                        <div className="d-inline-flex">
                            <button className="custom-primary ext-small disable" disabled onClick={() => onClickBack()}>Back</button>
                        </div>
                        <div className="d-inline-flex">
                            <button className="custom-primary ext-small" onClick={() => onClickNext()}>Next</button>
                        </div>
                    </div>

                </div> */}
      {/* <div className="right-block">
                    <div className="flex-bl">
                        <h5 className="tab-heading c-tx-primary f-700 ">What is a Home Region ?</h5>
                        <p className="c-tx-ninth mb-2 small">The home region is the cloud provider and underlying region where information about the network (such as membership details) is stored. The available cloud providers are :</p>
                        <div className="points-wrapper h-auto">
                            <ul className="point-list">
                                <li><div className="icon"><p className="c-tx-tenth mb-0 small lh-18">{Check}</p></div><p className="c-tx-tenth mb-0 small lh-18">AWS</p></li>
                                <li><div className="icon"><p className="c-tx-tenth mb-0 small lh-18">{Check}</p></div><p className="c-tx-tenth mb-0 small lh-18">Microsoft Azure</p></li>
                            </ul>
                        </div>
                        <p className="c-tx-ninth mb-0 small">Additional regions may be whitelisted during the next and final step of creating a network.</p>
                        <div className="ct-link">
                            <Link to='/' className="mb-0 readmore small f-700">Learn more </Link>
                        </div>
                    </div>
                </div> */}

      {/* </div> */}
      {/* Tab1 Code ends */}
    </>
  );
}

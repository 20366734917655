import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 *
 * @param {*}
 * @returns {FunctionalComponents}
 */

export function StepperLink({ complete, status, title, count }: StepperLinkType) {
  const Check = <FontAwesomeIcon icon={faCheck} />;
  return (
    <>
      <li className={`${status === "active" || complete ? "active" : ""}`}>
        <div className="border-block"></div>
        {/**
         * @active
         * @completed
         *
         * If its active then show number with bg
         * If its completed then show completed
         */}
        {complete ? (
          <div className="icon-bl">
            <p className="c-tx-tenth mb-0 small lh-18 f-700">{Check}</p>
          </div>
        ) : status === "active" ? (
          <div className="icon-bl">
            <p className="c-tx-tenth mb-0 small lh-18 f-700">{count}</p>
          </div>
        ) : (
          <div className="icon-bl">
            <p className="mb-0">{count}</p>
          </div>
        )}
        <div className="title">
          <p className="mb-0">{title}</p>
        </div>
      </li>
    </>
  );
}

type StepperLinkType = {
  complete: boolean;
  status: string | boolean;
  count: number | string;
  title: string;
};

import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getWebHooksAPI } from "actions/webHook/getWebHooksAPI";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container, Table } from "reactstrap";
import { setLoading } from "store/loader/action";
import { toastError } from "utils/commonFunctions";
import "./webhooks.scss";

const Webhooks = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  useEffect(() => {
    dispatch(setLoading(true));
    getWebHooksAPI()
      .then((res: any) => {
        if (res.status === 200) {
          setData(res.data);
        }
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        toastError(err.data.message);
      });
  }, []);

  return (
    <div className="p-4">
      <Container>
        <span
          className="cursor-pointer c-tx-secondary fw-bold"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-10" />
          Back
        </span>
        <h1 className="c-tx-primary mb-md-0 f-700">Developers</h1>
        <div className="d-flex mt-20">
          <h4 className="c-tx-secondary f-600 mb-2">Webhooks</h4>
        </div>
        <div className="mt-10">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="c-tx-third f-600 mb-2 mb-0">Hosted endpoints</h3>
            <Button
              className="custom-primary custom-primary"
              onClick={() => {
                navigate("/webhooks/create");
              }}
            >
              Add
            </Button>
          </div>
          <Table bordered className="mt-20">
            <thead>
              <tr className="p-4">
                <th className="c-tx-third">URL</th>
                <th className="c-tx-third">STATUS</th>
              </tr>
            </thead>
            {data.map((item: any, index) => (
              <tbody key={index}>
                {data.length === 0 ? (
                  <tr>
                    <td className="text-center" colSpan={2}>
                      NO DATA FOUND
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td className="c-tx-tenth">{item?.redirectUrl}</td>
                    <td className="c-tx-tenth">
                      {item?.status === 1 ? "Active" : "InActive"}
                    </td>
                  </tr>
                )}
              </tbody>
            ))}
          </Table>
        </div>
      </Container>
    </div>
  );
};

export default Webhooks;

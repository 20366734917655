import React, { useEffect, useState } from "react";
import { Input, Label } from "reactstrap";

const CheckboxInput = ({
  val,
  handleChange,
  eventData,
}: any) => {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    if (eventData) {
      const status = eventData.filter(
        (items: any) => items.event === val.event
      );
      status.length !== 0 && setCheck(true);
    }
  }, [eventData]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheck(e.target.checked);
    handleChange(e);
  };

  return (
    <Label
      className={
        val.action === "succeed"
          ? "c-tx-darkgreen fw-bold cursor-pointer"
          : "c-tx-red fw-bold cursor-pointer"
      }
      check
    >
      <Input
        checked={check}
        onChange={(e) => onChange(e)}
        type="checkbox"
        className="mr-10"
        value={val.event}
      />
      {val.action[0].toUpperCase() + val.action.slice(1)}
    </Label>
  );
};

export default CheckboxInput;
